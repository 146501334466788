import React, { useEffect, useState } from 'react';
import './CreateVacancy.css';
import { Button, makeStyles, OutlinedInput } from '@mui/material';
import { HouseRounded, LocationOn, WorkHistory, WorkHistoryRounded } from '@mui/icons-material';
import Logo from '../../../Assets/StarteleLogo.png';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import Typography from "@mui/material/Typography";
import { Bars } from 'react-loader-spinner';
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {
    Box,
    Icon,
    styled,
    Table,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    Switch,
    Grid,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import QuizRound from '../../InterviewScreens/QuizRound/QuizRound';

const CreateVacancy = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");  //This is the tenant uuid
    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID"); // This is for taking the company till the profile of the company is not created

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [JobID, setJobID] = useState('');
    const [JobTitle, setJobTitle] = useState("")
    const [JobExperience, setJobExperience] = useState("")
    const [JobSalary, setJobSalary] = useState("")
    const [JobLocation, setJobLocation] = useState("")
    const [JobOpening, setJobOpening] = useState("")
    const [JobRole, setJobRole] = useState("")
    const [JobDescription, setJobDescription] = useState("")
    const [JobResponsibilities, setJobResponsibilities] = useState("")
    const [JobQualification, setJobQualification] = useState("")
    const [JobSkills, setJobSkills] = useState('')

    const [InterviewRounds, setInterviewRounds] = useState('0')
    const [openRound, setOpenRound] = useState(null); // State to track the opened accordion
    // State to hold all rounds
    const [rounds, setRounds] = useState([]);

    // extempore round state
    const [ExtemporeRound, setExtemporeRound] = useState(true);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [ExtemporeRound_no, setExtemporeRound_no] = useState('1/4');
    const [ExtemporeRounddifficultyLevel, setExtemporeRoundDifficultyLevel] = useState('0');
    const [ExtemporeRoundinterviewtime, setExtemporeRoundinterviewtime] = useState('');
    const [ExtemporeRoundquestionLimit, setExtemporeRoundquestionLimit] = useState('1');
    const [ExtemporeRoundTechnology, setExtemporeRoundTechnology] = useState('')

    // Comprehension Round state
    const [ComprehensionRound, setComprehensionRound] = useState(true);
    const [ComprehensionRound_no, setComprehensionRound_no] = useState('2/4');
    const [ComprehensiondifficultyLevel, setComprehensiondifficultyLevel] = useState('0');
    const [Comprehensioninterviewtime, setComprehensioninterviewtime] = useState('');
    const [ComprehensionquestionLimit, setComprehensionquestionLimit] = useState('1');
    const [ComprehensionTechnology, setComprehensionTechnology] = useState('')

    // Quiz Round State 
    const [QuizRound, setQuizRound] = useState(true);
    const [QuizRound_no, setQuizRound_no] = useState('3/4');
    const [QuizdifficultyLevel, setQuizDifficultyLevel] = useState('0');
    const [Quizinterviewtime, setQuizinterviewtime] = useState('');
    const [QuizquestionLimit, setQuizquestionLimit] = useState('');
    const [QuizTechnology, setQuizTechnology] = useState('')


    // Coding Round State 
    const [CodingRound, setCodingRound] = useState(false);
    const [CodingRound_no, setCodingRound_no] = useState('4/4');
    const [CodingdifficultyLevel, setCodingDifficultyLevel] = useState('0');
    const [Codinginterviewtime, setCodinginterviewtime] = useState('');
    const [CodingquestionLimit, setCodingquestionLimit] = useState('1');
    const [CodingTechnology, setCodingTechnology] = useState('')

    // checkbox check function 


    const handleAddExtemporeRound = () => {
        const newRound = {
            // vacancy_uuid: '',  // Add relevant UUID or other vacancy details here
            round_type: 'extempore',
            limit: ExtemporeRoundquestionLimit,
            difficulty_level: ExtemporeRounddifficultyLevel,
            question: ExtemporeRoundTechnology,  // Assuming 'question' is the technology/topic
            timer: (ExtemporeRoundinterviewtime * 60).toString(),
            round_no: ExtemporeRound_no
        };

        setRounds(prevRounds => [...prevRounds, newRound]); // Add the new round to the existing rounds
        toast.success('Extempore round added successfully.', {
            toastId: customId,
            position: 'top-right',
            autoClose: 2000
        })
        setIsAccordionOpen(!isAccordionOpen)
    };

    // Function to handle adding the Comprehension round
    const handleAddComprehensionRound = () => {
        const newRound = {
            // vacancy_uuid: '',  // Add relevant UUID or other vacancy details here
            round_type: 'comprehension',
            limit: ComprehensionquestionLimit,
            difficulty_level: ComprehensiondifficultyLevel,
            question: ComprehensionTechnology,  // Assuming 'question' is the topic/technology
            timer: (Comprehensioninterviewtime * 60).toString(),
            round_no: ComprehensionRound_no
        };
        toast.success('Comprehension round added successfully.', {
            toastId: customId,
            position: 'top-right',
            autoClose: 2000
        })

        setRounds(prevRounds => [...prevRounds, newRound]); // Add the new round to the existing rounds
    };

    const handleAddQuizRound = () => {
        const newRound = {
            // vacancy_uuid: '',  // Add relevant UUID or other vacancy details here
            round_type: 'quiz',
            limit: QuizquestionLimit,
            difficulty_level: QuizdifficultyLevel,
            question: QuizTechnology,  // Assuming 'question' is the topic/technology
            timer: (Quizinterviewtime * 60).toString(),
            round_no: QuizRound_no
        };
        toast.success('Quiz round added successfully.', {
            toastId: customId,
            position: 'top-right',
            autoClose: 2000
        })

        setRounds(prevRounds => [...prevRounds, newRound]); // Add the new round to the existing rounds
    };

    const handleAddCodingRound = () => {
        const newRound = {
            // vacancy_uuid: '',  // Add relevant UUID or other vacancy details here
            round_type: 'coding',
            limit: CodingquestionLimit,
            difficulty_level: CodingdifficultyLevel,
            question: CodingTechnology,  // Assuming 'question' is the topic/technology
            timer: (Codinginterviewtime * 60).toString(),
            round_no: CodingRound_no
        };
        toast.success('Coding round added successfully.', {
            toastId: customId,
            position: 'top-right',
            autoClose: 2000
        })

        setRounds(prevRounds => [...prevRounds, newRound]); // Add the new round to the existing rounds
    };

    const handleQuestionLimit = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");

        if (inputValue.trim() === "") {
            setQuizquestionLimit('')
        }
        setQuizquestionLimit(numericValue)
    }

    const handleExtemporeRoundinterviewtime = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");

        if (inputValue.trim() === "") {
            setExtemporeRoundinterviewtime('')
        }
        setExtemporeRoundinterviewtime(numericValue)
    }

    const handleComprehensionInterviewTime = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");

        if (inputValue.trim() === "") {
            setComprehensioninterviewtime('')
        }
        setComprehensioninterviewtime(numericValue)
    }

    const handleQuizInterviewTime = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");

        if (inputValue.trim() === "") {
            setQuizinterviewtime('')
        }
        setQuizinterviewtime(numericValue)
    }

    const handleCodingInterviewtime = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");

        if (inputValue.trim() === "") {
            setCodinginterviewtime('')
        }
        setCodinginterviewtime(numericValue)

    }


    // Error messages state start here
    const [JobIDError, setJobIDError] = useState('');
    const [JobTitleError, setJobTitleError] = useState('');
    const [JobRoleNameError, setJobRoleNameError] = useState('');
    const [JobExprienceError, setJobExperienceError] = useState('');
    const [JobSallaryError, setJobSallaryError] = useState('');
    const [JobLocError, setJobLocError] = useState('');
    const [TotalOpeningError, setTotalOpeningError] = useState('');
    const [JobDescriptionError, setJobDescriptionError] = useState('');
    const [JobResponsibilitiesError, setJobResponsibilitiesError] = useState('');
    const [JobQualificationError, setJobQualificationError] = useState('');
    const [JobSkillError, setJobSkillError] = useState('')


    const [SkillsArray, setSkillsArray] = useState([]);

    const [CompanyName, setCompanyName] = useState(localStorage.getItem('CompanyName'))
    const [CompanyType, setCompanyType] = useState("")
    const [CompanyCity, setCompanyCity] = useState("")
    const [CompanyState, setCompanyState] = useState("")

    const [ViewCompanyDetailsLoader, setViewCompanyDetailsLoader] = useState(true)

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setViewCompanyDetailsLoader(false)
                if (result.Status === "1") {
                    setCompanyName(result.enterprises[0].company_name)
                    setCompanyCity(result.enterprises[0].company_city)
                    setCompanyState(result.enterprises[0].company_state)
                    setCompanyType(result.enterprises[0].company_type)
                }

            })
    }

    useEffect(() => {
        ViewEnterpriseDetails()
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && JobSkills.trim() !== '') {
            setSkillsArray([...SkillsArray, JobSkills.trim()]);
            setJobSkills('');
        }
    };

    const handleDelete = (chipToDelete) => () => {
        setSkillsArray((chips) => chips.filter((chip) => chip !== chipToDelete));
    };



    // Create Vacancy input fields  validations


    const HandleCreateVacancy = () => {
        if (rounds.length < 3) {
            toast.warn('Please select at least 3 interview rounds', {
                position: 'top-right',
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        if (!JobID) {
            setJobIDError('Please enter job ID');
        } else {
            setJobIDError('');
        }

        if (!JobTitle) {
            setJobTitleError('Please enter job title');
        } else {
            setJobTitleError('');
        }

        if (!JobRole) {
            setJobRoleNameError('Please enter job role name');
        } else {
            setJobRoleNameError('');
        }

        if (!JobExperience) {
            setJobExperienceError('Please enter job experience');
        } else {
            setJobExperienceError('');
        }

        if (!JobSalary) {
            setJobSallaryError('Please enter offered salary');
        } else {
            setJobSallaryError('');
        }

        if (!JobLocation) {
            setJobLocError('Please enter job location');
        } else {
            setJobLocError('');
        }

        if (!JobOpening) {
            setTotalOpeningError('Please enter total opening');
        } else {
            setTotalOpeningError('');
        }

        if (!JobDescription) {
            setJobDescriptionError('Please enter job description');
        } else {
            setJobDescriptionError('');
        }

        if (!JobResponsibilities) {
            setJobResponsibilitiesError('Please enter job responsibilities');
        } else {
            setJobResponsibilitiesError('');
        }

        if (!JobQualification) {
            setJobQualificationError('Please enter job qualification');
        } else {
            setJobQualificationError('');
        }

        if (SkillsArray.length === 0) {
            setJobSkillError('Please enter job skills');
        } else {
            setJobSkillError('');
        }

        if (!JobID || !JobTitle || !JobExperience || !JobSalary || !JobLocation || !JobOpening || !JobDescription || !JobResponsibilities || !JobQualification || !JobRole || SkillsArray.length === 0) {
            toast.warn('Please fill in all required fields', {
                position: 'top-right',
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const vacancyPayload = JSON.stringify({
            tenant_uuid: EnterpriseUuid,
            job_id: JobID,
            job_title: JobTitle,
            job_experience: JobExperience,
            job_salary: JobSalary,
            job_location: JobLocation,
            job_opening: JobOpening,
            job_description: JobDescription,
            job_responsibilities: JobResponsibilities,
            job_qualifications: JobQualification,
            job_skills: SkillsArray,
            job_role: JobRole,
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: vacancyPayload,
            redirect: 'follow',
        };

        fetch(`${REACT_APP_BASE_API_URL}/create_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === '1') {
                    toast.success(result.Msg, {
                        position: 'top-right',
                        toastId: customId,
                        autoClose: 2000,
                    });

                    // Make the second API call to submit the interview rounds
                    const roundsPayload = JSON.stringify({
                        vacancy_uuid: result.vacancies[0].vacancy_uuid, // Use the vacancy_uuid from the result
                        entries: rounds, // Send the interview rounds stored in the rounds state
                    });

                    const roundsRequestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: roundsPayload,
                        redirect: 'follow',
                    };

                    fetch(`${REACT_APP_BASE_API_URL}/interview_template`, roundsRequestOptions)
                        .then((response) => response.json())
                        .then((roundResult) => {
                            navigate('/all_vacancies');
                            if (roundResult.Status === '1') {
                                // toast.success('Interview rounds saved successfully', {
                                //     position: 'top-right',
                                //     toastId: customId,
                                //     autoClose: 2000,
                                // });

                                // Navigate to vacancies page after success

                            } else {
                                toast.error('Failed to save interview rounds', {
                                    position: 'top-right',
                                    toastId: customId,
                                    autoClose: 2000,
                                });
                            }
                        })
                } else {
                    toast.error(result.Msg, {
                        position: 'top-right',
                        toastId: customId,
                        autoClose: 2000,
                    });
                }
            })
    };


    const handleJobID = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobIDError('Please enter job ID');
        } else {
            setJobIDError('')
        }
        setJobID(inputValue);
    }

    const handleJobTitle = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "") // Allow alphanumeric and spaces

        if (inputValue.trim() === "") {
            setJobTitleError('Please enter job title');
        } else {
            setJobTitleError('')
        }
        setJobTitle(inputValue)

    }

    const handleJobRole = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobRoleNameError('Please enter job role name');
        } else {
            setJobRoleNameError('')
        }
        setJobRole(inputValue);
    }

    const handleJobExperience = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobExperienceError('Please enter job experience');
        } else {
            setJobExperienceError('')
        }
        setJobExperience(inputValue);
    }

    const handleJobSalary = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobSallaryError('Please enter offered salary');
        } else {
            setJobSallaryError('');
        }

        setJobSalary(inputValue);
    }

    const handleJobLocation = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobLocError('Please enter job location')
        } else {
            setJobLocError('');
        }
        setJobLocation(inputValue);
    }

    const handleJobOpening = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9\s]/g, "")

        if (inputValue.trim() === "") {
            setTotalOpeningError('Please enter total opening')
        } else {
            setTotalOpeningError("")
        }
        setJobOpening(numericValue);
    }

    const handleJobDescription = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[a-zA-Z0-9\s]/g, "");

        if (inputValue.trim() === "") {
            setJobDescriptionError('Please enter job description')
        } else {
            setJobDescriptionError('')
        }

        setJobDescription(inputValue)

    }

    const handlejobResponsibilities = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobResponsibilitiesError('Please enter job responsibilities ')
        } else {
            setJobResponsibilitiesError('')
        }

        setJobResponsibilities(inputValue)
    }

    const hanldeJobQualification = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobQualificationError('Please enter job qualification')
        } else {
            setJobQualificationError('');
        }
        setJobQualification(inputValue);
    }

    const handleJobSkills = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length === 0) {
            setJobSkillError('Please enter job skills')
        } else {
            setJobSkillError('')
        }
        setJobSkills(inputValue)
    }

    const NotAllowedCreateVacancy = () => {
        toast.warn("Please complete the company profile to proceed", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
        });
        navigate('/company_profilePage')
    }


    return (
        <div className='CreateVacancy'>
            <div className='Profile_left' >
                <div className="section_1">
                    <h2>Create <span> Vacancy <WorkHistory /> </span> </h2>
                    <p> Welcome to the Create Vacancy page. Here, you can add new job openings by providing essential details like the job title, description, and requirements. This user-friendly form ensures your listings are thorough and appealing. Once saved, your new vacancies will appear on the Vacancies page for easy management and updates. </p>
                </div>

                <div className='CreateVacancyModals' >
                    <h2>
                        Fill in the required details.
                    </h2>
                    <div id='Type1' >
                        <h3>Job ID<span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please enter the required job ID here eg : IRC231812</p>
                        <input
                            type="text"
                            placeholder='Enter job ID here'
                            value={JobID}
                            tabIndex={1}
                            // onChange={(e) => setJobID(e.target.value)}
                            onChange={handleJobID}
                        />
                        {JobIDError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{JobIDError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Title<span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please enter the required job title here eg : Web Developer</p>
                        <input
                            type="text"
                            placeholder='Enter job title here'
                            value={JobTitle}
                            tabIndex={2}
                            // onChange={(e) => setJobTitle(e.target.value)}
                            onChange={handleJobTitle}
                        />
                        {JobTitleError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{JobTitleError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Role Name<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the particular role name for this vacancy eg: Front End Developer</p>
                        <input
                            type="text"
                            placeholder='Enter the role name here'
                            value={JobRole}
                            tabIndex={3}
                            // onChange={(e) => setJobRole(e.target.value)}
                            onChange={handleJobRole}
                        />
                        {JobRoleNameError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobRoleNameError} </p>
                        }

                    </div>

                    <div id='Type1' >
                        <h3>Experience Required<span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please specify the required experience for eg : Freshers , 1-2 Years , 3+ Years</p>
                        <input
                            type="text"
                            placeholder='Enter experience here'
                            value={JobExperience}
                            tabIndex={4}
                            // onChange={(e) => setJobExperience(e.target.value)}
                            onChange={handleJobExperience}
                        />
                        {JobExprienceError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobExprienceError} </p>
                        }
                    </div>


                    <div id='Type1' >
                        <h3>Offered Salary<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the offered salary for eg : 4-5 LPA</p>
                        <input
                            type="text"
                            placeholder='Enter salary amount here'
                            value={JobSalary}
                            tabIndex={5}
                            // onChange={(e) => setJobSalary(e.target.value)}
                            onChange={handleJobSalary}
                        />
                        {JobSallaryError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobSallaryError} </p>
                        }
                    </div>

                    <div id='Type1'>
                        <h3>Job Location<span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please specify the required Job location for eg : Delhi, Banglore</p>
                        <input
                            type="text"
                            placeholder='Enter location here'
                            value={JobLocation}
                            tabIndex={6}
                            // onChange={(e) => setJobLocation(e.target.value)}
                            onChange={handleJobLocation}
                        />
                        {JobLocError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobLocError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Total Openings<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the total openings for this role for eg: 3, 4</p>
                        <input
                            type="text"
                            placeholder='Enter number of openings here'
                            value={JobOpening}
                            tabIndex={7}
                            // onChange={(e) => setJobOpening(e.target.value)}
                            onChange={handleJobOpening}
                        />
                        {TotalOpeningError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{TotalOpeningError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Description<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please provide a detailed job description for this vacancy.</p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobDescription}
                            tabIndex={8}
                            // onChange={(e) => setJobDescription(e.target.value)}
                            onChange={handleJobDescription}
                            style={{ width: "100%" }}
                            placeholder='Enter decsription here'
                        />
                        {JobDescriptionError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobDescriptionError}</p>
                        }
                    </div>

                    <div id='Type1'>
                        <h3>Job Responsibilities<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please list down the responsibilities involved in this job vacancy for eg:
                            <br />
                            1. Design integrated UI / UX designs using HTML , CSS , Javascript.
                            <br />
                            2. Developing front-end by liaising with the design team.
                        </p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobResponsibilities}
                            tabIndex={9}
                            // onChange={(e) => setJobResponsibilities(e.target.value)}
                            onChange={handlejobResponsibilities}
                            style={{ width: "100%" }}
                            placeholder='Enter jobresponsibilities here'
                        />
                        {JobResponsibilitiesError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobResponsibilitiesError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Qualification <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the qualification required for this vacancy for eg: B.Tech / MCA / etc. </p>
                        <input
                            type="text"
                            placeholder='Enter qualification here'
                            value={JobQualification}
                            tabIndex={10}
                            // onChange={(e) => setJobQualification(e.target.value)}
                            onChange={hanldeJobQualification}
                        />
                        {JobQualificationError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobQualificationError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Skills<span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the skills to be required in this vacancy. </p>
                        <input
                            type="text"
                            placeholder='Enter skill name and press enter.'
                            value={JobSkills}
                            tabIndex={11}
                            // onChange={(e) => setJobSkills(e.target.value)}
                            onChange={handleJobSkills}
                            onKeyDown={handleKeyDown}
                        />
                        {JobSkillError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobSkillError}</p>
                        }
                        <div id='SkillsBox' >
                            {/* <Chip
                                label="Add Skill +"
                            /> */}
                            {SkillsArray.map((data, index) => (
                                <Chip
                                    key={index}
                                    label={data}
                                    onDelete={handleDelete(data)}
                                    style={{ textTransform: 'capitalize' }}
                                />
                            ))}
                        </div>
                    </div>

                    <hr style={{
                        widht: "70%",
                        // height: "150px",
                        margin: "0px 40px 0px 40px",
                        border: "1px lightgray solid",
                        bordeRadius: "5px",
                    }} />


                    <div id='Type1'>
                        <h3 style={{ fontSize: '22px' }}>Interview Rounds<span style={{ color: 'red' }}>*</span></h3>
                        <p>Below is the list of all the rounds that will take place for this vacancy. Please specify your requirements for each </p>
                    </div>

                    <Accordion style={{ border: '2px #A2C4D5 solid' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ height: '15px', backgroundColor: '#A2C4D5' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6">Extempore Round</Typography>
                                {/* <Checkbox
                                    checked={ExtemporeRound}
                                    color="default"
                                /> */}
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className='Accordion_body'>
                                <p style={{ color: "grey", fontSize: '15px' }} >
                                    An Extempore Round is an interview or assessment phase where candidates are given one random question based on the topics or technologies related to the job requirements you provide and asked to speak or present their thoughts impromptu, without prior preparation. This round tests a candidate's ability to think on their feet, communicate clearly, and organize ideas under pressure.
                                </p>

                                <div id='Type1'>
                                    <h3>Difficulty Level<span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please select the difficulty level </p>
                                    <div className="SelectElement">
                                        <select
                                            value={ExtemporeRounddifficultyLevel}
                                            tabIndex="5"
                                            onChange={(e) => setExtemporeRoundDifficultyLevel(e.target.value)}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Difficulty Level-- </option>
                                            <option value="3">Easy</option>
                                            <option value="7">Medium</option>
                                            <option value="10">Hard</option>
                                        </select>
                                    </div>
                                </div>
                                <div id='Type1'>
                                    <h3>Time limit <span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the time limit for this round in minutes eg. if you want 2 minutes for this round then enter 2.</p>
                                    <input
                                        type="text"
                                        placeholder='Enter time here'
                                        value={ExtemporeRoundinterviewtime}
                                        tabIndex={1}
                                        // onChange={(e) => setExtemporeRoundinterviewtime(e.target.value)}
                                        onChange={handleExtemporeRoundinterviewtime}
                                    />
                                </div>
                            </div>

                            <div id='Type1'>
                                <h3>Topics or Technologies<span style={{ color: 'red' }}>*</span></h3>
                                <p>Please enter the topics or technology name from which you want the questions to be asked, for eg: Topic 1 , Topic 2 </p>
                                <input
                                    type="text"
                                    placeholder='Enter the technology'
                                    value={ExtemporeRoundTechnology}
                                    tabIndex={1}
                                    onChange={(e) => setExtemporeRoundTechnology(e.target.value)}
                                />
                            </div>

                            <Button
                                onClick={handleAddExtemporeRound}
                                className='Button_1'
                                variant="contained"
                                tabIndex={12}
                                style={{ marginRight: '12px' }}
                            >
                                Add & submit
                            </Button>

                            {/* <Button
                                expandIcon={() => setIsAccordionOpen(!isAccordionOpen)}
                                className='Button_2'
                                variant="outlined"
                                tabIndex={13}
                                color="inherit"
                            >
                                Cancel
                            </Button> */}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ border: '2px #A2C4D5 solid' }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ height: '15px', backgroundColor: '#A2C4D5' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6">Comprehension Round</Typography>
                                {/* <Checkbox
                                    checked={ComprehensionRound}
                                    color="default"
                                /> */}
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className='Accordion_body'>
                                <p style={{ color: "grey", fontSize: '15px' }} >
                                    The Comprehension Round is an assessment phase where candidates are presented with a question related to topics or technologies related to the job requirements you provide. They are expected to formulate their own responses, demonstrating their understanding and critical thinking skills, and complete the task within the allotted time.
                                </p>

                                <div id='Type1'>
                                    <h3>Difficulty Level<span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please select the difficulty level</p>
                                    <div className="SelectElement">
                                        <select
                                            value={ComprehensiondifficultyLevel}
                                            tabIndex="5"
                                            onChange={(e) => setComprehensiondifficultyLevel(e.target.value)}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Difficulty Level-- </option>
                                            <option value="3">Easy</option>
                                            <option value="7">Medium</option>
                                            <option value="10">Hard</option>
                                        </select>
                                    </div>
                                </div>
                                <div id='Type1'>
                                    <h3>Time limit <span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the time limit for this round in minutes eg. if you want 2 minutes for this round then enter 2.</p>
                                    <input
                                        type="text"
                                        placeholder='Enter the interview time'
                                        value={Comprehensioninterviewtime}
                                        tabIndex={1}
                                        // onChange={(e) => setComprehensioninterviewtime(e.target.value)}
                                        onChange={handleComprehensionInterviewTime}
                                    />
                                </div>
                            </div>

                            <div id='Type1'>
                                <h3>Topics or Technologies<span style={{ color: 'red' }}>*</span></h3>
                                <p>Please enter the topics or technology name from which you want the questions to be asked, for eg: Topic 1 , Topic 2 </p>
                                <input
                                    type="text"
                                    placeholder='Enter the technology'
                                    value={ComprehensionTechnology}
                                    tabIndex={1}
                                    onChange={(e) => setComprehensionTechnology(e.target.value)}
                                />
                            </div>

                            <Button
                                onClick={handleAddComprehensionRound}
                                className='Button_1'
                                variant="contained"
                                tabIndex={12}
                                style={{ marginRight: '12px' }}
                            >
                                Add & Submit
                            </Button>

                            {/* <Button
                                expandIcon={<ExpandMoreIcon />}
                                className='Button_2'
                                variant="outlined"
                                tabIndex={13}
                                color="inherit"
                            >
                                Cancel
                            </Button> */}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ border: '2px #A2C4D5 solid' }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ height: '15px', backgroundColor: '#A2C4D5' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6">Quiz Round</Typography>
                                {/* <Checkbox
                                    checked={QuizRound}
                                    color="default"
                                /> */}
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className='Accordion_body'>
                                <p style={{ color: "grey", fontSize: '15px' }} >
                                    The Quiz Round is an assessment phase where candidates answer a series of questions on topics or technologies related to the job requirements you provide, typically within a set time limit. For each question, the AI will present four options, and candidates must select and submit the correct answer.
                                </p>

                                <div id='Type1'>
                                    <h3>Difficulty Level<span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please select the difficulty level </p>
                                    <div className="SelectElement">
                                        <select
                                            value={QuizdifficultyLevel}
                                            tabIndex="5"
                                            onChange={(e) => setQuizDifficultyLevel(e.target.value)}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Difficulty Level-- </option>
                                            <option value="3">Easy</option>
                                            <option value="7">Medium</option>
                                            <option value="10">Hard</option>
                                        </select>
                                    </div>
                                </div>
                                <div id='Type1'>
                                    <h3>Time limit <span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the time limit for this round in minutes eg. if you want 2 minutes for this round then enter 2.</p>
                                    <input
                                        type="text"
                                        placeholder='Enter the interview time'
                                        value={Quizinterviewtime}
                                        tabIndex={1}
                                        // onChange={(e) => setQuizinterviewtime(e.target.value)}
                                        onChange={handleQuizInterviewTime}
                                    />
                                </div>

                                <div id='Type1'>
                                    <h3>Number of questions <span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the number of questions for this quiz round (e.g., 2)</p>
                                    <input
                                        type="text"
                                        placeholder='Enter the interview time'
                                        value={QuizquestionLimit}
                                        tabIndex={1}
                                        // onChange={(e) => setQuizquestionLimit(e.target.value)}
                                        onChange={handleQuestionLimit}
                                    />
                                </div>
                            </div>

                            <div id='Type1'>
                                <h3>Topics or Technologies<span style={{ color: 'red' }}>*</span></h3>
                                <p>Please enter the topics or technology name from which you want the questions to be asked, for eg: Topic 1 , Topic 2 </p>
                                <input
                                    type="text"
                                    placeholder='Enter the technology'
                                    value={QuizTechnology}
                                    tabIndex={1}
                                    onChange={(e) => setQuizTechnology(e.target.value)}
                                />
                            </div>

                            <Button
                                onClick={handleAddQuizRound}
                                className='Button_1'
                                variant="contained"
                                tabIndex={12}
                                style={{ marginRight: '12px' }}
                            >
                                Add & Submit
                            </Button>

                            {/* <Button
                                onClick={() => navigate('/all_vacancies')}
                                className='Button_2'
                                variant="outlined"
                                tabIndex={13}
                                color="inherit"
                            >
                                Cancel
                            </Button> */}
                        </AccordionDetails>
                    </Accordion>


                    <Accordion style={{ border: '2px #A2C4D5 solid' }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ height: '15px', backgroundColor: '#A2C4D5' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6">Coding Round <span style={{ color: 'white', fontSize: 'Medium' }}> (Only for Software Engineering vacancies)</span> </Typography>
                                {/* <Checkbox
                                    checked={CodingRound}
                                    color="default"
                                /> */}
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className='Accordion_body'>
                                <p style={{ color: "grey", fontSize: '15px' }} >
                                    <span style={{ color: 'indianred' }}> The Coding Round is an assessment phase specifically for software engineering roles.</span> <br />In this round, candidates are required to solve coding problems or complete programming tasks related to the job requirements. The focus is on evaluating their problem-solving skills, coding efficiency, and knowledge of relevant programming languages and algorithms, all within a set time limit.
                                </p>

                                <div id='Type1'>
                                    <h3>Difficulty Level<span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please select the difficulty level</p>
                                    <div className="SelectElement">
                                        <select
                                            value={CodingdifficultyLevel}
                                            tabIndex="5"
                                            onChange={(e) => setCodingDifficultyLevel(e.target.value)}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Difficulty Level-- </option>
                                            <option value="3">Easy</option>
                                            <option value="7">Medium</option>
                                            <option value="10">Hard</option>
                                        </select>
                                    </div>
                                </div>

                                <div id='Type1'>
                                    <h3>Time limit <span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the time limit for this round in minutes eg. if you want 20 minutes for this round then enter 20.</p>
                                    <input
                                        type="text"
                                        placeholder='Enter the interview time'
                                        value={Codinginterviewtime}
                                        tabIndex={1}
                                        // onChange={(e) => setCodinginterviewtime(e.target.value)}
                                        onChange={handleCodingInterviewtime}
                                    />
                                </div>

                                <div id='Type1'>
                                    <h3>Topics or Technologies<span style={{ color: 'red' }}>*</span></h3>
                                    <p>Please enter the topics or technology name from which you want the questions to be asked, for eg: Topic 1 , Topic 2 </p>
                                    <input
                                        type="text"
                                        placeholder='Enter the technology'
                                        value={CodingTechnology}
                                        tabIndex={1}
                                        onChange={(e) => setCodingTechnology(e.target.value)}
                                    />
                                </div>
                            </div>

                            <Button
                                onClick={handleAddCodingRound}
                                className='Button_1'
                                variant="contained"
                                tabIndex={12}
                                style={{ marginRight: '12px' }}
                            >
                                Add & Submit
                            </Button>

                            {/* <Button
                                onClick={() => navigate('/all_vacancies')}
                                expandIcon={<ExpandMoreIcon />}
                                className='Button_2'
                                variant="outlined"
                                tabIndex={13}
                                color="inherit"
                            >
                                Cancel
                            </Button> */}
                        </AccordionDetails>
                    </Accordion>


                    <div style={{ marginTop: '20px' }} >

                        <Button
                            onClick={HandleCreateVacancy}
                            className='Button_1'
                            variant="contained"
                            tabIndex={12}
                            style={{ marginRight: '12px' }}
                        >
                            Create Vacancy
                        </Button>

                        {/* {CheckCompanyProfileID == 'false' ? (
                            <Button variant="contained"
                                onClick={NotAllowedCreateVacancy}
                                className='Button_1'
                                style={{ marginRight: '12px' }}

                            >
                                Create Vacancy
                            </Button>
                        ) : (
                            <Button variant="contained"
                                className='Button_1'
                                onClick={HandleCreateVacancy}
                                style={{ marginRight: '12px' }}

                            >
                                Create Vacancy
                            </Button>
                        )} */}

                        <Button
                            onClick={() => navigate('/all_vacancies')}
                            className='Button_2'
                            variant="outlined"
                            tabIndex={13}
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>

            <div className='Profile_right' >
                {ViewCompanyDetailsLoader ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: '300px',
                    }} >
                        <Bars visible={true} height="25" width="30" color="#749BBC" />
                        <p style={{ padding: '10px 0px ' }}> Loading data !!</p>
                    </div>
                    :
                    <div id='one' >
                        <div id="profile">
                            <div id='ProfileImage'>
                                {/* <img src={Logo} /> */}
                                <div className='profile_logo'>
                                    {CompanyName && CompanyName.split(' ').map(name => name.charAt(0)).join('')}
                                </div>
                            </div>
                        </div>
                        <h2>{CompanyName ? CompanyName : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}  > Add Company Name  </span>}</h2>
                        <p>{CompanyType ? CompanyType : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add Company Type  </span>} </p>
                        <p> <LocationOn id="icon" />
                            {CompanyCity ? CompanyCity : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add City  </span>} , {" "}
                            {CompanyState ? CompanyState : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}> Add State  </span>}
                        </p>
                        <Button variant="contained"
                            onClick={() => navigate('/all_vacancies')}
                            style={{
                                fontSize: '13px',
                                width: '80%',
                                color: 'white',
                                border: "1px solid #4B64FF",
                                backgroundColor: '#4B64FF',
                                border: '0px ',
                                marginTop: '10px'
                            }}
                        >
                            View All Vacancies
                        </Button>
                    </div>
                }
            </div>

        </div>
    )
}

export default CreateVacancy
