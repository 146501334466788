import React, { useEffect, useState } from 'react';
import './VacancyPage.css';
import { Button, makeStyles, OutlinedInput, Tooltip, Modal, Box } from '@mui/material';
import { AccountBalanceWallet, BusinessCenter, EventAvailable, History, HouseRounded, LocationOn, Mail, Money, Phone, WorkHistory, WorkHistoryRounded, WorkOutline } from '@mui/icons-material';
import Logo from '../../../Assets/StarteleLogo.png';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import CloseIcon from "@mui/icons-material/Close";
import SentImg from "../../../Assets/Files sent.gif";

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    border: "none",
    p: 3,
    animationName: "none",
};

const VacancyPage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const Account_Type = localStorage.getItem("Account_Type");
    const isResumeUploaded = localStorage.getItem("CheckResume") === "true";
    const UserUuid = localStorage.getItem("UserUuid");
    const [appliedVacancies, setAppliedVacancies] = useState([]);
    const VacancyIdForViewVacancyPage = localStorage.getItem('VacancyIdForViewVacancyPage')
    const EnterpriseIdForViewVacancyPage = localStorage.getItem("EnterpriseIdForViewVacancyPage");  //This is the tenant uuid
    const CurrentDate = new Date()

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    function FormatDescription(description) {
        return description.replace(/\n/g, '<br/>');
    }

    const [VacancyArray, setVacancyArray] = useState([])
    const [CompanyArray, setCompanyArray] = useState([])
    const [VacancyUUID, setVacancyUUID] = useState("")
    const [CompanyName, setCompanyName] = useState("")
    const [CompanyType, setCompanyType] = useState("")
    const [JobID, setJobID] = useState('')
    // const [PageLoader, setPageLoader] = useState(true)

    const HandleViewVacancy = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseIdForViewVacancyPage,
            "vacancy_uuid": VacancyIdForViewVacancyPage
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_particular_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setVacancyArray(result.vacancies)
                setCompanyArray(result.enterprises)
                setVacancyUUID(result.vacancies[0].vacancy_uuid)
                setCompanyName(result.enterprises[0].company_name)
                setCompanyType(result.enterprises[0].company_type)
                setJobID(result.vacancies[0].job_id)
            })
    }

    useEffect(() => {
        HandleViewVacancy()
    }, [])

    // Modal state for applied successfully
    const [OpenAppliedSuccesfullyModal, setOpenAppliedSuccesfullyModal] = useState(false);

    const handleApply = (vacancy_uuid) => {

        if (!isResumeUploaded) {
            toast("Please complete your profile", {
                position: 'top-right',
                autoClose: 2000
            })
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            vacancy_uuid: vacancy_uuid,
            apply_status: "",
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };


        fetch(`${REACT_APP_BASE_API_URL}/apply`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);

                if (result.Status == "1") {
                    setOpenAppliedSuccesfullyModal(true);
                    fetchAppliedVacancies();
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
            .catch((error) => console.error(error));
    };

    const fetchAppliedVacancies = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/view_applied_vacancies`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);

                setAppliedVacancies(result.apply);
            })
            .catch((error) => console.error(error));
    };
    useEffect(() => {
        fetchAppliedVacancies();
    }, []);

    return (
        <>
            <div className='VacancyPage'>
                <div className='Profile_left' >
                    <div className="section_1">
                        <h2>Vacancy <span> Details <WorkHistory /> </span> </h2>
                        <p>  This section provides all the pertinent information about the job role, responsibilities, qualifications, and other essential criteria required for potential candidates. Please review the information carefully to ensure you meet the necessary requirements and understand the expectations for the position.  </p>
                    </div>

                    {
                        (VacancyArray.length < 1) ?
                            <div style={{ display: 'grid', placeItems: 'center', width: "100%", height: "200px" }} >
                                <Bars
                                    height="50"
                                    width="140"
                                    color="#176D96"
                                    ariaLabel="bars-loading"
                                    visible={true}
                                />
                            </div> :
                            VacancyArray.map((data) => {

                                return <>
                                    <div className="section_2">
                                        <h2>{data.job_role} </h2>
                                        <h3>{CompanyName}</h3>

                                        <div id="part_1">
                                            <ul>
                                                <li>
                                                    <WorkOutline id="icon" /> <p>Job ID : {JobID ? JobID : "--"} </p>
                                                </li>
                                                <li>
                                                    <BusinessCenter id="icon" /> <p>Company Type : {CompanyType ? CompanyType : "--"} </p>
                                                </li>

                                            </ul>
                                            <ul>
                                                <li>
                                                    <LocationOn id="icon" />
                                                    <p>
                                                        Location : {data.job_location}
                                                    </p>
                                                </li>
                                                <li>
                                                    <AccountBalanceWallet id="icon" />
                                                    <p>
                                                        <span>Salary : {data.job_salary}</span>
                                                    </p>
                                                </li>


                                            </ul>
                                            <ul>
                                                <li>
                                                    <>
                                                        <EventAvailable id="icon" />
                                                        <p> Experience : {data.job_experience} </p>
                                                    </>
                                                </li>


                                                <li>
                                                    <p style={{ color: 'grey', fontSize: 'small' }} ><History id="icon" />
                                                        Posted {convertDateFormat(data.created_at)}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="section_2">
                                        <h3 style={{ marginBottom: '8px' }}>Job Description</h3>
                                        <p dangerouslySetInnerHTML={{ __html: FormatDescription(data.job_description) }} />
                                    </div>

                                    <div className="section_2">
                                        <h3 style={{ marginBottom: '8px' }}>Job Responsibilities</h3>
                                        <p dangerouslySetInnerHTML={{ __html: FormatDescription(data.job_responsibilities) }} />
                                    </div>

                                    <div className="section_3">
                                        <p>Role Name : <span> {data.job_title} </span></p>
                                        <p>Job Location : <span>{data.job_location}</span></p>
                                        <p>Total Vacancies : <span>{data.job_opening}</span></p>
                                        <p>Employment Type : <span>Full Time, Permanent</span></p>
                                        <p>Education : <span>{data.job_qualifications} </span></p>
                                        <p>Required Skills  <br />

                                            <div id='SkillsBox' >
                                                {JSON.parse(data.job_skills).map((v) => (
                                                    <Chip key={v} label={v} />
                                                ))}
                                            </div>
                                        </p>
                                    </div>
                                </>
                            })
                    }
                </div>

                <div className='Profile_right' >

                    {(CompanyArray.length < 1) ?
                        <div style={{ width: "100%", height: "150px", display: 'grid', placeItems: 'center' }} >
                            <Bars
                                height="30"
                                width="50"
                                color="#176D96"
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                        </div> :
                        CompanyArray.map((data) => {
                            const isApplied = appliedVacancies.some(
                                (applied) => applied.vacancy_uuid === VacancyUUID
                            );
                            return (
                                <div id='one' >
                                    <div id="profile">
                                        <div id='ProfileImage'>
                                            {/* <img src={Logo} /> */}
                                            <div className='profile_logo'>
                                                {CompanyName && CompanyName.split(' ').map(name => name.charAt(0)).join('')}
                                            </div>

                                        </div>
                                    </div>

                                    <h2>{data.company_name}</h2>
                                    <p>{data.company_type} </p>
                                    <p> <LocationOn id="icon" /> {data.company_city} {" , "} {data.company_state} </p>
                                    <p>{data.company_tag_line}</p>

                                    {Account_Type === "Job_Seeker" ? (
                                        isResumeUploaded ? (
                                            <Tooltip title={isApplied ? "You have already applied" : "You can now apply"}>

                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        fontSize: "13px",
                                                        color: "white",
                                                        width: "80%",
                                                        backgroundColor: isApplied ? "green" : "#176D96",
                                                        cursor: isApplied ? "default" : "pointer",
                                                    }}
                                                    onClick={() => !isApplied && handleApply(VacancyUUID)}
                                                    disabled={isApplied}
                                                >
                                                    {isApplied ? "Applied" : "APPLY"}
                                                </Button>

                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Resume must be uploaded to apply">

                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        fontSize: "13px",
                                                        color: "white",
                                                        width: "80%",
                                                        backgroundColor: "#176D96",
                                                        cursor: "default",
                                                    }}
                                                    disabled
                                                >
                                                    APPLY
                                                </Button>


                                            </Tooltip>
                                        )
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={() => navigate('/all_vacancies')}
                                            style={{
                                                fontSize: "13px",
                                                width: "80%",
                                                color: "white",
                                                backgroundColor: "#4B64FF",
                                                border: "none",
                                                marginTop: "10px",
                                            }}
                                        >
                                            View All Vacancies
                                        </Button>
                                    )}

                                    <Button
                                        variant="contained"
                                        onClick={() => navigate('/view_company_profilepage')}
                                        style={{
                                            fontSize: "13px",
                                            marginTop: '3%',
                                            color: "white",
                                            width: "80%",
                                            backgroundColor: "#176D96",
                                            cursor: "pointer",
                                        }}
                                    >
                                        View Company Profile
                                    </Button>

                                </div>
                            )
                        })
                    }
                </div>

            </div >

            {/* Applied Successfully Modal  */}
            <Modal Modal
                open={OpenAppliedSuccesfullyModal}
                onClose={() => setOpenAppliedSuccesfullyModal(false)}
            >
                <Box sx={{ ...ModalStyle2 }}>
                    <CloseIcon
                        onClick={() => setOpenAppliedSuccesfullyModal(false)}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "15px",
                            color: "black",
                            fontSize: "35px",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center", // Center align text
                            rowGap: "10px",
                        }}
                    >
                        <img src={SentImg} style={{ height: "280px", width: "auto" }} />
                        <h1
                            style={{
                                color: "#232a58",
                                fontSize: "23px",
                                fontWeight: "400",
                            }}
                        >
                            You have Successfully Applied for the Job !
                        </h1>
                        {/* <p
              style={{
                fontSize: "17px",
                fontWeight: "400",
                color: "grey",
              }}
            >
              You can now visit the applied jobs page (accessible via the top navbar) and proceed with the first round of your interview by clicking the "Start Assessment" button.
            </p> */}

                        <div>
                            <Button
                                className="Button_1"
                                variant="contained"
                                style={{ margin: "0px 10px" }}
                                onClick={() => navigate('/applied_vacancy')}
                            >
                                View Applied Jobs
                            </Button>

                            {/* <Button
                onClick={() => setOpenAppliedSuccesfullyModal(false)}
                style={{ color: "indianRed" }}
                variant="outlined"
                color="inherit"
              >
               Close
              </Button> */}
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default VacancyPage
