import React, { useState, useEffect } from 'react';
import './EnterpriseOwnerProfilePage.css';
import { Hourglass } from 'react-loader-spinner';
import {
    BusinessCenter, CreateRounded, LocationOn, Mail, Phone, ContactMailRounded, Call, BoyOutlined,
    GirlOutlined
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel, } from '@mui/lab';
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Autocomplete,
    TextField,
    Tabs,
    Tooltip,
    Chip,
    InputBase,
    outlinedInputClasses,
    Icon,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import { Fab, IconButton, Input } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SimpleCard from "../../Components/SimpleCard";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Country, State, City } from "country-state-city";

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    p: 3,
    animationName: "none",
    maxHeight: "80vh", // Set a maximum height for the modal
    overflowY: "auto", // Enable vertical scrolling when content overflows
};


const EnterpriseOwnerProfilePage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast";

    const navigate = useNavigate();
    const CurrentDate = new Date();

    const FullName = localStorage.getItem("FullName");
    const UserUuid = localStorage.getItem("UserUuid");
    const Designation = localStorage.getItem("Designation");
    const Experience = localStorage.getItem("Experience");

    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");

    const EmailId = localStorage.getItem("EmailId");
    const MobileNumber = localStorage.getItem("MobileNumber");
    const CheckProfileId = localStorage.getItem("CheckProfileId");

    // API Reponse status states to manage loading
    const [AllDataResponse, setAllDataResponse] = useState("0");


    const convertDateFormat = (isoDate) => {
        // For converting the date format for DOB
        const date = new Date(isoDate);
        const dateString = date.toDateString().split(" ");
        return `${dateString[1]} ${dateString[2]} , ${dateString[3]}`;
    };

    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1980, currentYear + 3);
    const MonthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [ProfileSection, setProfileSection] = useState("1");
    const handleProfileChangeTab = (setValue, newValue) => {
        setProfileSection(newValue);
    };

    // modal state for edit the details
    const [OpenEditBasicDetailsModal, setOpenEditBasicDetailsModal] = useState(false);

    // Profile details states here
    const [OpenCreateProfileModal, setOpenCreateProfileModal] = useState(true); // This profile will open initially when user login after sign in
    useEffect(() => {
        if (CheckProfileId == "true") {
            setOpenCreateProfileModal(false);
            ViewProfileBasicDetails();

        } else {
            setOpenCreateProfileModal(true);
        }
    }, []);

    const [ProfileFullName, setProfileFullName] = useState(FullName);
    const [FullNameError, setFullNameError] = useState('');
    const [ProfileGender, setProfileGender] = useState("0");
    const [ProfileGenderError, setProfileGenderError] = useState('')
    const [ProfileEmail, setProfileEmail] = useState("");
    const [DOB, setDOB] = useState(CurrentDate);
    const [DOBError, setDOBError] = useState('')
    const [ProfileLanguages, setProfileLanguages] = useState([]);
    const [ProfileMobileNumber, setProfileMobileNumber] = useState("");
    const [ProfileAddress, setProfileAddress] = useState("");
    const [ProfileAddressError, setProfileAddressError] = useState('')
    const [ProfileCountryName, setProfileCountryName] = useState("0");
    const [ProfileStateName, setProfileStateName] = useState("0");
    const [ProfileCityName, setProfileCityName] = useState("0")
    const [ProfieleCountryError, setProfileCountryError] = useState('')
    const [ProfileStateError, setProfileStateError] = useState('')
    const [ProfileCityError, setProfileCityError] = useState('')
    const [ProfileMaritalStatus, setProfileMaritalStatus] = useState("0");
    const [ProfileMaritalError, setProfileMaritalError] = useState('')
    const [profileNoticePeriod, setProfileNoticePeriod] = useState("")
    const [ProfileExperience, setProfileExperience] = useState("");
    const [ProfileDesignation, setProfileDesignation] = useState("");
    const [ProfileCurrentAddress, setProfileCurrentAddress] = useState("");
    const [ProfileCurrentAddressError, setProfileCurrentAddressError] = useState('');
    const [PermanentCountry, setPermanentCountry] = useState('0')
    const [PermanentState, setPermanentState] = useState('0');
    const [PermanentCity, setPermanentCity] = useState('0');

    const [PermanentCountryError, setPermanentCountryError] = useState('');
    const [PermanentStateError, setPermanentStateError] = useState('');
    const [PermanentCityError, setPermanentCityError] = useState('')


    const [AllStates, setAllStates] = useState([]);
    const [statesMap, setStatesMap] = useState({});
    const [AllCities, setAllCities] = useState([]);


    const [AllPermanentStates, setAllPermanentStates] = useState([])
    const [PermanentStatesMap, setPermanentStatesMap] = useState({});
    const [AllPermanentCities, setAllPermanentCities] = useState([])

    const countries = Country.getAllCountries();

    const countryMap = countries.reduce((acc, country) => {
        acc[country.name] = country.isoCode;
        return acc;
    }, {});

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        ...country,
    }));

    const getStates = (countryIsoCode) => {
        const stateList = State.getStatesOfCountry(countryIsoCode);

        const statesMap = stateList.reduce((acc, state) => {
            acc[state.name] = state.isoCode;
            return acc;
        }, {});

        setStatesMap(statesMap);

        const mappedStates = stateList.map((state) => ({
            label: state.name,
            value: state.name,
            isoCode: state.isoCode,
            ...state,
        }));
        setAllStates(mappedStates);

        return mappedStates;
    };

    // Updating the selected state value
    useEffect(() => {
        if (ProfileCountryName) {
            const countryIsoCode = countryMap[ProfileCountryName];
            getStates(countryIsoCode);
        }
    }, [ProfileCountryName]);

    // Geeting the all cities name based on selected state
    const getCities = (countryIsoCode, stateIsoCode) => {
        const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

        const mappedCities = cityList.map((city) => ({
            label: city.name,
            value: city.name,
            ...city,
        }));
        setAllCities(mappedCities);

        return mappedCities;
    };

    // Updating the cities name in the city selectbox
    useEffect(() => {
        if (ProfileStateName) {
            const countryIsoCode = countryMap[ProfileCountryName];
            const stateIsoCode = statesMap[ProfileStateName];
            getCities(countryIsoCode, stateIsoCode);
        }
    }, [ProfileStateName, statesMap]);


    const [SameAsPermanentAddress, setSameAsPermanentAddress] = useState(ProfileAddress === ProfileCurrentAddress ? true : false)

    // Effect to set initial address if SameAsPermanentAddress is true
    useEffect(() => {
        if (SameAsPermanentAddress) {
            // setProfileAddress(
            //     `${ProfileCityName === "0" ? "" : ProfileCityName}, ${ProfileStateName === "0" ? "" : ProfileStateName}, ${ProfileCountryName === "0" ? "" : ProfileCountryName}`
            // );
            setProfileAddress(ProfileCurrentAddress)

        }
    }, [SameAsPermanentAddress]);

    useEffect(() => {
        setSameAsPermanentAddress(ProfileAddress === ProfileCurrentAddress);
    }, [ProfileAddress, ProfileCurrentAddress]);

    // Geeting the all Shipping country name 
    const PermanentCountries = countries.map((country) => ({
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        ...country
    }));

    //Geeting the all shipping states name based on selected country 
    const getPermanentStates = (countryIsoCode) => {
        const stateList = State.getStatesOfCountry(countryIsoCode);


        const PermanentStatesMap = stateList.reduce((acc, state) => {
            acc[state.name] = state.isoCode;
            return acc;
        }, {});

        setPermanentStatesMap(PermanentStatesMap);

        const mappedStates = stateList.map((state) => ({
            label: state.name,
            value: state.name,
            isoCode: state.isoCode,
            ...state
        }));
        setAllPermanentStates(mappedStates);

        return mappedStates;
    };

    // Updating the state select box 
    useEffect(() => {
        if (PermanentCountry) {
            const countryIsoCode = countryMap[PermanentCountry];
            getPermanentStates(countryIsoCode);
        }
    }, [PermanentCountry]);


    // Geeting the all Cities name based on selected state
    const getPermanentCities = (countryIsoCode, stateIsoCode) => {
        const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

        const mappedCities = cityList.map((city) => ({
            label: city.name,
            value: city.name,
            ...city
        }));
        setAllPermanentCities(mappedCities);

        return mappedCities;
    };

    // Updating the shipping state selectbox 
    useEffect(() => {
        if (PermanentState) {
            const countryIsoCode = countryMap[PermanentCountry];
            const stateIsoCode = PermanentStatesMap[PermanentState];

            getPermanentCities(countryIsoCode, stateIsoCode);
        }
    }, [PermanentState, PermanentStatesMap]);

    const handleCheckboxChange = () => {
        setSameAsPermanentAddress((prevSameAsPermanentAddress) => !prevSameAsPermanentAddress)
        if (!SameAsPermanentAddress) {
            // setProfileAddress(
            //     `${ProfileCityName}, ${ProfileStateName},  ${ProfileCountryName} `
            // );
            setProfileAddress(ProfileCurrentAddress)
        }
        else {
            setProfileAddress("");
        }
    };

    const ViewProfileBasicDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: "",
            tenant_uuid: EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAllDataResponse(result.Status)
                setProfileFullName(result.account[0].full_name);
                localStorage.setItem("FullName", result.account[0].full_name)
                setProfileGender(result.profiles[0].gender);
                setProfileEmail(result.account[0].email_id);
                setDOB(result.profiles[0].dob);
                setProfileLanguages(result.profiles[0].language);
                setProfileMobileNumber(result.account[0].mobile_number);

                setProfileAddress(result.profiles[0].permanent_address);

                setPermanentCountry(result.profiles[0].permanent_country);
                setPermanentState(result.profiles[0].permanent_state);
                setPermanentCity(result.profiles[0].permanent_city);

                setProfileCurrentAddress(result.profiles[0].current_address)
                setProfileCountryName(result.profiles[0].current_country);
                setProfileStateName(result.profiles[0].current_state);
                setProfileCityName(result.profiles[0].current_city);
                setProfileMaritalStatus(result.profiles[0].marital_status);
                setProfessionalSummary(result.profiles[0].profile_summary);
                setProfileExperience(result.account[0].experience);
                setProfileDesignation(result.account[0].designation);

                const filteredNoticePeriod = result.notice_period.filter(item => item.notice_period !== "0");
                if (filteredNoticePeriod.length > 0) {
                    setProfileNoticePeriod(filteredNoticePeriod[0].notice_period);
                }
            });
    };

    const CreateProfileDetails = () => {

        if (SameAsPermanentAddress === true) {
            if (ProfileGender == 0 || ProfileGender === "") {
                setProfileGenderError('Please select gender');
            } else {
                setProfileGenderError('');
            }
            // if(DOB === CurrentDate  || DOB === ""){
            //   setDOBError('Please select DOB')
            // }else{
            //   setDOBError('');

            // }
            if (!ProfileCurrentAddress) {
                setProfileCurrentAddressError('Please enter your current address')
            } else {
                setProfileCurrentAddressError("");
            }
            if (ProfileCountryName == 0 || ProfileCountryName === "") {
                setProfileCountryError('Please select your country ');
            } else {
                setProfileCountryError('');
            }

            if (ProfileStateName == 0 || ProfileStateName === "") {
                setProfileStateError('Please select your state ');
            } else {
                setProfileStateError('');
            }

            if (ProfileCityName == 0 || ProfileCityName === "") {
                setProfileCityError('Please select your city ');
            } else {
                setProfileCityError('');
            }

            if (ProfileMaritalStatus == 0 || ProfileMaritalStatus === "") {
                setProfileMaritalError('Please select marital status');
            } else {
                setProfileMaritalError('');
            }


            if (
                !ProfileFullName ||
                !DOB ||
                ProfileGender == 0 ||
                // !ProfileAddress ||
                !ProfileCurrentAddress ||
                ProfileCountryName == 0 ||
                ProfileStateName == 0 ||
                ProfileCityName == 0 ||
                ProfileMaritalStatus == 0 ||
                !ProfileLanguages
            ) {
                toast.warn("Please fill in all required fields", {
                    position: "top-right",
                    autoClose: 2000,
                    toastId: customId,
                });
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                user_uuid: "",
                tenant_uuid: EnterpriseUuid,
                gender: ProfileGender,
                dob: DOB,
                full_name: ProfileFullName,
                permanent_address: ProfileCurrentAddress,
                current_address: ProfileCurrentAddress,
                current_city: ProfileCityName,
                current_state: ProfileStateName,
                current_country: ProfileCountryName,
                permanent_country: ProfileCountryName,
                permanent_state: ProfileStateName,
                permanent_city: ProfileCityName,
                marital_status: ProfileMaritalStatus,
                language: ["Hindi", "English"],
                profile_summary: "No profile summary..",
            });


            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            // console.log(raw, 'rawwwwwwwwwwwwwwwwwwwwww')


            fetch(`${REACT_APP_BASE_API_URL}/create_profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.Status === "1") {
                        toast.success(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        localStorage.setItem(
                            "CheckProfileId",
                            "profile_uuid" in result.profiles[0]
                        );
                        setOpenCreateProfileModal(false);
                    } else {
                        toast.error(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        return;
                    }
                });

        } else {

            if (ProfileGender == 0 || ProfileGender === "") {
                setProfileGenderError('Please select gender');
            } else {
                setProfileGenderError('');
            }
            // if(DOB === CurrentDate  || DOB === ""){
            //   setDOBError('Please select DOB')
            // }else{
            //   setDOBError('');

            // }
            if (!ProfileCurrentAddress) {
                setProfileCurrentAddressError('Please enter your current address')
            }
            else {
                setProfileCurrentAddressError('')
            }
            if (ProfileCountryName == 0 || ProfileCountryName === "") {
                setProfileCountryError('Please select your country ');
            } else {
                setProfileCountryError('');
            }

            if (ProfileStateName == 0 || ProfileStateName === "") {
                setProfileStateError('Please select your state ');
            } else {
                setProfileStateError('');
            }

            if (ProfileCityName == 0 || ProfileCityName === "") {
                setProfileCityError('Please select your city ');
            } else {
                setProfileCityError('');
            }

            if (!ProfileAddress) {
                setProfileAddressError('Please enter your permanent address');
            } else {
                setProfileAddressError('')
            }

            if (PermanentCountry == 0 || PermanentCountry === "") {
                setPermanentCountryError('Please select your country ');
            } else {
                setPermanentCountryError('');
            }

            if (PermanentState == 0 || PermanentState === "") {
                setPermanentStateError('Please select your state ');
            } else {
                setPermanentStateError('');
            }

            if (PermanentCity == 0 || PermanentCity === "") {
                setPermanentCityError('Please select your city ');
            } else {
                setPermanentCityError('');
            }

            // if (!SameAsPermanentAddress && (!ProfileAddress)) {
            //     setProfileAddressError('Please enter your address');
            // } else {
            //     setProfileAddress('')
            // }

            if (ProfileMaritalStatus == 0 || ProfileMaritalStatus === "") {
                setProfileMaritalError('Please select marital status');
            } else {
                setProfileMaritalError('');
            }


            if (
                !ProfileFullName ||
                !DOB ||
                ProfileGender == 0 ||
                !ProfileAddress ||
                !ProfileCurrentAddress ||
                ProfileCountryName == 0 ||
                ProfileStateName == 0 ||
                ProfileCityName == 0 ||
                PermanentCountry == 0 ||
                PermanentState == 0 ||
                PermanentCity == 0 ||
                ProfileMaritalStatus == 0 ||
                !ProfileLanguages
            ) {
                toast.warn("Please fill in all required fields", {
                    position: "top-right",
                    autoClose: 2000,
                    toastId: customId,
                });
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                user_uuid: "",
                tenant_uuid: EnterpriseUuid,
                gender: ProfileGender,
                dob: DOB,
                full_name: ProfileFullName,
                permanent_address: ProfileAddress,
                current_address: ProfileCurrentAddress,
                current_city: ProfileCityName,
                current_state: ProfileStateName,
                current_country: ProfileCountryName,
                permanent_country: PermanentCountry,
                permanent_state: PermanentState,
                permanent_city: PermanentCity,
                marital_status: ProfileMaritalStatus,
                language: ["Hindi", "English"],
                profile_summary: "No profile summary..",
            });


            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };


            // console.log(raw, 'rawwwwwwwwwwwwwwwwwwww')


            fetch(`${REACT_APP_BASE_API_URL}/create_profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.Status === "1") {
                        toast.success(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        localStorage.setItem(
                            "CheckProfileId",
                            "profile_uuid" in result.profiles[0]
                        );
                        setOpenCreateProfileModal(false);
                    } else {
                        toast.error(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        return;
                    }
                });

        }


    };

    const EditProfileDetails = () => {

        // if(!ProfileFullName){
        //   setFullNameError('Please enter full name');
        //   return;
        // }

        if (SameAsPermanentAddress === true) {
            if (
                !ProfileFullName ||
                !DOB ||
                ProfileGender == 0 ||
                !ProfileCurrentAddress ||
                ProfileCountryName == 0 ||
                ProfileStateName == 0 ||
                ProfileCityName == 0 ||
                ProfileMaritalStatus == 0 ||
                !ProfileLanguages
            ) {
                toast.warn("Please fill in all required fields", {
                    position: "top-right",
                    autoClose: 2000,
                    toastId: customId,
                });
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                user_uuid: "",
                tenant_uuid: EnterpriseUuid,
                gender: ProfileGender,
                dob: DOB,
                full_name: ProfileFullName,
                current_address: ProfileCurrentAddress,
                current_city: ProfileCityName,
                current_state: ProfileStateName,
                current_country: ProfileCountryName,
                permanent_address: ProfileCurrentAddress,
                permanent_country: ProfileCountryName,
                permanent_state: ProfileStateName,
                permanent_city: ProfileCityName,
                marital_status: ProfileMaritalStatus,
                language: ["Hindi", "English"],
                profile_summary: "No profile summary..",
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            // console.log(raw, 'rawwwwwwwwwwwwwwwwwwwwwww')

            fetch(`${REACT_APP_BASE_API_URL}/edit_profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.Status == "1") {
                        toast.success(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        setOpenEditBasicDetailsModal(false);
                        ViewProfileBasicDetails();
                    } else if (result.Status == "0") {
                        toast.error(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        return;
                    }
                });

        } else {
            if (
                !ProfileFullName ||
                !DOB ||
                ProfileGender == 0 ||
                !ProfileAddress ||
                !ProfileCurrentAddress ||
                ProfileCountryName == 0 ||
                ProfileStateName == 0 ||
                ProfileCityName == 0 ||
                PermanentCountry == 0 ||
                PermanentState == 0 ||
                PermanentCity == 0 ||
                ProfileMaritalStatus == 0 ||
                !ProfileLanguages
            ) {
                toast.warn("Please fill in all required fields", {
                    position: "top-right",
                    autoClose: 2000,
                    toastId: customId,
                });
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                user_uuid: "",
                tenant_uuid: EnterpriseUuid,
                gender: ProfileGender,
                dob: DOB,
                full_name: ProfileFullName,
                permanent_address: ProfileAddress,
                current_address: ProfileCurrentAddress,
                current_city: ProfileCityName,
                current_state: ProfileStateName,
                current_country: ProfileCountryName,
                permanent_country: PermanentCountry,
                permanent_state: PermanentState,
                permanent_city: PermanentCity,
                marital_status: ProfileMaritalStatus,
                language: ["Hindi", "English"],
                profile_summary: "No profile summary..",
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            // console.log(raw, 'rawwwwwwwwwwwwwwwwwwwwwww')

            fetch(`${REACT_APP_BASE_API_URL}/edit_profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.Status == "1") {
                        toast.success(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        setOpenEditBasicDetailsModal(false);
                        ViewProfileBasicDetails();
                    } else if (result.Status == "0") {
                        toast.error(result.Msg, {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 2000,
                        });
                        return;
                    }
                });

        }


    };


    // validation start here for create profile 

    const handleProfileName = (e) => {
        const inputValue = e.target.value;
        setProfileFullName(inputValue);

        // If the input value is blank
        if (inputValue.trim() === "") {
            setFullNameError("Please enter Full name");
        } else {
            // Remove non-alphabetic characters
            const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
            setProfileFullName(alphabeticValue);
            setFullNameError("");
        }
    }

    const handleProfileGender = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setProfileGenderError('Please select gender')
        } else {
            setProfileGenderError('');
            setProfileGender(inputValue)
        }
    }

    // const handleChangeDOB = (e) => {
    //   const inputValue = e.target.value;
    //   if(inputValue === CurrentDate  || inputValue === ""){
    //     setDOBError('Please select DOB')
    //   }else{
    //     setDOBError('');
    //     setDOB(inputValue)
    //   }

    // }

    const handleChangeCountry = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setProfileCountryError('Please select your country')
        } else {
            setProfileCountryError('');

        }
        setProfileCountryName(inputValue)
        setProfileStateName("0");
        setProfileCityName("0");
    }

    const handleChangeState = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setProfileStateError('Please select your state')
        } else {
            setProfileStateError('');
        }
        setProfileStateName(inputValue)
        setProfileCityName('0')
    }

    const handleChangeCity = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setProfileCityError('Please select your city ')
        } else {
            setProfileCityError('');
            setProfileCityName(inputValue)
        }
    }

    const handlePermanentCountry = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setPermanentCountryError('Please select your country')
        } else {
            setPermanentCountryError('')
            setPermanentCountry(inputValue)
        }
    }

    const handlePermanentState = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setPermanentStateError('Please select your state');
        } else {
            setPermanentStateError('');
            setPermanentState(inputValue);
        }
    }

    const hanldePermanentCity = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setPermanentCityError('Please select your city');
        } else {
            setPermanentCityError('');
            setPermanentCity(inputValue);
        }
    }

    const handleChangeProfileAddress = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, ""); // Allow alphanumeric and spaces

        if (inputValue.trim() === "") { // Check if the input is empty
            setProfileAddressError('Please enter your permanent address');
        } else {
            setProfileAddressError('');
        }

        setProfileAddress(inputValue);
    }

    const handleCurrentAddress = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, ""); // Allow alphanumeric and spaces

        if (inputValue.trim() === "") { // Check if the input is empty
            setProfileCurrentAddressError('Please enter your current address');
        } else {
            setProfileCurrentAddressError('');
        }

        setProfileCurrentAddress(inputValue);
    }

    const handleChangeMaritalStatus = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setProfileMaritalError('Please select marital status')
        } else {
            setProfileMaritalError('');
            setProfileMaritalStatus(inputValue)
        }
    }




    // modal state for professional summary 
    const [ProfessionalSummary, setProfessionalSummary] = useState("");
    const [canUseSpace, setCanUseSpace] = useState(false);
    const [
        OpenEditProfessionalSummaryModal,
        setOpenEditProfessionalSummaryModal,
    ] = useState(false);

    const handleProfessionalSummaryInput = (e) => {
        const input = e.target.value;

        // Check if there's any non-space character in the input
        const hasNonSpaceCharacter = /\S/.test(input);

        // Allow spaces only if there's at least one non-space character
        const newValue = hasNonSpaceCharacter ? input : input.replace(/\s/g, "");

        // Update the state
        setProfessionalSummary(newValue);
        setCanUseSpace(hasNonSpaceCharacter);
    };



    const SaveProfessionalSummary = () => {

        // if (!ProfessionalSummary) {
        //   toast.warn("Please enter professional summary", {
        //     position: 'top-right',
        //     autoClose: 2000,
        //     toastId: customId
        //   })
        //   return;
        // }

        // if (ProfessionalSummary.length > 500) {
        //   toast.warn("Please enter up to a maximum of 500 words", {
        //     position: "top-right",
        //     autoClose: 2000,
        //     toastId: customId
        //   })
        //   return;
        // }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: "",
            tenant_uuid: EnterpriseUuid,
            gender: ProfileGender,
            dob: DOB,
            full_name: ProfileFullName,
            permanent_address: ProfileAddress,
            current_address: ProfileCurrentAddress,
            current_city: ProfileCityName,
            current_state: ProfileStateName,
            current_country: ProfileCountryName,
            permanent_country: PermanentCountry,
            permanent_state: PermanentState,
            permanent_city: PermanentCity,
            marital_status: ProfileMaritalStatus,
            language: ["Hindi", "English"],
            profile_summary: ProfessionalSummary,
        });




        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };


        fetch(`${REACT_APP_BASE_API_URL}/edit_profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                if (result.Status == "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    setProfessionalSummary(ProfessionalSummary);
                    setOpenEditProfessionalSummaryModal(false);
                    ViewProfileBasicDetails();
                } else {
                    toast.error("Unable to add Professional Summary", {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => console.error(error));
    };





    // Personal Information States and modal opening states

    const [PersonalInfoSection, setPersonalInfoSection] = useState("1");

    const handleProfileInfoChangeTab = (setValue, newValue) => {
        setPersonalInfoSection(newValue);
    };

    const [SettingsSection, setSettingsSection] = useState("1");
    const handleChangeSettingSection = (setValue, newValue) => {
        setSettingsSection(newValue);
    };

    // state for taking the input from user 
    const [CurreentPassword, setCurreentPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [RetypeNewPassword, setRetypeNewPassword] = useState('')

    // state for eye icon on and off 
    const [ShowCurrentPassword, setShowCurrentPassword] = useState(false);
    const [ShowNewPassword, setShowNewPassword] = useState(false);
    const [ShowRetypePassword, setShowRetypePassword] = useState(false)

    // error message state start here
    const [NewPasswordError, setNewPasswordError] = useState('');
    const [NewPasswordFlag, setNewPasswordFlag] = useState(true);
    const [RetypeNewPasswordError, setRetypeNewPasswordError] = useState('');
    const [RetypeNewPasswordFlag, setRetypeNewPasswordFlag] = useState(true);

    const maxPasswordLength = 16;


    const handleNewPassword = (e) => {

        const inputValue = e.target.value;
        // setNewPassword(inputValue);

        if (inputValue.length < 6 || inputValue.length > maxPasswordLength) {
            setNewPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
            setNewPasswordFlag(false)
        } else {

            setNewPasswordError("");
            setNewPasswordFlag(true)
        }
        setNewPassword(inputValue);

    }

    const handleRetypePassword = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length < 6 || inputValue.length > maxPasswordLength) {
            setRetypeNewPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
            setRetypeNewPasswordFlag(false);

        } else {

            setRetypeNewPasswordError("");
            setRetypeNewPasswordFlag(true);
        }
        setRetypeNewPassword(inputValue)
    }

    return (
        <>

            {/* {AllDataResponse !== "1" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: "100vh",
                        width: "100vw",
                        position: "fixed",
                        zIndex: '100',
                        top: 0,
                        overflow: 'hidden'
                    }}
                >
                    <Hourglass visible={true} height="40" width="40" colors={['#306cce', '#72a1ed']} />
                    <p style={{ padding: '10px 0px ' }}> Loading data !!</p>
                </div>
            )} */}

            <div
                // style={{ opacity: AllDataResponse === "1" ? "1" : "0.3" }}
                className="EnterpriseOwnerProfilePage ">
                <h2>
                    Profile <span> details</span>{" "}
                </h2>

                <div className="profile">

                    <div className="two">
                        <div id="part_1">
                            <div>
                                <h2>
                                    {" "}
                                    {ProfileFullName}
                                    {ProfileGender === "Male" ? (<BoyOutlined
                                        style={{ fontSize: "inherit", color: "#232a58" }}
                                    />) : (<GirlOutlined
                                        style={{ fontSize: "inherit", color: "pink" }}
                                    />)}

                                </h2>
                            </div>
                        </div>
                        <div id="part_2">
                            <ul>
                                <li>
                                    <LocationOn id="icon" />{" "}
                                    <p>
                                        {ProfileCityName == "0" ? (
                                            "No Address"
                                        ) : (
                                            <>
                                                {ProfileCityName}
                                            </>
                                        )}
                                    </p>
                                </li>
                                {/* <li>
                                    <BusinessCenter id="icon" />
                                    <p>
                                        Occupation
                                    </p>
                                </li> */}

                                <li>
                                    {" "}
                                    <Mail id="icon" />{" "}
                                    <p>
                                        {" "}
                                        {EmailId}
                                    </p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Phone id="icon" />
                                    <p>
                                        <span
                                            
                                        >
                                            {MobileNumber}
                                        </span>
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="one">
                        <div className="one_image">
                            <div
                                style={{
                                    left: "10px",
                                    color: "#333",
                                    fontSize: "70px",
                                    width: "140px",
                                    height: "140px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: 'uppercase'
                                }}
                            >
                                {ProfileFullName && ProfileFullName.split(' ').map(name => name.charAt(0)).join('')}
                                {/* RK */}
                            </div>
                        </div>
                    </div>

                    {/* <hr /> */}

                    {/* <div className="three">
                        <h2>
                            Professional Summary
                            <CreateRounded
                                id="editIcon"
                            />
                        </h2>
                        Summary here
                    </div> */}
                </div>

                <div className="ProfileDetails">
                    <TabContext value={ProfileSection}>
                        <Box>
                            <TabList onChange={handleProfileChangeTab} centered>
                                <Tab label="Personal Information" value="1" />
                                {/* <Tab label="Settings" value="2" /> */}
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    bgcolor: "background.paper",
                                    display: "flex",
                                }}
                            >
                                <TabContext value={PersonalInfoSection}>
                                    <TabList
                                        onChange={handleProfileInfoChangeTab}
                                        orientation="vertical"
                                        sx={{
                                            borderRight: 1,
                                            borderColor: "divider",
                                            width: "16%",
                                        }}
                                    >
                                        <Tab label="Basic Details" value="1" />
                                        <Tab label="Professional Summary" value="2" />
                                        {/* <Tab label="Experience" value="3" /> */}
                                    </TabList>

                                    <TabPanel
                                        value="1"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <h2>
                                            Basic Details{" "}
                                            <CreateRounded onClick={() => setOpenEditBasicDetailsModal(!OpenEditBasicDetailsModal)}
                                                id="editIcon"
                                            />{" "}
                                        </h2>

                                        <ul>
                                            <li>
                                                <p> Name </p>{" "}
                                                <span>
                                                    {ProfileFullName ? (
                                                        ProfileFullName
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>
                                            <li>
                                                <p> Mobile Number </p>{" "}
                                                <span>
                                                    {ProfileMobileNumber ? (
                                                        ProfileMobileNumber
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>
                                            <li>
                                                <p> Email Id </p>{" "}
                                                <span>
                                                    {ProfileEmail ? (
                                                        ProfileEmail
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p>Permanent Address </p>{" "}
                                                <span>
                                                    {ProfileAddress ? (
                                                        ProfileAddress + "," + PermanentCity + "," + PermanentState
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Gender </p>{" "}
                                                <span>
                                                    {" "}
                                                    {ProfileGender !== "0" ? (
                                                        ProfileGender
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Marital Status </p>{" "}
                                                <span>
                                                    {" "}
                                                    {ProfileMaritalStatus !== "0" ? (
                                                        ProfileMaritalStatus
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Date of Birth </p>
                                                <span>
                                                    {DOB ? (
                                                        convertDateFormat(DOB)
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>

                                            <li>
                                                <p>Current Address </p>
                                                <span>
                                                    {ProfileCurrentAddress ? (

                                                        ProfileCurrentAddress + "," + ProfileCityName + "," + ProfileStateName





                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                            onClick={() => setOpenEditBasicDetailsModal(true)}
                                                        >
                                                            Add Address
                                                        </span>
                                                    )}
                                                </span>
                                            </li>
                                        </ul>

                                        {/* <div style={{ marginTop: "20px" }}>
                                            <h2>
                                                Professional Summary{" "}
                                            </h2>
                                            <p>
                                                Here summary
                                            </p>
                                        </div> */}
                                    </TabPanel>

                                    <TabPanel
                                        value="2"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h2>Professional Summary
                                                <CreateRounded
                                                    id="editIcon"
                                                    onClick={() =>
                                                        setOpenEditProfessionalSummaryModal(true)
                                                    }
                                                />{" "} </h2>
                                        </div>

                                        <div>{ProfessionalSummary ? (
                                            <p>{ProfessionalSummary}</p>
                                        ) : (
                                            <p>
                                                No profile summary..
                                            </p>
                                        )}</div>
                                    </TabPanel>

                                    {/* <TabPanel
                                        value="3"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h2>Experience Details </h2>
                                        </div>

                                        <div>No Experience Found</div>
                                    </TabPanel> */}
                                </TabContext>
                            </Box>
                        </TabPanel>

                        <TabPanel value="2" className="tabPanel" id="tabPanelOne">
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    bgcolor: "background.paper",
                                    display: "flex",
                                }}
                            >
                                <TabContext value={SettingsSection}>
                                    <TabList
                                        onChange={handleChangeSettingSection}
                                        orientation="vertical"
                                        sx={{
                                            borderRight: 1,
                                            borderColor: "divider",
                                            width: "16%",
                                        }}
                                    >
                                        <Tab label="Change Password" value="1" />
                                    </TabList>

                                    <TabPanel
                                        value="1"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                    // className="BasicDetails"
                                    >

                                        <div
                                            style={{
                                                padding: '10px'
                                            }}
                                        >
                                            <Typography>
                                                <H2
                                                    style={{
                                                        color: "#1976D2",
                                                        fontWeight: "500",
                                                        fontSize: "22px",
                                                    }}
                                                >
                                                    Change Password
                                                </H2>



                                                <div  >
                                                    <TextField
                                                        style={{
                                                            width: "40%",
                                                            padding: "0px",
                                                            marginTop: "15px",
                                                        }}

                                                        type={ShowCurrentPassword ? "text" : "password"}
                                                        label="Current Password"
                                                        value={CurreentPassword}
                                                        onChange={(e) => setCurreentPassword(e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowCurrentPassword(!ShowCurrentPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {ShowCurrentPassword ? (
                                                                            <Visibility />
                                                                        ) : (
                                                                            <VisibilityOff />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                    <div  >
                                                        <TextField
                                                            style={{
                                                                width: "40%",
                                                                padding: "0px",
                                                                marginTop: "15px",
                                                            }}
                                                            type={ShowNewPassword ? "text" : "password"}
                                                            label="New Password"
                                                            value={NewPassword}
                                                            onChange={handleNewPassword}

                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => setShowNewPassword(!ShowNewPassword)}
                                                                            edge="end"
                                                                        >
                                                                            {ShowNewPassword ? (
                                                                                <Visibility />
                                                                            ) : (
                                                                                <VisibilityOff />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />

                                                        {NewPasswordError && (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "12px",
                                                                    margin: '5px 0px'
                                                                }}
                                                            >
                                                                {NewPasswordError}
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div   >
                                                        <TextField
                                                            style={{
                                                                width: "40%",
                                                                padding: "0px",
                                                                marginTop: "15px",
                                                            }}
                                                            type={ShowRetypePassword ? "text" : "password"}
                                                            label="Retype New Password"
                                                            value={RetypeNewPassword}
                                                            onChange={handleRetypePassword}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => setShowRetypePassword(!ShowRetypePassword)}
                                                                            edge="end"
                                                                        >
                                                                            {ShowRetypePassword ? (
                                                                                <Visibility />
                                                                            ) : (
                                                                                <VisibilityOff />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {RetypeNewPasswordError && (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "12px",
                                                                    margin: '5px 0px'
                                                                }}
                                                            >
                                                                {RetypeNewPasswordError}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                <br />
                                                <Button
                                                    style={{
                                                        width: "40%",
                                                        padding: "0px",
                                                        height: '40px',
                                                        backgroundColor: "#1976D2",
                                                        color: "#ffffff",
                                                    }}
                                                // onClick={handleApi}
                                                >
                                                    Change Password
                                                </Button>
                                            </Typography>
                                        </div>

                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>

            {/* Modal for creating the basic details or part in Personal Information tab  */}
            <Modal open={OpenCreateProfileModal}
            // onClose={() => {
            //   setOpenCreateProfileModal(false);
            // }}
            >
                <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
                    <div className="ModalInputs">
                        <H2
                            style={{
                                fontSize: "20px",
                                width: "100%",
                                fontWeight: "400",
                                margin: "10px 0px",
                            }}
                        >
                            Complete your basic profile details.
                        </H2>
                        <CloseIcon
                            onClick={() => {
                                navigate("/homePage");
                                setOpenCreateProfileModal(false);
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: "15px",
                                color: "black",
                                fontSize: "35px",
                            }}
                        />

                        <p style={{ color: "grey" }}>
                            {" "}
                            Here are the essential details that will enable the company to
                            connect with you and understand more about you.
                        </p>

                        <div id="Type1">

                            <h3>Name <span style={{ color: 'red' }}>*</span> </h3>
                            <p>Please enter your full name here.</p>
                            <input
                                type="text"
                                tabIndex={1}
                                placeholder="Edit your name"
                                value={ProfileFullName}
                                // onChange={(e) => setProfileFullName(e.target.value)}
                                onChange={handleProfileName}
                            // disabled
                            // onChange={(e) => setProfileFullName(e.target.value)}
                            />
                            {
                                FullNameError && (
                                    <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }}>{FullNameError}</p>
                                )}
                        </div>

                        <div id="Type1">

                            <h3>Gender <span style={{ color: 'red' }}>*</span></h3>
                            <p>Please select your gender.</p>


                            <div className="SelectElement" >
                                <select value={ProfileGender}
                                    tabIndex="2"
                                    // onChange={(e) => setProfileGender(e.target.value)}
                                    onChange={handleProfileGender}
                                    style={{ width: "100%", height: "42px", color: "gray" }}
                                >
                                    <option value="0" disabled> -- Select Gender --</option>
                                    <option value="Male"> Male </option>
                                    <option value="Female"> Female </option>
                                    <option value="Other"> Other </option>
                                </select>
                                {
                                    ProfileGenderError && (
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }}>{ProfileGenderError}</p>
                                    )}
                            </div>
                        </div>

                        <div id="Type1">
                            <h3>Date of Birth <span style={{ color: 'red' }}>*</span> </h3>
                            <p style={{ marginBottom: "0px" }}>
                                Please select your Date of Birth.
                            </p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={["DatePicker", "DatePicker"]}
                                    style={{ border: "3px blue solid" }}
                                    tabIndex={3}
                                >
                                    <DatePicker
                                        value={dayjs(DOB)}
                                        onChange={(DOB) => setDOB(DOB)}
                                        disableFuture={true}
                                    // onChange={handleChangeDOB}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {/* {
                DOBError && (
                  <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }}>{DOBError}</p>
                )} */}
                        </div>

                        {/* <div id="Type1">
              <h3>Languages </h3>
              <p>Please provide the languages you can communicate in.</p>
              <input
                type="text"
                placeholder="Enter the languages"
                value={ProfileLanguages}
                onChange={(e) => setProfileLanguages(e.target.value)}
              />
            </div> */}

                        <div id="Type1" style={{ margin: "0px" }}>
                            <h3>Current Address <span style={{ color: 'red' }}>*</span> </h3>

                            <div id="Type1">
                                <p>Please enter your Current Address.</p>
                                <input
                                    type="text"
                                    placeholder="Enter your address"
                                    value={ProfileCurrentAddress}
                                    // onChange={(e) => setProfileCurrentAddress(e.target.value)}
                                    onChange={handleCurrentAddress}
                                    tabIndex={"4"}
                                />
                                {ProfileCurrentAddressError &&
                                    <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileCurrentAddressError} </p>
                                }
                            </div>
                            <br />

                            <>
                                <p>Please select your country </p>

                                <div className="SelectElement" >
                                    <select value={ProfileCountryName}
                                        tabIndex="5"
                                        // onChange={(e) => setProfileCountryName(e.target.value)}
                                        onChange={handleChangeCountry}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your Country-- </option>
                                        {updatedCountries.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>

                                    {ProfieleCountryError &&
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfieleCountryError} </p>
                                    }
                                </div>
                            </>
                            <br />
                            <>
                                <p>Please select your state </p>

                                <div className="SelectElement" >
                                    <select value={ProfileStateName}
                                        tabIndex="6"
                                        // onChange={(e) => setProfileStateName(e.target.value)}
                                        onChange={handleChangeState}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your State-- </option>
                                        {AllStates.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>

                                    {ProfileStateError &&
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileStateError} </p>
                                    }
                                </div>
                            </>
                            <br />
                            <>
                                <p>Please select your city </p>

                                <div className="SelectElement" >
                                    <select value={ProfileCityName}
                                        tabIndex="7"
                                        // onChange={(e) => setProfileCityName(e.target.value)}
                                        onChange={handleChangeCity}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your City-- </option>
                                        {AllCities.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>

                                    {ProfileCityError &&
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileCityError} </p>
                                    }
                                </div>
                            </>
                        </div>

                        <div id="Type2">
                            <input
                                type="checkbox"
                                id="CurrentAddress"
                                value={SameAsPermanentAddress}
                                checked={SameAsPermanentAddress}
                                onChange={handleCheckboxChange}
                            // onChange={() => setSameAsPermanentAddress(!SameAsPermanentAddress)}
                            />
                            <label htmlFor="CurrentAddress">
                                <h3> Keep Permanent Address same as Current Address </h3>
                            </label>
                        </div>

                        {
                            !SameAsPermanentAddress &&

                            <div id="Type1">
                                <h3>Permanent Address <span style={{ color: 'red' }}>*</span></h3>
                                <div id="Type1">
                                    <p>Please enter your permanent residence address.</p>

                                    <input
                                        type="text"
                                        placeholder="Enter your address"
                                        value={ProfileAddress}
                                        // onChange={(e) => setProfileAddress(e.target.value)}
                                        onChange={handleChangeProfileAddress}

                                        tabIndex={"4"}
                                    />
                                    {ProfileAddressError &&
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileAddressError} </p>
                                    }
                                </div>
                                <br />

                                <>
                                    <p>Please select your country </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentCountry}
                                            tabIndex="5"
                                            // onChange={(e) => setPermanentCountry(e.target.value)}
                                            onChange={handlePermanentCountry}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your Country-- </option>
                                            {PermanentCountries.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>

                                        {PermanentCountryError &&
                                            <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{PermanentCountryError} </p>
                                        }
                                    </div>
                                </>
                                <br />
                                <>
                                    <p>Please select your state </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentState}
                                            tabIndex="6"
                                            // onChange={(e) => setPermanentState(e.target.value)}
                                            onChange={handlePermanentState}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your State-- </option>
                                            {AllPermanentStates.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>

                                        {PermanentStateError &&
                                            <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{PermanentStateError} </p>
                                        }
                                    </div>
                                </>
                                <br />
                                <>
                                    <p>Please select your city </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentCity}
                                            tabIndex="7"
                                            // onChange={(e) => setPermanentCity(e.target.value)}
                                            onChange={hanldePermanentCity}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your City-- </option>
                                            {AllPermanentCities.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>

                                        {PermanentCityError &&
                                            <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{PermanentCityError} </p>
                                        }
                                    </div>
                                </>

                            </div>

                        }

                        <div id="Type1">
                            <h3>Marital Status <span style={{ color: 'red' }}>*</span> </h3>
                            <p>Please select your Marital Status.</p>

                            <div className="SelectElement" >
                                <select value={ProfileMaritalStatus}
                                    tabIndex="8"
                                    // onChange={(e) => setProfileMaritalStatus(e.target.value)}
                                    onChange={handleChangeMaritalStatus}
                                    style={{ width: "100%", height: "42px", color: "gray" }}
                                >
                                    <option value="0" disabled> --Select Status-- </option>

                                    <option value="Single"> Single </option>
                                    <option value="Married"> Married </option>
                                </select>
                                {
                                    ProfileMaritalError && (
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }}>{ProfileMaritalError}</p>
                                    )}
                            </div>
                        </div>

                        <div>
                            <Button
                                onClick={CreateProfileDetails}
                                className="Button_1"
                                variant="contained"
                                style={{ marginRight: "12px" }}
                                tabIndex={"9"}
                            >
                                Next
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/homePage");
                                    setOpenCreateProfileModal(false);
                                    // window.location.href = "/homePage";
                                }}
                                // onClick={handlehomepageNavigate}
                                className="Button_2"
                                variant="outlined"
                                color="inherit"
                                tabIndex={"10"}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Modal for opening and editing the basic details part in Personal Information tab  */}
            <Modal open={OpenEditBasicDetailsModal}>
                <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
                    <div className="ModalInputs">
                        <H2
                            style={{
                                fontSize: "20px",
                                width: "100%",
                                fontWeight: "400",
                                margin: "10px 0px",
                            }}
                        >
                            Basic Details
                        </H2>

                        <CloseIcon
                            onClick={() => {
                                setOpenEditBasicDetailsModal(false)
                                setFullNameError('')
                                ViewProfileBasicDetails();
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: "15px",
                                color: "black",
                                fontSize: "35px",
                            }}
                        />

                        <p style={{ color: "grey" }}>
                            {" "}
                            Here are the essential details that will enable the company to
                            connect with you and understand more about you.
                        </p>

                        <div id="Type1">
                            <h3>Name <span style={{ color: 'red' }}>*</span> </h3>
                            <p>Please enter your full name here.</p>
                            <input
                                type="text"
                                placeholder="Edit your name"
                                value={ProfileFullName}
                                tabIndex={"1"}
                                // onChange={(e) => setProfileFullName(e.target.value)}
                                onChange={handleProfileName}
                            />
                            {
                                FullNameError && (
                                    <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }}>{FullNameError}</p>
                                )}
                        </div>

                        <div id="Type1">
                            <h3>Email Address</h3>
                            <div style={{ display: "flex", padding: "0px" }}>
                                <ContactMailRounded
                                    style={{ color: "#232a58", marginRight: "8px" }}
                                />
                                <h2>{EmailId}</h2>
                            </div>

                            {/* <Accordion style={{ boxShadow: "0px 0px 0px white" }}>
                <AccordionSummary id="panel1-header">
                  <p>
                    You will be contacted via this email address
                    <span>Change Email Id.</span>
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Enter ID to get OTP </h3>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Get OTP
                    </Button>
                  </div>

                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "0px",
                    }}
                  >
                    <h3>Verify OTP recieved</h3>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Submit
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
                        </div>

                        <div id="Type1">
                            <h3>Gender <span style={{ color: 'red' }}>*</span> </h3>
                            <p>Please select your gender.</p>


                            <div className="SelectElement" >
                                <select value={ProfileGender}
                                    tabIndex="2"
                                    onChange={(e) => setProfileGender(e.target.value)}
                                    style={{ width: "100%", height: "42px", color: "gray" }}
                                >
                                    <option value="0" disabled> -- Select Gender --</option>
                                    <option value="Male"> Male </option>
                                    <option value="Female"> Female </option>
                                    <option value="Other"> Other </option>
                                </select>

                            </div>
                        </div>

                        <div id="Type1">
                            <h3>Date of Birth <span style={{ color: 'red' }}>*</span> </h3>
                            <p style={{ marginBottom: "0px" }}>
                                Please select your Date of Birth.
                            </p>




                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={["DatePicker", "DatePicker"]}
                                    style={{ border: "3px blue solid" }}
                                    tabIndex={3}
                                >
                                    <DatePicker
                                        value={dayjs(DOB)}
                                        onChange={(newDOB) => setDOB(newDOB)}
                                        disableFuture={true}
                                        tabIndex="3"
                                        style={{ border: '2px solid red' }}

                                    />
                                </DemoContainer>
                            </LocalizationProvider>


                        </div>

                        {/* <div id="Type1">
              <h3>Languages </h3>
              <p>Please provide the languages you can communicate in.</p>
              <input
                type="text"
                placeholder="Enter the languages ex. Hindi "
                value={ProfileLanguages}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div>
                {languages.map((language, index) => (
                  <Chip
                    key={index}
                    label={language}
                    onDelete={handleDelete(language)}
                    sx={{ margin: "2px" }}
                  />
                ))}
              </div>
            </div> */}

                        <div id="Type1">
                            <h3>Mobile Number </h3>
                            <div style={{ display: "flex" }}>
                                <Call style={{ color: "#232a58", marginRight: "6px" }} />{" "}
                                <h2>{MobileNumber}</h2>
                            </div>

                            {/* <Accordion style={{ boxShadow: "0px 0px 0px white" }}>
                <AccordionSummary id="panel1-header">
                  <p>
                    You will be contacted via this mobile number
                    <span>Change Mobile Number.</span>
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Enter number to get OTP </h3>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      style={{ width: "60%" }}

                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Get OTP
                    </Button>
                  </div>

                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Verify OTP recieved</h3>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Submit
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
                        </div>





                        <div id="Type1" style={{ margin: "0px" }}>
                            <h3>Current Address <span style={{ color: 'red' }}>*</span> </h3>

                            <div id="Type1">
                                <p>Please enter your Current Address.</p>
                                <input
                                    type="text"
                                    placeholder="Enter your address"
                                    value={ProfileCurrentAddress}
                                    // onChange={(e) => setProfileCurrentAddress(e.target.value)}
                                    onChange={handleCurrentAddress}
                                    tabIndex={"4"}
                                />
                                {ProfileCurrentAddressError &&
                                    <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileCurrentAddressError} </p>
                                }
                            </div>
                            <br />

                            <>
                                <p>Please select your country </p>

                                <div className="SelectElement" >
                                    <select value={ProfileCountryName}
                                        tabIndex="5"
                                        // onChange={(e) => setProfileCountryName(e.target.value)}
                                        onChange={handleChangeCountry}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your Country-- </option>
                                        {updatedCountries.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>


                                </div>
                            </>
                            <br />
                            <>
                                <p>Please select your state </p>

                                <div className="SelectElement" >
                                    <select value={ProfileStateName}
                                        tabIndex="6"
                                        // onChange={(e) => setProfileStateName(e.target.value)}
                                        onChange={handleChangeState}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your State-- </option>
                                        {AllStates.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>


                                </div>
                            </>
                            <br />
                            <>
                                <p>Please select your city </p>

                                <div className="SelectElement" >
                                    <select value={ProfileCityName}
                                        tabIndex="7"
                                        // onChange={(e) => setProfileCityName(e.target.value)}
                                        onChange={handleChangeCity}
                                        style={{ width: "100%", height: "42px", color: "gray" }}
                                    >
                                        <option value="0" disabled> --Select Your State-- </option>
                                        {AllCities.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}  </option>))}
                                        <option value="Other"> Other </option>
                                    </select>


                                </div>
                            </>
                        </div>

                        <div id="Type2">
                            <input
                                type="checkbox"
                                id="CurrentAddress"
                                value={SameAsPermanentAddress}
                                checked={SameAsPermanentAddress}
                                onChange={handleCheckboxChange}
                            // onChange={() => setSameAsPermanentAddress(!SameAsPermanentAddress)}
                            />
                            <label htmlFor="CurrentAddress">
                                <h3> Keep Permanent Address same as Current Address </h3>
                            </label>
                        </div>

                        {
                            !SameAsPermanentAddress &&

                            <div id="Type1">
                                <h3>Permanent Address <span style={{ color: 'red' }}>*</span></h3>
                                <div id="Type1">
                                    <p>Please enter your permanent residence address.</p>

                                    <input
                                        type="text"
                                        placeholder="Enter your address"
                                        value={ProfileAddress}
                                        // onChange={(e) => setProfileAddress(e.target.value)}
                                        onChange={handleChangeProfileAddress}

                                        tabIndex={"4"}
                                    />
                                    {ProfileAddressError &&
                                        <p style={{ color: "red", fontSize: "13px", marginTop: '8px' }} >{ProfileAddressError} </p>
                                    }
                                </div>
                                <br />

                                <>
                                    <p>Please select your country </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentCountry}
                                            tabIndex="5"
                                            // onChange={(e) => setPermanentCountry(e.target.value)}
                                            onChange={handlePermanentCountry}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your Country-- </option>
                                            {PermanentCountries.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>


                                    </div>
                                </>
                                <br />
                                <>
                                    <p>Please select your state </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentState}
                                            tabIndex="6"
                                            // onChange={(e) => setPermanentState(e.target.value)}
                                            onChange={handlePermanentState}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your State-- </option>
                                            {AllPermanentStates.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>


                                    </div>
                                </>
                                <br />
                                <>
                                    <p>Please select your city </p>

                                    <div className="SelectElement" >
                                        <select value={PermanentCity}
                                            tabIndex="7"
                                            // onChange={(e) => setPermanentCity(e.target.value)}
                                            onChange={hanldePermanentCity}
                                            style={{ width: "100%", height: "42px", color: "gray" }}
                                        >
                                            <option value="0" disabled> --Select Your State-- </option>
                                            {AllPermanentCities.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}  </option>))}
                                            <option value="Other"> Other </option>
                                        </select>


                                    </div>
                                </>

                            </div>

                        }

                        <div id="Type1">
                            <h3>Marital Status <span style={{ color: 'red' }}>*</span></h3>
                            <p>Please select your Marital Status.</p>

                            <div className="SelectElement" >
                                <select value={ProfileMaritalStatus}
                                    tabIndex="8"
                                    onChange={(e) => setProfileMaritalStatus(e.target.value)}
                                    style={{ width: "100%", height: "42px", color: "gray" }}
                                >
                                    <option value="0" disabled> --Select Status-- </option>

                                    <option value="Single"> Single </option>
                                    <option value="Married"> Married </option>
                                </select>


                            </div>
                        </div>

                        <div>
                            <Button
                                onClick={EditProfileDetails}
                                className="Button_1"
                                variant="contained"
                                style={{ marginRight: "12px" }}
                                tabIndex={"9"}
                            >
                                Update
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenEditBasicDetailsModal(false);
                                    ViewProfileBasicDetails();
                                    setFullNameError('')
                                    setProfileAddressError('');
                                    setProfileCurrentAddressError('');
                                    // setProfileFullName("");
                                    // setDOB("");
                                    // setProfileGender("");
                                    // setProfileAddress("");
                                    // setProfileCurrentAddress("");
                                    // setProfileCountryName("");
                                    // setProfileStateName("");
                                    // setProfileCityName("");
                                    // setProfileMaritalStatus("");

                                }}
                                className="Button_2"
                                variant="outlined"
                                color="inherit"
                                tabIndex={"10"}

                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>


            {/* Modal for opening and editing the Prefessional Summary part  */}
            <Modal open={OpenEditProfessionalSummaryModal}>
                <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <H2
                            style={{
                                fontSize: "22px",
                                width: "100%",
                                fontWeight: "400",
                                margin: "5px 0px",
                            }}
                        >
                            About Section
                        </H2>

                        <CloseIcon
                            onClick={() => {
                                setOpenEditProfessionalSummaryModal(false)
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: "15px",
                                top: '7',
                                color: "black",
                                fontSize: "35px",
                                // border: '2px solid red'
                            }}
                        />

                        <p style={{ color: 'grey' }} >
                            This is the place to showcase your experience and skills. Write a concise overview to demonstrate your accomplishments. A strong summary can highlight why you are a perfect fit for the job. Use this space to advocate for yourself effectively.
                        </p>
                        <div
                            className="inputInfoBox "
                            style={{ width: "100%", marginBottom: "20px" }}
                        >
                            <OutlinedInput
                                // autoFocus={true}
                                multiline
                                rows={8} // Adjust the number of rows as needed
                                type="text"
                                value={ProfessionalSummary}
                                // onChange={(e) => setProfessionalSummary(e.target.value)}
                                onChange={handleProfessionalSummaryInput}
                                onPaste={handleProfessionalSummaryInput}
                                style={{ width: "100%", marginTop: '20px' }}
                                placeholder="Write here"
                            />
                        </div>

                        <div>
                            <Button
                                onClick={() => {
                                    SaveProfessionalSummary();
                                }}
                                className="Button_1"
                                variant="contained"
                                style={{ marginRight: "12px" }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenEditProfessionalSummaryModal(false);
                                    ViewProfileBasicDetails();
                                }}
                                className="Button_2"
                                variant="outlined"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default EnterpriseOwnerProfilePage;
