import React, { useEffect, useState } from 'react';
import './AllVacancies.css'
import { Box, Button, Chip, Modal, OutlinedInput, Tooltip } from '@mui/material';
import { Bookmark, BookmarkBorderOutlined, BusinessCenterOutlined, BusinessOutlined, HouseRounded, LocationOnOutlined, WorkHistoryRounded, WorkHistory, WorkOutline, WorkspacePremiumOutlined, Create, Delete, History } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bars, Hourglass } from 'react-loader-spinner';
import { H3 } from '../../Typography';

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "13px",
    border: "none",
    p: 3,
    animationName: "none",
    maxHeight: "80vh", // Set a maximum height for the modal
    overflowY: "auto", // Enable vertical scrolling when content overflows
};

const AllVacancies = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const CurrentDate = new Date()

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    const getTruncatedText = (text) => {
        const words = text.split(' ');
        const wordLimit = 20;
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    // API Reponse status states to manage loading
    const [AllDataResponse, setAllDataResponse] = useState("");

    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");  //This is the tenant uuid
    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID"); // This is for taking the company till the profile of the company is not created

    const [AllVacanciesArray, setAllVacanciesArray] = useState([])
    const [CompanyName, setCompanyName] = useState("")
    const [OpenDeleteVacancyModal, setOpenDeleteVacancyModal] = useState(false)

    const ShowAllVacancies = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    setAllDataResponse(result.Status);
                    setAllVacanciesArray(result.vacancies)

                    if (CheckCompanyProfileID == "true") {
                        setCompanyName(result.enterprises[0].company_name)
                    } else {
                        setCompanyName(localStorage.getItem('CompanyName'))
                    }
                }
                else if (result.Status === "0") {
                    setAllVacanciesArray([])
                }
            })
    }

    const NotAllowedCreateVacancy = () => {
        toast.warn("Please complete the company profile to proceed", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
        });
        navigate('/company_profilePage')
    }

    const HandleDeleteVacancyButton = (vacancyId, vacancyName) => {
        setOpenDeleteVacancyModal(true)
        localStorage.setItem("DeleteVacancyId", vacancyId)
        localStorage.setItem("DeleteVacancyName", vacancyName)
    }

    const ConfirmDeleteVacancy = (vacancy_uuid) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": localStorage.getItem("DeleteVacancyId")
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/delete_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    ShowAllVacancies()
                    setOpenDeleteVacancyModal(false)
                    localStorage.removeItem("DeleteVacancyId")

                    {

                    }
                    localStorage.removeItem("DeleteVacancyName")
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    useEffect(() => {
        ShowAllVacancies()
    }, [])

    const HandleViewVacancyPage = (vacancyId, enterpriseId) => {
        localStorage.setItem("VacancyIdForViewVacancyPage", vacancyId)
        localStorage.setItem("EnterpriseIdForViewVacancyPage", enterpriseId)
        navigate('/vacancy_page')
    }

    const handleEditVacancyPage = (vacancy_uuid) => {
        localStorage.setItem("EditVacancyUUID", vacancy_uuid);
        navigate('/edit_vacancy')
    }


    return (
        <>

            <div className='AllVacancies' >
                <div className="section_1" style={{ opacity: AllDataResponse === "1" ? "1" : "0.3" }}  >
                    <div className="box_1">
                        <h2>List of All <span> Vacancies <WorkHistory /> </span> </h2>
                        <p>Below is a comprehensive list of all the vacancies you have created. This section allows you to review, edit, and manage your current job openings efficiently. By clicking on each vacancy, you can see detailed information and make any necessary updates. To expand your opportunities and attract more candidates, simply click on the "Create Vacancy" button to add new job openings. </p>
                    </div>
                    <div className="box_2">
                        {CheckCompanyProfileID == 'false' ? (
                            <Button variant="contained"
                                onClick={NotAllowedCreateVacancy}
                                style={{
                                    fontSize: '18px',
                                    color: 'white',
                                    border: "1px solid #4B64FF",
                                    backgroundColor: '#4B64FF',
                                    border: '0px ',
                                }}

                            >
                                Create Vacancy
                            </Button>
                        ) : (
                            <Button variant="contained"
                                onClick={() => navigate('/create_vacancy')}
                                style={{
                                    fontSize: '18px',
                                    color: 'white',
                                    border: "1px solid #4B64FF",
                                    backgroundColor: '#4B64FF',
                                    border: '0px ',
                                }}

                            >
                                Create Vacancy
                            </Button>
                        )}


                    </div>
                </div>

                {AllDataResponse === "1" ?
                    <div className="section_2">
                        {AllVacanciesArray.length > 0 ?
                            (AllVacanciesArray.map((vacancy, index) => {
                                return <div className="card" key={index} >

                                    <h3> {vacancy.job_title ? vacancy.job_title : "No Title"} {" "} </h3>
                                    <div style={{ display: 'flex', }}>

                                        <h1> {vacancy.job_role ? vacancy.job_role : "No Role Name"} </h1>
                                        <p style={{}}> {vacancy.job_id ?  "-   " + vacancy.job_id : ""} </p>
                                    </div>
                                    <p><WorkOutline id="icon" />{CompanyName} </p>
                                    <p><BusinessCenterOutlined id="icon" />
                                        {vacancy.job_experience ? vacancy.job_experience : 'Add experience'}
                                    </p>
                                    <p><LocationOnOutlined id="icon" />
                                        {vacancy.job_location ? vacancy.job_location : "Add location"}
                                    </p>
                                    <p style={{ color: 'grey', fontSize: 'small' }} ><History id="icon" />
                                        Posted {convertDateFormat(vacancy.created_at)}
                                    </p>
                                    <hr />
                                    <h2>
                                        {vacancy.job_description ? getTruncatedText(vacancy.job_description) : "Add job description ...."}
                                    </h2>

                                    <div id='cardButtons' >
                                        <Button variant="contained"
                                            onClick={(vacancyId) => {
                                                HandleViewVacancyPage(vacancy.vacancy_uuid, vacancy.tenant_uuid)
                                            }}
                                            style={{
                                                fontSize: "13px",
                                                color: "white",
                                                border: "1px solid #176D96",
                                                backgroundColor: "#176D96",
                                                border: "0px",
                                            }}
                                        >
                                            Read More
                                        </Button>

                                        <div>
                                            <Button variant="contained"
                                                style={{
                                                    fontSize: "13px",
                                                    color: "white",
                                                    border: "1px solid #176D96",
                                                    backgroundColor: "#176D96",
                                                    margin: '0px 5px'
                                                }}
                                                onClick={() => handleEditVacancyPage(vacancy.vacancy_uuid)}
                                            >
                                                <Create />
                                            </Button>

                                            <Button variant="outlined"
                                                onClick={(vacancy_uuid) => HandleDeleteVacancyButton(vacancy.vacancy_uuid, vacancy.job_role)}
                                                style={{
                                                    fontSize: '14px',
                                                    color: 'indianred',
                                                    margin: '0px 5px'
                                                }}
                                                color='inherit'
                                            >
                                                <Delete />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            })
                            )
                            :
                            (
                                <div className="card">
                                    <h3> Job Title </h3>
                                    <h1> Role Name </h1>
                                    <p><WorkOutline id="icon" /> {CompanyName}</p>
                                    <p><BusinessCenterOutlined id="icon" /> Experience</p>
                                    <p><LocationOnOutlined id="icon" />  Location</p>
                                    <hr />
                                    <h2>
                                        Curently there are no vacancies , simply click on the "Create Vacancy" button to add new job openings...
                                    </h2>

                                    <div id='cardButtons' >
                                        {/* <Button variant="contained"
                                                onClick={() => navigate('/create_vacancy')}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    border: "1px solid #011015",
                                                    backgroundColor: `#8ecae6`,
                                                    border: '0px ',
                                                }}
                                            >
                                                Create Vacancy
                                            </Button> */}
                                        {CheckCompanyProfileID == 'false' ? (
                                            <Button variant="contained"
                                                onClick={NotAllowedCreateVacancy}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    border: "1px solid #011015",
                                                    backgroundColor: '#8ecae6',
                                                    border: '0px ',
                                                }}

                                            >
                                                Create Vacancy
                                            </Button>
                                        ) : (
                                            <Button variant="contained"
                                                onClick={() => navigate('/create_vacancy')}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    border: "1px solid #011015",
                                                    backgroundColor: '#8ecae6',
                                                    border: '0px ',
                                                }}

                                            >
                                                Create Vacancy
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: 'column',
                            alignItems: "center",
                            height: "200px",
                            width: "90%",
                            margin: '0px auto'
                        }}
                    >
                        <Hourglass visible={true} height="30" width="30" colors={["#306cce", "#72a1ed"]} />
                        <p style={{ padding: '10px 0px ' }}> Loading data !!</p>
                    </div>
                }

            </div >

            <Modal
                open={OpenDeleteVacancyModal}
            >
                <Box sx={ModalStyle2} style={{ width: "50%" }}>

                    <H3
                        style={{
                            fontSize: "20px",
                            width: "100%",
                            fontWeight: "200",
                            marginBottom: '10px',
                            textAlign: 'center'


                        }}
                    >
                        Are you sure you want to delete this {" "} <br />
                        <span style={{ fontWeight: "500", color: '#4B64FF' }}>
                            {localStorage.getItem("DeleteVacancyName")}
                        </span>{" "} vacancy
                        ?
                    </H3>

                    <div style={{ width: 'fit-content', margin: '0px auto' }}>
                        <Button
                            type="submit"
                            onClick={ConfirmDeleteVacancy}
                            style={{
                                color: "indianRed",
                                marginRight: "20px",
                            }}
                            variant="outlined"
                            color="inherit"

                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenDeleteVacancyModal(false);
                            }}
                            className="Button_2"
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default AllVacancies
