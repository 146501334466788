import React, { useEffect, useState } from 'react';
import './TotalEnterprises.css';
import { Delete, History, Send, WorkHistory } from "@mui/icons-material";
import { Box, Button, Checkbox, Icon, IconButton, Menu, Modal, OutlinedInput, styled, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bars, RotatingLines } from "react-loader-spinner";
import { H2, H4 } from "../../Typography";
import SimpleCard from '../../SimpleCard';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { toast } from 'react-toastify';

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  borderRadius: "0px",
  border: "none",
  animationName: "none",
};


const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const TotalEnterprises = () => {
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast"

  const navigate = useNavigate();

  const CurrentDate = new Date();
  const convertDateFormat = (isoDate) => {
    const date = new Date(isoDate);
    const TimeGap = CurrentDate - date;

    const seconds = Math.floor(TimeGap / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const ShowDateFormat = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatToCustomISO = (dateString) => {
    const date = new Date(dateString);

    // Format the date to ISO 8601 with milliseconds
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };

  const DomainUuid = localStorage.getItem('DomainUuid');

  const [EnterpriseList, setEnterpriseList] = useState([])
  const [StartDate, setStartDate] = useState(CurrentDate)
  const [EndDate, setEndDate] = useState(CurrentDate)
  const [OpenLicenseKeyModal, setOpenLicenseKeyModal] = useState(false)
  const [LicenseKey, setLicenseKey] = useState("")

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredEnterprises = EnterpriseList.filter((enterprise) => {
    const query = searchTerm.toLowerCase();
    return (
      enterprise.company_name.toLowerCase().includes(query) ||
      enterprise.full_name.toLowerCase().includes(query) ||
      enterprise.email_id.toLowerCase().includes(query)
    );
  });






  const HandleViewLicenseKey = (enterpriseName, enterpriseUuid, licenseKey, startDate, endDate) => {
    setOpenLicenseKeyModal(true)
    setLicenseKey(licenseKey)
    setStartDate(startDate)
    setEndDate(endDate)
    localStorage.setItem("EnterpriseNameForLicenseKey", enterpriseName)
    localStorage.setItem("EnterpriseUuidForLicenseKey", enterpriseUuid)
  }

  const GenerateLicenseKey = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "tenant_uuid": localStorage.getItem('EnterpriseUuidForLicenseKey')
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_API_URL}/generate_license_key`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success("License Key Generated.", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          setLicenseKey(result.license_key)
        } else {
          toast.error("Error occured, try again.", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return
        }
      })
  }

  const PushLicenseDates = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "tenant_uuid": localStorage.getItem('EnterpriseUuidForLicenseKey'),
      "start_date": formatToCustomISO(StartDate),
      "end_date": formatToCustomISO(EndDate)
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_API_URL}/push_license_date`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success("License Key Updated.", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          setOpenLicenseKeyModal(false)
          // localStorage.setItem("EnterpriseNameForLicenseKey")
          // localStorage.setItem("EnterpriseUuidForLicenseKey")
        } else {
          toast.error("Error occurred, try again. ", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return
        }
      })
  }

  const SendLicenseKey = (enterpriseUuid) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "tenant_uuid": enterpriseUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_API_URL}/send_license_key`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success("License key sent successfully.", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
        } else {
          toast.error("Error occurred ! try again. ", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return
        }
      })
  }

  const DeleteEnterprise = (enterpriseUuid) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "tenant_uuid": enterpriseUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_API_URL}/delete_enterprise`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success("Enterprise deleted successfully. ", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          GetAdminDetails()
        } else {
          toast.error("Error occurred ! try again. ", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return
        }
      })
  }

  const GetAdminDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "domain_uuid": DomainUuid
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_API_URL}/get_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setEnterpriseList(result.account_details)
        }
      })
  }

  useEffect(() => {
    GetAdminDetails()
  }, [OpenLicenseKeyModal])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };


  return (
    <>
      <div className="TotalEnterprises">
        <div className="Profile_left">
          <div className="section_1">
            <h2>
              Total {" "}
              <span>
                {" "}
                Enterprises <WorkHistory />{" "}
              </span>{" "}
            </h2>
            <p>
              {" "}
              This table provides a comprehensive list of all enterprises, along with their respective license details, including the start and end dates of each license period.{" "}
            </p>
          </div>

          <div className="section_2">
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 8px 10px 8px",
                  height: "70px",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "28%",
                    border: "1px solid  lightgrey",
                    paddingLeft: "10px",
                    borderRadius: "5px",
                    outline: "1px black lightgrey",
                  }}
                  name="search"
                  placeholder="Search..."
                  type="search"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                {/* <div
                  style={{
                    width: "120px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Select an action" placement="top-start">
                    <H4
                      // onClick={handleClick}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon style={{ transform: "scale(.8)" }}>
                        format_list_bulleted
                      </Icon>
                      Action
                    </H4>
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    // onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {Permissions.CpUserPerms.CpUserTurnOnCredentials && (
                      <MenuItem
                        onClick={turnOnCredentials}
                        disabled={SelectedUserCredentials.some((credentials) =>
                          CheckCredentials(credentials, true)
                        )}
                      >
                        Turn On Credentials
                      </MenuItem>
                    )}

                    {Permissions.CpUserPerms.CpUserTurnOffCredentials && (
                      <MenuItem
                        onClick={turnOffCredentials}
                        disabled={SelectedUserCredentials.some((credentials) =>
                          CheckCredentials(credentials, false)
                        )}
                      >
                        Turn Off Credentials
                      </MenuItem>
                    )}

                    {Permissions.CpUserPerms.CpUserReadLogout && (
                      <MenuItem
                        onClick={handleLogoutUser}
                        disabled={SelectedUserLogin.some((credentials) =>
                          CheckCredentials(credentials, true)
                        )}
                      >
                        Log Out
                      </MenuItem>
                    )}
                  </Menu>
                </div> */}
              </div>

              <Box width="100%" className="scroll" style={{ overflowX: "scroll", }}>
                <StyledTable
                  style={{ overflow: "scroll" }}
                >
                  <TableHead style={{ width: 'fit-content' }}>
                    <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
                      {/* <TableCell
                        style={{
                          paddingRight: "8px",
                          width: '100px',
                        }}
                        align="center"
                      >
                        <Checkbox
                        indeterminate={
                          selectedTenants.length > 0 &&
                          selectedTenants.length < userDataLength
                        }
                        checked={
                          userDataLength > 0
                            ? selectedTenants.length === userDataLength
                            : false
                        }
                        onChange={handleSelectAll}
                        inputProps={{ "aria-label": "select all tenants" }}
                        />
                      </TableCell> */}

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '150px',
                        }}
                        align="center"
                      >
                        S. NO.
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                          width: '150px',
                        }}
                        align="center"
                      // onClick={() => handleSort("FirstName")}
                      >
                        {" "}
                        Enterprise Name
                        {/* <TableSortLabel
                          active={orderBy === "FirstName"}
                          direction={orderBy === "FirstName" ? order : "asc"}
                        ></TableSortLabel> */}
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '150px',
                        }}
                        align="center"
                      >
                        Owner Name
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '180px',
                        }}
                        align="center"
                      >
                        Created On
                      </TableCell>



                      {/* <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: "200px",
                        }}
                        align="center"
                      >
                        Status
                      </TableCell> */}

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: "140px",
                          maxWidth: "140px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis"

                        }}
                        align="center"
                      >
                        Owner Email ID
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '120px',
                        }}
                        align="center"
                      >
                        License Key
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '120px',
                        }}
                        align="center"
                      >
                        Send Key
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: '100px',
                        }}
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {
                      filteredEnterprises.map((enterprise, index) => {
                        return <TableRow
                          key={index}
                          hover
                        >
                          {/* <TableCell
                            style={{
                              cursor: "pointer",
                            }}
                            align="center"
                          >
                            <Checkbox
                              checked={isSelected(user.UserUUID)}
                              onChange={() =>
                                handleSelectTenant(user.UserUUID)
                              }
                              inputProps={{
                                "aria-label": `select tenant ${user.UserUUID}`,
                              }}
                            />
                          </TableCell> */}

                          <TableCell
                            style={{ cursor: "pointer" }}
                            align="center"
                          >
                            {index + 1} .
                          </TableCell>

                          <TableCell
                            align="center"
                          >
                            {enterprise.company_name}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              width: "160px",
                              maxWidth: "160px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {enterprise.full_name}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              width: "160px",
                              maxWidth: "160px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {ShowDateFormat(enterprise.created_at)}
                          </TableCell>

                          {/* <TableCell align="center">
                            <span
                              style={{
                                fontSize: "12px",
                                padding: "3px 10px",
                                color: enterprise.is_enable == "true" ? "green" : "indianRed",
                                border: `1px solid ${enterprise.is_enable == "true" ? "green" : "indianRed"}`,
                                borderRadius: "15px",
                                marginTop: "4px",
                                width: "140px",
                                maxWidth: "120px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "inline-block"
                              }}

                            >
                              {enterprise.is_enable == "true" ? "Enable" : "Disable"}
                            </span>
                          </TableCell> */}

                          <TableCell
                            align="center"
                            style={{
                              width: "160px",
                              maxWidth: "160px",
                              overflowX: "auto",
                              padding: '0px',
                              margin: '0px',
                            }}
                          >
                            {enterprise.email_id}
                          </TableCell>

                          <TableCell
                            align="center"
                          >
                            <Button
                              className="Button_2"
                              variant="outlined"
                              style={{ margin: "0px 0px", fontSize: '12px', padding: '5px 8px' }}
                              onClick={() => HandleViewLicenseKey(enterprise.company_name, enterprise.tenant_uuid, enterprise.login_key, enterprise.license_start_date, enterprise.license_end_date)}
                            >
                              View
                            </Button>
                          </TableCell>

                          {/* <TableCell
                            align="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <DatePicker
                                  value={dayjs(EndDate)}
                                  onChange={(date) => setEndDate(date)}
                                // disableFuture={true}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </TableCell> */}


                          {/* <TableCell
                        style={{ fontSize: "13px" }}
                        align="center"
                      >
                        <Tooltip
                          title={
                            user.Enabled === "true"
                              ? "Enabled"
                              : "Disabled"
                          }
                        >
                          {user.Enabled === "true" ? (
                            <CheckCircleOutlineIcon color="primary" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </Tooltip>
                        enable
                      </TableCell> */}

                          <TableCell align="center">
                            {
                              enterprise.login_key == "" ?
                                <p
                                  style={{
                                    fontSize: "12px",
                                    padding: "3px 10px",
                                    color: "indianRed",
                                    border: "2px solid indianRed",
                                    borderRadius: "15px",
                                    marginTop: "4px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => HandleViewLicenseKey(enterprise.company_name, enterprise.tenant_uuid, enterprise.login_key)}
                                >
                                  Generate
                                </p> :
                                <Send style={{ color: '#70e000', cursor: 'pointer' }}
                                  onClick={() => {
                                    SendLicenseKey(enterprise.tenant_uuid)
                                  }}
                                />
                            }
                          </TableCell>

                          <TableCell align="center">
                            <Delete style={{ color: 'indianred', cursor: 'pointer' }}
                              onClick={() =>
                                DeleteEnterprise(enterprise.tenant_uuid)}
                            />
                          </TableCell>
                        </TableRow>
                      })
                    }
                  </TableBody>
                </StyledTable>
              </Box>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25,]}
                component="div"
                count={filteredEnterprises.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div >


      {/* Modal for entering the OTP to start assessment */}
      <Modal Modal open={OpenLicenseKeyModal} >
        <Box sx={ModalStyle2} className="TotalEnterpriseLicenseKeyModal">
          <div>
            <H2
              style={{
                fontSize: "25px",
                width: "100%",
                color: '#232A58',
                fontWeight: "400",
                margin: "10px 0px 0px 0px",
              }}
            >
              License Key Details <span style={{ color: '#4B64FF' }} >({localStorage.getItem("EnterpriseNameForLicenseKey")})</span>
            </H2>

            <p style={{ color: "grey" }}>
              {" "}
              These are the license key details.
            </p>
          </div>

          <div id="Type1">
            <h3>License Key </h3>
            <p>Generate a license key for this enterprise to access the portal .</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
              <input
                type="text"
                placeholder="Click to generate"
                value={LicenseKey}
              // onChange={(e) => setAddJobTitle(e.target.value)}
              />

              <Button variant="contained"
                onClick={() => GenerateLicenseKey()}
                style={{
                  fontSize: '14px',
                  color: 'white',
                  border: "1px solid #4B64FF",
                  backgroundColor: '#4B64FF',
                  border: '0px ',
                }}
              >
                {
                  LicenseKey == "" ? "Generate Key" : "Re-Generate Key"
                }
              </Button>
            </div>
          </div>

          <div id="Type2">
            <div>
              <h3>License Start Date : </h3>
              <p>This is the license start date.</p>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DatePicker"]}
              >
                <DatePicker
                  value={dayjs(StartDate)}
                  onChange={(date) => setStartDate(date)}
                // disableFuture={true}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div id="Type2">
            <div>
              <h3>License End Date : </h3>
              <p>This is the license end date.</p>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DatePicker"]}
              >
                <DatePicker
                  value={dayjs(EndDate)}
                  onChange={(date) => setEndDate(date)}
                // disableFuture={true}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }} >
            <Button
              onClick={() => {
                setOpenLicenseKeyModal(false);
              }}
              style={{ color: "indianRed" }}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: '14px',
                color: 'white',
                border: "1px solid #4B64FF",
                backgroundColor: '#4B64FF',
                border: '0px ',
                marginLeft: '10px'
              }}
              onClick={() => PushLicenseDates()}
            >
              Update
            </Button>
          </div>
        </Box>
      </Modal >

    </>
  )
}

export default TotalEnterprises
