import React, { useEffect, useState } from 'react';
import './SaveCandidates.css'
import { History, WorkHistory } from "@mui/icons-material";
import { Box, Button, styled, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Hourglass, } from "react-loader-spinner";
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { toast } from 'react-toastify';




const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
    },
}));



const SavedCandidates = () => {

    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate();
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");


    const AppliedCandidateVacancyId = localStorage.getItem('VacancyId')

    const CurrentDate = new Date();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");



    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? "s" : ""} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
        }
    };

    const [SavedCandidatesList, setSavedCandidatesList] = useState([])
    const [RoleName, setRoleName] = useState("")

    const [AllDataResponse, setAllDataResponse] = useState('')


    const SavedCandidates = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_saved_profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
                setSavedCandidatesList(result.data)

                setAllDataResponse(result.Status)

                // setRoleName(result.data.vacancy.job_role)


            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        SavedCandidates()
    }, [])


    const SaveProfile = (user_uuid) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "user_uuid": user_uuid,
            "vacancy_uuid": AppliedCandidateVacancyId,
            "condition": "false"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/save_profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    SavedCandidates();
                } else if (result.Status === "0") {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                }

            })
            .catch((error) => console.error(error));

    }




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    const filteredEnterprises = SavedCandidatesList.filter((data) => {
        const query = searchTerm.toLowerCase();
        return (
            data.account_details.full_name.toLowerCase().includes(query) ||
            data.account_details.email_id.toLowerCase().includes(query)
            // data.email_id.toLowerCase().includes(query)
        );
    });



    return (
        <>

            <div className="SavedCandidatesList">
                <div className="Profile_left">
                    <div className="section_1">
                        <h2>
                            Total Saved {" "}
                            <span>
                                {" "}
                                Candidates List <WorkHistory />{" "}
                            </span>{" "}
                        </h2>
                        <p>
                            {" "}
                            The Total Saved Candidates List is a separate list of candidates with all potential hires and qualifications. It aids recruiters in tracking candidates, enhancing decision-making, and streamlining the recruitment process for efficient hiring.{" "}
                        </p>
                    </div>

                    <div className="section_2">

                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px 8px 10px 8px",
                                    height: "70px",
                                }}
                            >
                                <input
                                    style={{
                                        height: "40px",
                                        width: "28%",
                                        border: "1px solid  lightgrey",
                                        paddingLeft: "10px",
                                        borderRadius: "5px",
                                        outline: "1px black lightgrey",
                                    }}
                                    name="search"
                                    placeholder="Search..."
                                    type="search"
                                    autoComplete="off"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            <Box width="100%" className="scroll" style={{ overflowX: "scroll", }}>
                                <StyledTable
                                    style={{ overflow: "scroll", }}
                                >
                                    <TableHead style={{ width: 'fit-content', opacity: AllDataResponse === "1" ? "1" : "0.3" }}>

                                        <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>

                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: '150px',
                                                }}
                                                align="center"
                                            >
                                                Saved Status
                                            </TableCell>

                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: '150px',
                                                }}
                                                align="center"
                                            >
                                                Name
                                            </TableCell>

                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    cursor: "pointer",
                                                    width: '150px',
                                                }}
                                                align="center"
                                            >
                                                Email
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: '100px',
                                                }}
                                                align="center"
                                            >
                                                Job Role
                                            </TableCell>

                                            {/* <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: '150px',
                                                }}
                                                align="center"
                                            >
                                                Applied At
                                            </TableCell> */}





                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: "200px",
                                                }}
                                                align="center"
                                            >
                                                Assesment Status
                                            </TableCell>

                                            <TableCell
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    width: "140px",
                                                    maxWidth: "140px",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis"

                                                }}
                                                align="center"
                                            >
                                                Assesment Marks
                                            </TableCell>


                                        </TableRow>
                                    </TableHead>


                                    {AllDataResponse === "1" ? (
                                        <TableBody>
                                            {filteredEnterprises && filteredEnterprises.length > 0 ? (
                                                filteredEnterprises.map((data, index) => (

                                                    <TableRow
                                                        key={index}
                                                        hover
                                                        style={{ cursor: "pointer" }}
                                                    >

                                                        <TableCell
                                                            style={{ cursor: "pointer" }}
                                                            align="center"
                                                        >

                                                            < BookmarkIcon
                                                                style={{ cursor: 'pointer' ,color:'#4B64FF'}}
                                                                onClick={() => SaveProfile(data.account_details.user_uuid)} />
                                                        </TableCell>

                                                        <TableCell
                                                            style={{ cursor: "pointer" }}
                                                            align="center"
                                                        >
                                                            {data.account_details.full_name}
                                                        </TableCell>

                                                        <TableCell
                                                            align="center"
                                                        >
                                                            {data.account_details.email_id}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                        >
                                                            {data.vacancy_details.job_title}
                                                        </TableCell>

                                                        {/* <TableCell
                                                            align="center"
                                                            style={{
                                                                minWidth: "160px",
                                                                overflow: "hidden",
                                                                // whiteSpace: "nowrap",
                                                                // textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            <p style={{ color: 'darkgrey', display: 'flex', alignItems: 'center' }} >
                                                                <History id="icon" /> Applied{" "}
                                                                {convertDateFormat(data.account_details.apply_at)}
                                                            </p>
                                                        </TableCell> */}



                                                        <TableCell align="center">
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                    padding: "3px 10px",
                                                                    color: data.total_marks > 0 ? "green" : "indianRed",
                                                                    border: `1px solid ${data.total_marks > 0 ? "green" : "indianRed"}`,
                                                                    borderRadius: "15px",
                                                                    marginTop: "4px",
                                                                    width: "140px",
                                                                    maxWidth: "120px",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    display: "inline-block"
                                                                }}

                                                            >

                                                                {data.total_marks > 0 ? "Given" : "Not-Given"}

                                                            </span>
                                                        </TableCell>

                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                width: "160px",
                                                                maxWidth: "160px",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {data.total_marks}
                                                        </TableCell>




                                                    </TableRow>
                                                ))
                                            ) : (

                                                <TableRow>
                                                    <TableCell align="center" colSpan={8}>
                                                        No data available
                                                    </TableCell>
                                                </TableRow>

                                            )}

                                        </TableBody>
                                    ) : AllDataResponse === "0" ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100vh",
                                                width: "95vw",
                                                position: "fixed",
                                                top: "10%",
                                                right: "0%",
                                                left: "10%",
                                            }}
                                        >
                                            ERROR .......
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: 'column',
                                                height: "100vh",
                                                width: "95vw",
                                                position: "fixed",
                                                top: "10%",
                                                right: "0%",

                                            }}
                                        >
                                            <Hourglass
                                                height="40"
                                                width="40"
                                                color="#9c88ff"
                                                ariaLabel="bars-loading"
                                                visible={true}
                                            />
                                            <p style={{ padding: '10px 0px ' }}>Loading data !!</p>
                                        </div>
                                    )}



                                </StyledTable>
                            </Box>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredEnterprises.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}


export default SavedCandidates;