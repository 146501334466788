import React, { useEffect, useState } from 'react';
import './EditVacancy.css';
import { Autocomplete, Button, FormControl, makeStyles, OutlinedInput, TextField } from '@mui/material';
import { HouseRounded, LocationOn, WorkHistory, WorkHistoryRounded } from '@mui/icons-material';
import Logo from '../../../Assets/StarteleLogo.png';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';

const EditVacancy = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");  //This is the tenant uuid
    const EditVacancyUUID = localStorage.getItem("EditVacancyUUID") // This is the Vacancy UUID

    // console.log(EditVacancyUUID)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const [JobID, setJobID] = useState('');
    const [JobTitle, setJobTitle] = useState("")
    const [JobExperience, setJobExperience] = useState("")
    const [JobSalary, setJobSalary] = useState("")
    const [JobLocation, setJobLocation] = useState("")
    const [JobOpening, setJobOpening] = useState("")
    const [JobRole, setJobRole] = useState("")
    const [JobDescription, setJobDescription] = useState("")
    const [JobResponsibilities, setJobResponsibilities] = useState("")
    const [JobQualification, setJobQualification] = useState("")
    const [JobSkills, setJobSkills] = useState('')


    // Error messages state start here
    const [JobIDError, setJobIDError] = useState('')
    const [JobTitleError, setJobTitleError] = useState('');
    const [JobRoleNameError, setJobRoleNameError] = useState('');
    const [JobExprienceError, setJobExperienceError] = useState('');
    const [JobSallaryError, setJobSallaryError] = useState('');
    const [JobLocError, setJobLocError] = useState('');
    const [TotalOpeningError, setTotalOpeningError] = useState('');
    const [JobDescriptionError, setJobDescriptionError] = useState('');
    const [JobResponsibilitiesError, setJobResponsibilitiesError] = useState('');
    const [JobQualificationError, setJobQualificationError] = useState('');
    const [JobSkillError, setJobSkillError] = useState('')

    const [SkillsArray, setSkillsArray] = useState([]);

    const [CompanyName, setCompanyName] = useState("")
    const [CompanyType, setCompanyType] = useState("")
    const [CompanyCity, setCompanyCity] = useState("")
    const [CompanyState, setCompanyState] = useState("")

    const [ViewCompanyDetailsLoader, setViewCompanyDetailsLoader] = useState(true)

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setViewCompanyDetailsLoader(false)
                setCompanyName(result.enterprises[0].company_name)
                setCompanyCity(result.enterprises[0].company_city)
                setCompanyState(result.enterprises[0].company_state)
                setCompanyType(result.enterprises[0].company_type)
            })
    }

    useEffect(() => {
        ViewEnterpriseDetails()
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && JobSkills.trim() !== '') {
            setSkillsArray([...SkillsArray, JobSkills.trim()]);
            setJobSkills('');
        }
    };



    const handleDelete = (chipToDelete) => () => {
        setSkillsArray((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleViewParticularVacancy = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": EditVacancyUUID
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_particular_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setJobID(result.vacancies[0].job_id)
                setJobTitle(result.vacancies[0].job_title)
                setJobExperience(result.vacancies[0].job_experience)
                setJobSalary(result.vacancies[0].job_salary)
                setJobLocation(result.vacancies[0].job_location)
                setJobOpening(result.vacancies[0].job_opening)
                setJobRole(result.vacancies[0].job_role)
                setJobDescription(result.vacancies[0].job_description)
                setJobResponsibilities(result.vacancies[0].job_responsibilities)
                setJobQualification(result.vacancies[0].job_qualifications)
                const skills = (JSON.parse(result.vacancies[0].job_skills))
                setSkillsArray(skills)

                // const Job_Skills = JSON.parse(result.vacancies[0].job_skills);

                // setJobSkills(Job_Skills);

            })
            .catch((error) => console.error(error));
    }

    const HandleEditVacancy = () => {


        const Skills = SkillsArray.map((item) => {
            if (typeof item === "object" && item.hasOwnProperty("skill")) {
                return item.skill;
            } else {
                return item;
            }
        });

        if(!JobID){
            setJobIDError('Please enter job ID');
        }else{
            setJobIDError('');
        }

        if (!JobTitle) {
            setJobTitleError('Please enter job title');
        } else {
            setJobTitleError('');
        }
        if (!JobRole) {
            setJobRoleNameError('Please enter job role name');
        } else {
            setJobRoleNameError('')
        }
        if (!JobExperience) {
            setJobExperienceError('Please enter job experience');
        } else {
            setJobExperienceError('');
        }
        if (!JobSalary) {
            setJobSallaryError('Please enter offered salary');
        } else {
            setJobSallaryError('');
        }
        if (!JobLocation) {
            setJobLocError('Please enter job location');
        } else {
            setJobLocError('')
        }
        if (!JobOpening) {
            setTotalOpeningError('Please enter total opening')
        } else {
            setTotalOpeningError('');
        }

        if (!JobDescription) {
            setJobDescriptionError('Please enter job description');
        } else {
            setJobDescriptionError('');
        }

        if (!JobResponsibilities) {
            setJobResponsibilitiesError('Please enter job responsibilities')
        } else {
            setJobResponsibilitiesError('')
        }

        if (!JobQualification) {
            setJobQualificationError('Please enter job qualification')
        } else {
            setJobQualificationError('');
        }
        if (SkillsArray.length == 0) {
            setJobSkillError('Please enter job skills');
        } else {
            setJobSkillError('')
        }

        if (!JobID ||  !JobTitle || !JobExperience || !JobSalary || !JobLocation || !JobOpening || !JobDescription || !JobResponsibilities || !JobQualification || !JobRole || Skills.length == 0) {
            toast.warn("Please fill in all required fields", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": EditVacancyUUID,
            "job_id": JobID,
            "job_title": JobTitle,
            "job_experience": JobExperience,
            "job_salary": JobSalary,
            "job_location": JobLocation,
            "job_opening": JobOpening,
            "job_description": JobDescription,
            "job_responsibilities": JobResponsibilities,
            "job_qualifications": JobQualification,
            "job_skills": Skills,
            "job_role": JobRole
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        // console.log(raw, 'This is raw');

        fetch(`${REACT_APP_BASE_API_URL}/edit_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    navigate('/all_vacancies');
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    useEffect(() => {
        handleViewParticularVacancy()
    }, []);

    // Create Vacancy input fields  validations

    const handleJobID = (e) => {
        const inputValue = e.target.value;

        if(inputValue.trim() === ""){
            setJobIDError('Please enter job ID')
        }else{
            setJobIDError('')
        }
        setJobID(inputValue);
    }

    const handleJobTitle = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "") // Allow alphanumeric and spaces

        if (inputValue.trim() === "") {
            setJobTitleError('Please enter job title');
        } else {
            setJobTitleError('')
        }
        setJobTitle(inputValue)

    }

    const handleJobRole = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobRoleNameError('Please enter job role name');
        } else {
            setJobRoleNameError('')
        }
        setJobRole(inputValue);
    }

    const handleJobExperience = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobExperienceError('Please enter job experience');
        } else {
            setJobExperienceError('')
        }
        setJobExperience(inputValue);
    }

    const handleJobSalary = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobSallaryError('Please enter offered salary');
        } else {
            setJobSallaryError('');
        }

        setJobSalary(inputValue);
    }

    const handleJobLocation = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobLocError('Please enter job location')
        } else {
            setJobLocError('');
        }
        setJobLocation(inputValue);
    }

    const handleJobOpening = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9\s]/g, "")

        if (inputValue.trim() === "") {
            setTotalOpeningError('Please enter total opening')
        } else {
            setTotalOpeningError("")
        }
        setJobOpening(numericValue);
    }

    const handleJobDescription = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[a-zA-Z0-9\s]/g, "");

        if (inputValue.trim() === "") {
            setJobDescriptionError('Please enter job description')
        } else {
            setJobDescriptionError('')
        }

        setJobDescription(inputValue)

    }

    const handlejobResponsibilities = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobResponsibilitiesError('Please enter job responsibilities ')
        } else {
            setJobResponsibilitiesError('')
        }

        setJobResponsibilities(inputValue)
    }

    const hanldeJobQualification = (e) => {
        const inputValue = e.target.value;

        if (inputValue.trim() === "") {
            setJobQualificationError('Please enter job qualification')
        } else {
            setJobQualificationError('');
        }
        setJobQualification(inputValue);
    }

    const handleJobSkills = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length === 0) {
            setJobSkillError('Please enter job skills')
        } else {
            setJobSkillError('')
        }
        setJobSkills(inputValue)
    }



    return (
        <div className='EditVacancy'>
            <div className='Profile_left' >
                <div className="section_1">
                    <h2>Edit <span> Vacancy <WorkHistory /> </span> </h2>
                    <p> Welcome to the Edit Vacancy page. Here, you can update the job by providing essential details such as the job title, description, and requirements. This user-friendly form ensures your listings are thorough and appealing.</p>
                </div>

                <div className='EditVacancyModals' >
                    <h2>
                        Fill in the required details.
                    </h2>

                    <div id='Type1' >
                        <h3>Job ID <span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please enter the required job ID here eg : IRC231812</p>
                        <input
                            type="text"
                            placeholder='Enter job ID here'
                            value={JobID}
                            tabIndex={1}
                            // onChange={(e) => setJobID(e.target.value)}
                            onChange={handleJobID}
                        />
                        {JobIDError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{JobIDError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Title <span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please enter the required job title here eg : Web Developer</p>
                        <input
                            type="text"
                            placeholder='Enter job title here'
                            value={JobTitle}
                            tabIndex={2}
                            // onChange={(e) => setJobTitle(e.target.value)}
                            onChange={handleJobTitle}
                        />
                        {JobTitleError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{JobTitleError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Role Name <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the particular role name for this vacancy eg: Front End Developer</p>
                        <input
                            type="text"
                            placeholder='Enter the role name here'
                            value={JobRole}
                            tabIndex={3}
                            // onChange={(e) => setJobRole(e.target.value)}
                            onChange={handleJobRole}
                        />
                        {JobRoleNameError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobRoleNameError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Experience Required <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the required experience for eg : Freshers , 1-2 Years , 3+ Years</p>
                        <input
                            type="text"
                            placeholder='Enter experience here'
                            value={JobExperience}
                            tabIndex={4}
                            // onChange={(e) => setJobExperience(e.target.value)}
                            onChange={handleJobExperience}
                        />
                        {JobExprienceError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobExprienceError} </p>
                        }
                    </div>


                    <div id='Type1' >
                        <h3>Offered Salary <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the offered salary for eg : 4-5 LPA</p>
                        <input
                            type="text"
                            placeholder='Enter salary amount here'
                            value={JobSalary}
                            tabIndex={5}
                            // onChange={(e) => setJobSalary(e.target.value)}
                            onChange={handleJobSalary}
                        />
                        {JobSallaryError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobSallaryError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Location <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the required Job location for eg : Delhi, Banglore</p>
                        <input
                            type="text"
                            placeholder='Enter location here'
                            value={JobLocation}
                            tabIndex={6}
                            // onChange={(e) => setJobLocation(e.target.value)}
                            onChange={handleJobLocation}
                        />
                        {JobLocError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobLocError} </p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Total Openings <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the total openings for this role for eg: 3, 4</p>
                        <input
                            type="text"
                            placeholder='Enter number of openings here'
                            value={JobOpening}
                            tabIndex={7}
                            // onChange={(e) => setJobOpening(e.target.value)}
                            onChange={handleJobOpening}
                        />
                        {TotalOpeningError &&
                            <p style={{ color: 'red', margin: '5px 0px' }}>{TotalOpeningError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Description <span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please provide a detailed job description for this vacancy.</p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobDescription}
                            tabIndex={8}
                            // onChange={(e) => setJobDescription(e.target.value)}
                            onChange={handleJobDescription}
                            style={{ width: "100%" }}
                            placeholder='Enter decsription here'
                        />
                        {JobDescriptionError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobDescriptionError}</p>
                        }
                    </div>

                    <div id='Type1'>
                        <h3>Job Responsibilities <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please list down the responsibilities involved in this job vacancy for eg:
                            <br />
                            1. Design integrated UI / UX designs using HTML , CSS , Javascript.
                            <br />
                            2. Developing front-end by liaising with the design team.
                        </p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobResponsibilities}
                            tabIndex={9}
                            // onChange={(e) => setJobResponsibilities(e.target.value)}
                            onChange={handlejobResponsibilities}
                            style={{ width: "100%" }}
                            placeholder='Enter decsription here'
                        />
                        {JobResponsibilitiesError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobResponsibilitiesError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Qualification  <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the qualification required for this vacancy for eg: B.Tech / MCA / etc. </p>
                        <input
                            type="text"
                            placeholder='Enter qualification here'
                            value={JobQualification}
                            tabIndex={10}
                            // onChange={(e) => setJobQualification(e.target.value)}
                            onChange={hanldeJobQualification}
                        />
                        {JobQualificationError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobQualificationError}</p>
                        }
                    </div>

                    <div id='Type1' >
                        <h3>Job Skills <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the skills to be required in this vacancy. </p>
                        <input
                            type="text"
                            placeholder='Enter skill name and press enter.'
                            value={JobSkills}
                            tabIndex={11}
                            // onChange={(e) => setJobSkills(e.target.value)}
                            onChange={handleJobSkills}
                            onKeyDown={handleKeyDown}
                        />
                        {JobSkillError &&
                            <p style={{ color: 'red', margin: '5px 0px' }} >{JobSkillError}</p>
                        }
                        <div id='SkillsBox' >
                            {/* <Chip
                                label="Add Skill +"
                            /> */}
                            {SkillsArray.map((data, index) => (
                                <Chip
                                    key={index}
                                    label={data}
                                    onDelete={handleDelete(data)}
                                    style={{ textTransform: 'capitalize' }}
                                />
                            ))}
                        </div>
                    </div>

                    <div>
                        <Button
                            onClick={HandleEditVacancy}
                            className='Button_1'
                            variant="contained"
                            tabIndex={12}
                            style={{ marginRight: '12px' }}
                        >
                            Edit Vacancy
                        </Button>

                        <Button
                            onClick={() => navigate('/all_vacancies')}
                            className='Button_2'
                            variant="outlined"
                            color="inherit"
                            tabIndex={13}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>

            <div className='Profile_right' >
                {ViewCompanyDetailsLoader ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: '300px',
                    }} >
                        <Bars visible={true} height="25" width="30" color="#749BBC" />
                        <p style={{ padding: '10px 0px ' }}> Loading data !!</p>
                    </div>
                    :
                    <div id='one' >
                        <div id="profile">
                            <div id='ProfileImage'>
                                {/* <img src={Logo} /> */}
                                <div className='profile_logo'>
                                    {CompanyName && CompanyName.split(' ').map(name => name.charAt(0)).join('')}
                                </div>

                            </div>
                        </div>
                        <h2>{CompanyName ? CompanyName : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}  > Add Company Name  </span>}</h2>
                        <p>{CompanyType ? CompanyType : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add Company Type  </span>} </p>
                        <p> <LocationOn id="icon" />
                            {CompanyCity ? CompanyCity : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add City  </span>} , {" "}
                            {CompanyState ? CompanyState : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}> Add State  </span>}
                        </p>
                        <Button variant="contained"
                            onClick={() => navigate('/all_vacancies')}
                            style={{
                                fontSize: '13px',
                                width: '80%',
                                color: 'white',
                                border: "1px solid #4B64FF",
                                backgroundColor: '#4B64FF',
                                border: '0px ',
                                marginTop: '10px'
                            }}
                        >
                            View All Vacancies
                        </Button>
                    </div>
                }
            </div>

        </div>
    )
}

export default EditVacancy
