import React, { useEffect } from 'react'
import './AppliedCandidates.css'
import { HouseRounded, LocationOn, WorkHistory, QueryStatsRounded, WorkHistoryRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { Bars, Hourglass } from "react-loader-spinner";


const AppliedCandidates = () => {

    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");

    // State for storing the apply candidate list
    const [JobTitle, setJobTitle] = useState('');
    const [JobRole, setJobRole] = useState('');
    const [AppliedCount, setAppliedCount] = useState('');
    const [AppliedDetailsData, setAppliedDetailsData] = useState([])
    const [AllDataResponse, setAllDataResponse] = useState('')


    const ViewApplliedCandidates = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_applied_vaccancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    // setJobTitle(result.applies_details[0].vacancy.job_title)
                    // setJobRole(result.applies_details[0].vacancy.job_role)
                    // console.log(result.applies_details.apply_count);
                    // console.log(result)
                    setAppliedDetailsData(result.applies_details);
                    setAllDataResponse(result.Status)

                }

            })
            .catch((error) => {
                console.error(error)
            });
    }

    useEffect(() => {
        ViewApplliedCandidates()
    }, [])

    const ShowCandidateslist = (enterPriseId, VacancyId) => {
        localStorage.setItem("VacancyId", VacancyId);
        localStorage.setItem("enterPriseId", enterPriseId)
        navigate('/total_applied_candidates_list')

    }

    return (
        <>
            <div className='AppliedCandidates'>
                <div  className='profile_section'>
                    <div  id="one">
                        <div id="welcome">
                            <h2>Total Applied <span> Candidates <WorkHistory /> </span></h2>
                            <p>The All Applied Candidates List is a detailed record of candidates who have applied for job vacancies. It includes key information such as the candidate’s name, the applied role, vacancy details, application date, and status. This list helps hiring managers and recruiters review applicants, assess their qualifications, and manage the hiring pipeline. It can be sorted by criteria like job role, date, or status, and shows whether candidates have completed their applications, ensuring a streamlined hiring process.</p>
                        </div>
                    </div>

                    <div  id="two">
                        <h2 style={{opacity : AllDataResponse === "1" ? "1" : '0.2' }}> All Vacancies <span><QueryStatsRounded style={{ fontSize: '34px' }} /></span></h2>

                        {AllDataResponse === "1" ? (
                            <div className="cardBox">
                                {AppliedDetailsData && AppliedDetailsData.length > 0 ? (
                                    AppliedDetailsData.map((data, index) => (
                                        <div className="cardBox" key={index}>
                                            <div className="card"
                                                onClick={() => ShowCandidateslist(data.vacancy.tenant_uuid, data.vacancy.vacancy_uuid)}
                                            >
                                                <h3>{data.vacancy.job_title}</h3>
                                                <h2>{data.vacancy.job_role}</h2>
                                                <h1>Total Candidates : {data.apply_count}</h1>
                                                <p>Click to view the list of all applied candidates.</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="cardBox">
                                        <div className="card">
                                            <h2>No Applied Candidates   </h2>
                                            <h1>Total Candidates : 0</h1>
                                            <p>Currently no candidate has applied to this vacancy.</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: 'column',
                                }}
                            >
                                <Hourglass visible={true} height="40" width="40" colors={['#306cce', '#72a1ed']} />
                                <p style={{ padding: '10px 0px ' }}>Loading data !!</p>
                            </div>
                        )}



                    </div>
                </div>
            </div>

        </>
    )
}

export default AppliedCandidates;

