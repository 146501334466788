import React, { useEffect, useState } from "react";
import "./AppliedVacancyPage.css";
import { History, WorkHistory } from "@mui/icons-material";
import { Box, Button, Modal, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bars, RotatingLines } from "react-loader-spinner";
import { H2 } from "../../Typography";
import OtpTimer from "otp-timer";
import SentImg from "../../../Assets/Files sent.gif";
import { toast } from "react-toastify";
import { loader } from "@monaco-editor/react";

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  borderRadius: "0px",
  border: "none",
  animationName: "none",
};

const AppliedVacancyPage = () => {
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast";

  const navigate = useNavigate();

  // useEffect(() => {
  //   // Push a new state to the history stack to prevent going back
  //   window.history.pushState(null, null, window.location.pathname);

  //   // Listen for popstate event which is triggered by back/forward navigation
  //   const handlePopState = () => {
  //     window.history.pushState(null, null, window.location.pathname);
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const CurrentDate = new Date();

  const convertDateFormat = (isoDate) => {
    const date = new Date(isoDate);
    const TimeGap = CurrentDate - date;

    const seconds = Math.floor(TimeGap / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const UserUuid = localStorage.getItem("UserUuid");
  const EmailId = localStorage.getItem("EmailId");
  const VacancyIdForStartAssessment = localStorage.getItem(
    "VacancyIdForStartAssessment"
  );

  const [VacanciesList, setVacanciesList] = useState([]);
  const [AppliedDates, setAppliedDates] = useState([]);

  const [OpenVerifyOtpModal, setOpenVerifyOtpModal] = useState(false);
  const [OpenViewMarksModal, setOpenViewMarksModal] = useState(false);
  const [AssessmentMarks, setAssessmentMarks] = useState({});

  const [AssessmentOtp, setAssessmentOtp] = useState("");

  const [ShowLoader, setShowLoader] = useState(true);
  const [ButtonsLoader, setButtonsLoader] = useState(true);
  const [ViewMarksLoader, setViewMarksLoader] = useState(false);

  const [MarksArray, setMarksArray] = useState([]);

  const RoundsNavigationPage = [""];

  const ViewAppliedVacancy = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_applied_vacancies`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setVacanciesList(result.vacancies);
        setShowLoader(false);
        setAppliedDates(result.apply.map((date) => date.apply_at));
      });
  };

  const HandleViewVacancyPage = (vacancyId, enterpriseId) => {
    localStorage.setItem("VacancyIdForViewVacancyPage", vacancyId);
    localStorage.setItem("EnterpriseIdForViewVacancyPage", enterpriseId);
    navigate("/vacancy_page");
  };

  useEffect(() => {
    ViewAppliedVacancy();
  }, []);

  const HandleSendOtp = (vacancyId, round) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email_id: EmailId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/send_assessment_otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          localStorage.setItem("VacancyIdForStartAssessment", vacancyId);
          setCurrentRound(round);
          setOpenVerifyOtpModal(true);
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
          });
        } else if (result.Status === "0") {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
          });
        }
      });
  };

  const HandleSubmitOtp = (round) => {
    if (!AssessmentOtp) {
      toast.warn("Please enter OTP", {
        position: "top-right",
        toastId: customId,
        autoClose: 4000,
      });
      return;
    }

    if (AssessmentOtp.length < 6 || AssessmentOtp.length > MaxOTPLength) {
      toast.warn("OTP length should be 6 digits", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email_id: EmailId,
      otp: AssessmentOtp,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/verify_assessment_otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
          });
          setOpenVerifyOtpModal(false); // Close modal after success
          if (currentRound === 1) {
            navigate("/extempore_round");
          } else if (currentRound === 2) {
            navigate("/comprehension_round");
          } else if (currentRound === 3) {
            navigate("/quiz_round");
          } else if (currentRound === 4) {
            navigate("/coding_round");
          }
        } else if (result.Status === "0") {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
          });
        }
      });
  };

  // variable for otp length and opt value
  const MaxOTPLength = 6;

  const handleVerifyOTP = (e) => {
    const OTPValue = e.target.value;

    if (OTPValue.length <= MaxOTPLength) {
      setAssessmentOtp(OTPValue);
    }
  };

  const [assessmentData, setAssessmentData] = useState({});
  const [currentRound, setCurrentRound] = useState(null); // New state to track current round

  const ShowVacancyRoundDetails = async (vacancy_uuid) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      vacancy_uuid: vacancy_uuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${REACT_APP_BASE_API_URL}/assessment_result`,
        requestOptions
      );
      const result = await response.json();
      setButtonsLoader(false);
      setAssessmentData((prevData) => ({
        ...prevData,
        [vacancy_uuid]: result.assessment,
      }));
      // setAssessmentData((prevData) => ({
      //   ...prevData,
      //   [vacancy_uuid]: [
      //     {
      //       id: 14,
      //       user_uuid: "b4a770f6-9ab3-482a-b78f-ef0d43e682e0",
      //       vacancy_uuid: "ad7ffc36-463b-4579-a22a-573288efb060",
      //       round_no: "1/3",
      //       round_type: "extempore",
      //       round_status: "true",
      //       round_marks: "30",
      //     },
      //     {
      //       id: 14,
      //       user_uuid: "b4a770f6-9ab3-482a-b78f-ef0d43e682e0",
      //       vacancy_uuid: "ad7ffc36-463b-4579-a22a-573288efb060",
      //       round_no: "2/3",
      //       round_type: "coding",
      //       round_status: "true",
      //       round_marks: "30",
      //     },
      //     {
      //       id: 14,
      //       user_uuid: "b4a770f6-9ab3-482a-b78f-ef0d43e682e0",
      //       vacancy_uuid: "ad7ffc36-463b-4579-a22a-573288efb060",
      //       round_no: "3/3",
      //       round_type: "coding",
      //       round_status: "true",
      //       round_marks: "30",
      //     },
      //     {
      //       id: 14,
      //       user_uuid: "b4a770f6-9ab3-482a-b78f-ef0d43e682e0",
      //       vacancy_uuid: "ad7ffc36-463b-4579-a22a-573288efb060",
      //       round_no: "4/4",
      //       round_type: "coding",
      //       round_status: "true",
      //       round_marks: "30",
      //     },
      //   ],
      // }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (VacanciesList.length > 0) {
      Promise.all(
        VacanciesList.map((vacancy) => {
          // Combine both async functions
          return Promise.all([
            ShowVacancyRoundDetails(vacancy.vacancy_uuid),
            getRounds(vacancy.vacancy_uuid),
          ]);
        })
      );
    }
  }, [VacanciesList]);

  const [roundData, setRoundData] = useState({});

  const getRounds = (vacancy_uuid) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      vacancy_uuid: vacancy_uuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/get_rounds`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRoundData((prevData) => ({
          ...prevData,
          [vacancy_uuid]: result.interview_detail,
        }));
      })
      .catch((error) => console.error(error));
  };

  const isButtonEnabled = (assessmentArray, round) => {
    const currentRoundCompleted = assessmentArray.some(
      (assessment) => assessment.round_no === `${round}/4`
    );

    const previousRoundCompleted = assessmentArray.some(
      (assessment) => assessment.round_no === `${round - 1}/4`
    );

    if (assessmentArray.length === 0 && round === 1) {
      return true;
    }

    if (round === 1) {
      return !currentRoundCompleted;
    } else {
      return previousRoundCompleted && !currentRoundCompleted;
    }
  };

  const isRoundCompleted = (assessmentArray, round) => {
    return assessmentArray.some(
      (assessment) => assessment.round_no === `${round}/4`
    );
  };

  const RoundData = (roundData) => {
    return roundData.some((assessment) => assessment.round_type === "coding");
  };

  const AllRoundDone = (Data, assessments) => {
    return Data.length === assessments.length;
  };

  const [isCodingPresent, setCodingPresent] = useState(false);
  // For showing the marks
  const HandleViewMarksButton = (vacancyId, Data, assessments) => {
    if (Data.some((assessment) => assessment.round_type === "coding")) {
      setCodingPresent(true);
    } else {
      setCodingPresent(false);
    }
    setViewMarksLoader(true);
    setOpenViewMarksModal(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      vacancy_uuid: vacancyId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/generate_report`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAssessmentMarks(result.result[0]);

        setTimeout(() => {
          setViewMarksLoader(false);
        }, 1000);
      });
  };

  // console.log(AssessmentMarks, 'marks');

  return (
    <>
      <div
        className="AppliedVacancyPage"
        // style={{ opacity: loading ? ".3" : "1" }}
      >
        <div className="Profile_left">
          <div className="section_1">
            <h2>
              Applied{" "}
              <span>
                {" "}
                Vacancies <WorkHistory />{" "}
              </span>{" "}
            </h2>
            <p>
              {" "}
              This section displays the vacancies you have applied for. Here,
              you can view the job details of the applied positions, start your
              assessment based on your skills and resume, and ensure you
              understand the job role, responsibilities, qualifications, and
              other essential criteria. <br /> Please review the information
              carefully to confirm you meet the necessary requirements and
              expectations for each position.{" "}
            </p>
          </div>

          {ShowLoader ? (
            <div
              style={{
                width: "100%",
                height: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Bars
                height="40"
                width="40"
                color="#9c88ff"
                ariaLabel="bars-loading"
                visible={true}
              />
            </div>
          ) : VacanciesList.length > 0 ? (
            VacanciesList.map((data, index) => {
              const assessments = assessmentData[data.vacancy_uuid] || [];
              const Data = roundData[data.vacancy_uuid] || [];

              return (
                <div className="section_2" key={index}>
                  <div id="RoundDetails">
                    <h2>{index + 1} .</h2>

                    <div id="name">
                      <h3>{data.job_title}</h3>
                      <p>{data.tenant_name}</p>
                    </div>

                    <p>
                      <History id="icon" /> Applied{" "}
                      {convertDateFormat(`${AppliedDates[index]}`)}
                    </p>

                    <Button
                      className="Button_1"
                      variant="contained"
                      onClick={() =>
                        HandleViewVacancyPage(
                          data.vacancy_uuid,
                          data.tenant_uuid
                        )
                      }
                    >
                      View Job Details
                    </Button>
                  </div>

                  <p
                    style={{
                      textAlign: "center",
                      borderTop: "1px grey solid",
                      padding: "2% 3% 5px 3%",
                      fontSize: "20px",
                      color: "#4B64FF",
                    }}
                  >
                    Interview Round details
                  </p>

                  {ButtonsLoader ? (
                    <div
                      style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RotatingLines
                        visible={true}
                        height="35"
                        width="35"
                        color="white"
                        strokeWidth="5"
                        animationDuration="1"
                        strokeColor="#4B64FF"
                      />
                    </div>
                  ) : (
                    <div id="RoundButtons">
                      <Button
                        className="Button_2"
                        variant="outlined"
                        color="inherit"
                        onClick={() => HandleSendOtp(data.vacancy_uuid, 1)}
                        disabled={!isButtonEnabled(assessments, 1)}
                      >
                        {isRoundCompleted(assessments, 1)
                          ? "Assessment 1 Completed"
                          : "Start Assessment 1"}
                      </Button>

                      <Button
                        className="Button_2"
                        variant="outlined"
                        color="inherit"
                        onClick={() => HandleSendOtp(data.vacancy_uuid, 2)}
                        disabled={!isButtonEnabled(assessments, 2)}
                      >
                        {isRoundCompleted(assessments, 2)
                          ? "Assessment 2 Completed"
                          : "Start Assessment 2"}
                      </Button>

                      <Button
                        className="Button_2"
                        variant="outlined"
                        color="inherit"
                        onClick={() => HandleSendOtp(data.vacancy_uuid, 3)}
                        disabled={!isButtonEnabled(assessments, 3)}
                      >
                        {isRoundCompleted(assessments, 3)
                          ? "Assessment 3 Completed"
                          : "Start Assessment 3"}
                      </Button>

                      {RoundData(Data) && (
                        <Button
                          className="Button_2"
                          variant="outlined"
                          color="inherit"
                          onClick={() => HandleSendOtp(data.vacancy_uuid, 4)}
                          disabled={!isButtonEnabled(assessments, 4)}
                        >
                          {isRoundCompleted(assessments, 4)
                            ? "Assessment 4 Completed"
                            : "Start Assessment 4"}
                        </Button>
                      )}
                      {/* <Button
                        className="Button_2"
                        variant="outlined"
                        color="inherit"
                        onClick={() => HandleSendOtp(data.vacancy_uuid, 4)}
                        disabled={!isButtonEnabled(assessments, 4)}
                      >
                        {isRoundCompleted(assessments, 4)
                          ? "Assessment 4 Completed"
                          : "Start Assessment 4"}
                      </Button> */}
                    </div>
                  )}

                  {AllRoundDone(Data, assessments) && (
                    <div id="CompleteLine">
                      <p>
                        Assessment conducted successfully , you will get
                        informed via your Email Id for further process.
                      </p>

                      <Button
                        variant="contained"
                        style={{
                          fontSize: "13px",
                          color: "white",
                          backgroundColor: "#6aa7c4",
                          cursor: "pointer",
                          width: "170px",
                        }}
                        onClick={(vacancyId) =>
                          HandleViewMarksButton(
                            data.vacancy_uuid,
                            Data,
                            assessments
                          )
                        }
                      >
                        View Marks
                      </Button>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="section_2">
              <h2>S.No. </h2>

              <div id="name">
                <h3>Job Title</h3>
                <p>Company Name</p>
              </div>

              <p>NO APPLIED VACANCY CURRENTLY</p>

              <Button className="Button_1" variant="contained" disabled>
                View Job Details
              </Button>

              <Button
                className="Button_2"
                variant="outlined"
                color="inherit"
                disabled
              >
                Start Assessment
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Modal for entering the OTP to start assessment */}
      <Modal open={OpenVerifyOtpModal}>
        <Box sx={ModalStyle2} className="AppliedVacancyModal">
          <img src={SentImg} style={{ height: "220px", width: "auto" }} />
          <H2
            style={{
              fontSize: "17px",
              width: "100%",
              fontWeight: "300",
              margin: "10px 0px",
            }}
          >
            A Verification Email with OTP has been sent to your <br />
            <span style={{ color: "#232a58", textDecoration: "underline" }}>
              {EmailId}
            </span>
          </H2>

          <div
            className="inputInfoBox "
            style={{ width: "85%", marginBottom: "20px" }}
          >
            <OutlinedInput
              autoFocus={true}
              type="text"
              value={AssessmentOtp}
              // onChange={(e) => setAssessmentOtp(e.target.value)}
              onChange={handleVerifyOTP}
              style={{ width: "100%", height: "42px" }}
              placeholder="Enter the OTP here."
            />
          </div>

          <div>
            <Button
              onClick={() => {
                setOpenVerifyOtpModal(false);
                setAssessmentOtp("");
              }}
              style={{ color: "indianRed" }}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>

            <Button
              className="Button_2"
              variant="outlined"
              style={{ margin: "0px 10px" }}
              onClick={HandleSubmitOtp}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Modal for showing the marks of the assessment */}
      <Modal open={OpenViewMarksModal}>
        <Box sx={ModalStyle2} className="ViewMarksModal">
          <h3 style={{ color: "#4B64FF" }}>Assessment Marks</h3>

          {/* <hr /> */}

          {ViewMarksLoader ? (
            <div
              style={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RotatingLines
                visible={true}
                height="40"
                width="40"
                color="white"
                strokeWidth="5"
                animationDuration="1"
                strokeColor="#4B64FF"
              />
            </div>
          ) : (
            <>
              <div
                className="FinalResult"
                style={{
                  backgroundColor:
                    AssessmentMarks.percentage < 50
                      ? "rgba(200, 13, 13, 0.4)"
                      : "rgba(13, 200, 34, 0.4)",
                }}
              >
                <div id="Box_1">
                  <h4> Final Result </h4>
                  <h3
                    style={{
                      color:
                        AssessmentMarks.percentage < 50 ? "indianred" : "green",
                    }}
                  >
                    {AssessmentMarks.percentage < 50 ? "Fail" : "Qualified"}
                  </h3>
                </div>

                <div id="Box_2">
                  <p> Total Marks : {AssessmentMarks.total_marks} / 400 </p>
                  <p>Final Percentage : {AssessmentMarks.percentage} % </p>
                  {/* <p> You will be further contacted via your Email Id  </p> */}
                </div>
              </div>

              <h1>
                {AssessmentMarks.percentage < 50
                  ? "You are not eligible for the personal interview round , stay connected for the future opportunities ."
                  : "You will be contacted further via your Email Id for personal interview round."}
              </h1>
              <div className="RoundMarks">
                {[
                  {
                    name: "Extempore Round",
                    marks: AssessmentMarks.round1_marks,
                  },
                  {
                    name: "Comprehension Round",
                    marks: AssessmentMarks.round2_marks,
                  },
                  {
                    name: "Quiz Round",
                    marks: AssessmentMarks.round3_marks,
                  },
                  ...(isCodingPresent
                    ? [
                        {
                          name: "Coding Round",
                          marks: AssessmentMarks.round4_marks,
                        },
                      ]
                    : []), 
                ].map((round, index) => (


                  <div key={index} className="Round">
                    <h4>{round.name}</h4>
                    <p>
                      Marks Obtained: <span>{round.marks}</span>
                    </p>
                    <h3
                      style={{
                        color: round.marks < 50 ? "indianred" : "green",
                        border:
                          round.marks < 50
                            ? "2px indianred solid"
                            : "2px green solid",
                      }}
                    >
                      {round.marks < 50 ? "Fail" : "Pass"}
                    </h3>
                  </div>
                ))}
              </div>
            </>
          )}

          <center>
            <Button
              onClick={() => {
                setOpenViewMarksModal(false);
              }}
              style={{ color: "indianRed" }}
              variant="outlined"
              color="inherit"
            >
              Close
            </Button>
          </center>
        </Box>
      </Modal>
    </>
  );
};

export default AppliedVacancyPage;