import React, { useEffect, useState } from "react";
import "./ViewCandidateProfile.css";
import CloseIcon from "@mui/icons-material/Close";
import {
    AccountBalanceWallet,
    Add,
    Boy,
    BoyOutlined,
    BusinessCenter,
    BusinessCenterRounded,
    BusinessOutlined,
    Call,
    CheckBox,
    ContactMailRounded,
    CreateRounded,
    Delete,
    Edit,
    EventAvailable,
    GirlOutlined,
    GpsFixed,
    GradientSharp,
    HorizontalRule,
    HouseRounded,
    LocationOn,
    Mail,
    MailOutline,
    Male,
    Phone,
    TramOutlined,
    WorkHistoryOutlined,
} from "@mui/icons-material";
import { H1, H2, H3 } from "../../Typography";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Autocomplete,
    TextField,
    Tabs,
    Tooltip,
    Chip,
    InputBase,
    outlinedInputClasses,
    Icon,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { saveAs } from "file-saver";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Country, State, City } from "country-state-city";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SimpleCard from "../../../Components/SimpleCard";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Bars, Hourglass } from "react-loader-spinner";


const modalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    border: "0px",
    outline: "0px",
    width: '50%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px'

};

const ViewCandidateProfile = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    // const FullName = localStorage.getItem("FullName");
    const UserUuid = localStorage.getItem('CandidateUserUuidForProfileView');

    // API Reponse status states to manage loading
    const [AllDataResponse, setAllDataResponse] = useState("0");

    const navigate = useNavigate();

    const CurrentDate = new Date();

    const convertDateFormat = (isoDate) => {
        // For converting the date format for DOB
        const date = new Date(isoDate);
        const dateString = date.toDateString().split(" ");
        return `${dateString[1]} ${dateString[2]} , ${dateString[3]}`;
    };

    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1980, currentYear + 3);
    const MonthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [ProfessionalSummary, setProfessionalSummary] = useState("");
    const [
        OpenEditProfessionalSummaryModal,
        setOpenEditProfessionalSummaryModal,
    ] = useState(false);

    const [ProfileSection, setProfileSection] = useState("1");
    const handleProfileChangeTab = (setValue, newValue) => {
        setProfileSection(newValue);
    };

    // Resume Information states and modal opening states
    const [ResumeInfoSection, setResumeInfoSection] = useState("1");
    const [ResumeName, setResumeName] = useState("");
    const [Resumefile, setResumeFile] = useState("");
    const [ShowResumeLoader, setShowResumeLoader] = useState(false)

    const [GetResume_data, setGetResume_data] = useState("");

    const handleResumeInfoChangeTab = (setValue, newValue) => {
        setResumeInfoSection(newValue);
    };

    const handleGetResume = () => {
        const requestOptions = {
            method: "POST",
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/get_resume_data?user_uuid=${UserUuid}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    setGetResume_data(result.resumes.Content);
                    setResumeName(result.resumes.Name);
                    setShowResumeLoader(false)
                }
            })
    };

    const viewResume = async () => {
        try {
            const apiUrl = `${REACT_APP_BASE_API_URL}/get_resume_data?user_uuid=${UserUuid}`;

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.Status === "1") {
                setGetResume_data(data.file_content);

                const base64String = data.file_content;
                if (!base64String) {
                    console.error("Base64 string is empty or undefined.");
                    return;
                }

                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });

                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            } else {
                console.error("Failed to fetch PDF:", data.Msg);
            }
        } catch (error) {
            console.error("Error fetching PDF:", error);
        }
    };

    useEffect(() => {
        handleGetResume();
    }, []);

    const triggerFileInput = () => {
        document.getElementById("resume-input").click();
    };

    // Personal Information States and modal opening states

    const [PersonalInfoSection, setPersonalInfoSection] = useState("1");
    const handleProfileInfoChangeTab = (setValue, newValue) => {
        setPersonalInfoSection(newValue);
    };

    // Profile details states here

    const [ProfileFullName, setProfileFullName] = useState('');
    const [Designation, setDesignation] = useState('');
    const [ProfileGender, setProfileGender] = useState("0");
    const [ProfileEmail, setProfileEmail] = useState("");
    const [DOB, setDOB] = useState(CurrentDate);
    const [ProfileMobileNumber, setProfileMobileNumber] = useState("");
    const [ProfileAddress, setProfileAddress] = useState("");
    const [ProfileCountryName, setProfileCountryName] = useState("0");
    const [ProfileStateName, setProfileStateName] = useState("0");
    const [ProfileCityName, setProfileCityName] = useState("0")
    const [ProfileMaritalStatus, setProfileMaritalStatus] = useState("0");
    const [profileNoticePeriod, setProfileNoticePeriod] = useState("")
    const [ProfileExperience, setProfileExperience] = useState("");
    const [ProfileDesignation, setProfileDesignation] = useState("");
    const [ProfileCurrentAddress, setProfileCurrentAddress] = useState("")


    const [allEducation, setAllEducation] = useState([]);


    //for viewing all educations

    const viewAllEducation = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_education`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAllEducation(result.educations);
            })
            .catch((error) => console.error(error));
    };

    const getEducationRank = (educationType) => {
        switch (educationType) {
            case "10":
                return 1;
            case "12":
                return 2;
            case "Graduation":
            case "Diploma":
                return 3;
            case "Masters":
            case "Post Graduation":
                return 4;
            case "PhD":
                return 5;
            default:
                return 6;
        }
    };

    const sortedEducation = Array.isArray(allEducation)
        ? allEducation.sort((a, b) => getEducationRank(a.education_type) - getEducationRank(b.education_type))
        : [];

    useEffect(() => {
        viewAllEducation();
    }, []);


    const [AddJobTitle, setAddJobTitle] = useState("");
    const [AddCompanyName, setAddCompanyName] = useState("");
    const [AddCompanyLocation, setAddCompanyLocation] = useState("");
    const [AddEmploymentType, setAddEmploymentType] = useState("");
    const [AddIfCurrentJob, setAddIfCurrentJob] = useState(false);
    const [AddJoiningMonth, setAddJoiningMonth] = useState("0");
    const [AddJoiningYear, setAddJoiningYear] = useState("0");
    const [AddEndingMonth, setAddEndingMonth] = useState("0");
    const [AddNoticePeriod, setAddNoticePeriod] = useState("0");
    const [AddEndingYear, setAddEndingYear] = useState("0");
    const [AddJobDescription, setAddJobDescription] = useState("");
    const [AddYourExpSkills, setAddYourExpSkills] = useState([]);
    const [experienceSkills, setExperienceSkills] = useState([]);
    const [AddYourOtherExpSkills, setAddYourOtherExpSkills] = useState("");

    const [allExperience, setAllExperience] = useState([]);

    //For viewing all Experience
    const viewAllExperience = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_experience`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAllExperience(result.experiences);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        viewAllExperience();
    }, []);


    // States for skills and  modal opening states
    const [AddYourSkills, setAddYourSkills] = useState("0");
    const [AddYourOtherSkills, setAddYourOtherSkills] = useState("");

    const [techSkills, setTechSkills] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const [skillSetList, setSkillSetList] = useState([]);
    const [softSkillSetList, setsoftSkillSetList] = useState([]);

    const viewSkillSet = () => {
        const requestOptions = {
            method: "POST",
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/view_all_skill_set`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setSkillSetList(result.all_skill_sets);
            })
            .catch((error) => console.error(error));
    };

    const viewSoftSkillSet = () => {
        const requestOptions = {
            method: "POST",
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_soft_skills`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                setsoftSkillSetList(result.all_skill_sets);
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const [viewTechSkills, setViewTechSkills] = useState([]);
    const [viewSoftSkills, setViewSoftSkills] = useState([]);

    const ViewAllSkills = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_skills`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const techSkills = JSON.parse(result.skillset[0].tech_skills);
                setViewTechSkills(techSkills);
                setTechSkills(techSkills);
                const softskills = JSON.parse(result.skillset[0].soft_skills);
                setViewSoftSkills(softskills);
                setSoftSkills(softskills);
            })
            .catch((error) => console.error(error));
    };


    const ViewProfileBasicDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            tenant_uuid: ""
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setAllDataResponse(result.Status)
                setProfileFullName(result.account[0].full_name);
                // localStorage.setItem("FullName", result.account[0].full_name)
                setProfileGender(result.profiles[0].gender);
                setProfileEmail(result.account[0].email_id);
                setDOB(result.profiles[0].dob);
                setDesignation(result.account[0].designation)
                setProfileExperience(result.account[0].experience)
                // setProfileLanguages(result.profiles[0].language);
                setProfileMobileNumber(result.account[0].mobile_number);
                setProfileAddress(result.profiles[0].permanent_address);
                setProfileCurrentAddress(result.profiles[0].current_address)
                setProfileCountryName(result.profiles[0].current_country);
                setProfileStateName(result.profiles[0].current_state);
                setProfileCityName(result.profiles[0].current_city);
                setProfileMaritalStatus(result.profiles[0].marital_status);
                setProfessionalSummary(result.profiles[0].profile_summary);
                setProfileExperience(result.account[0].experience);
                setProfileDesignation(result.account[0].designation);

                const filteredNoticePeriod = result.notice_period.filter(item => item.notice_period !== "0");
                if (filteredNoticePeriod.length > 0) {
                    setProfileNoticePeriod(filteredNoticePeriod[0].notice_period);
                }
            });
    };


    useEffect(() => {
        ViewProfileBasicDetails();
    }, []);

    useEffect(() => {
        viewSkillSet();
        ViewAllSkills();
        viewSoftSkillSet();
      }, []);


    useEffect(() => {
        // Define the fields and their respective weightages
        const fields = [
            { key: ProfileStateName, weight: 1, type: 'string' },
            { key: ProfessionalSummary, weight: 1, type: 'string' },
            { key: allEducation, weight: 1, type: 'array' },
            { key: viewTechSkills, weight: 1, type: 'array' },
            { key: viewSoftSkills, weight: 1, type: 'array' },
            { key: GetResume_data, weight: 5, type: 'object' } // GetResume_data contributes significantly
        ];

        // Calculate total weightage for all fields
        const totalWeightage = fields.reduce(
            (total, field) => total + field.weight,
            0
        );

        // Calculate completed weightage based on filled fields
        const completedWeightage = fields.reduce((total, field) => {
            if (
                (field.type === 'string' && field.key && field.key.trim() !== '') ||
                (field.type === 'array' && Array.isArray(field.key) && field.key.length > 0) ||
                (field.type === 'object' && field.key && Object.keys(field.key).length > 0)
            ) {
                // Check if field is filled
                return total + field.weight;
            }
            return total;
        }, 0);

        // Calculate profile completion percentage
        let percentage = (completedWeightage / totalWeightage) * 100;

        // Ensure percentage is capped at 100
        percentage = Math.min(percentage, 100);

        localStorage.setItem("ProfileCompletionPercentage", percentage);
    }, [
        ProfileStateName,
        ProfessionalSummary,
        allEducation,
        viewTechSkills,
        viewSoftSkills,
        GetResume_data, // Ensure GetResume_data is included in the dependency array
    ]);

    // Disable scroll when loader on
    useEffect(() => {
        if (AllDataResponse !== "1") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        // enable scrolling when loader off
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [AllDataResponse]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // modal state for view the education
    const [ViewEducationModal, setViewEducationModal] = useState(false);
    const [ViewEducation_type, setViewEducation_type] = useState('');
    const [ViewCollegeName, setViewCollegeName] = useState('');
    const [ViewBoardName, setViewBoardName] = useState('');
    const [ViewSpecialization, setViewSpecialization] = useState('');
    const [ViewStartYear, setViewStartYear] = useState('');
    const [ViewEndYear, setViewEndYear] = useState('');
    const [ViewPercentage, setViewPercentage] = useState('');
    const [EducationDataResponse, setEducationDataResponse] = useState(false);


    // function start here for view the education 

    const ViewEducation = (EducationId) => {
        setViewEducationModal(true);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            education_uuid: EducationId,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/view_particular_education`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // setParticularEducation(result.educations);
                setEducationDataResponse(true)
                setViewEducation_type(result.educations[0].education_type);
                setViewCollegeName(result.educations[0].name_of_institute);
                setViewBoardName(result.educations[0].degree_board);
                setViewSpecialization(result.educations[0].specialization);
                setViewStartYear(result.educations[0].start_year);
                setViewEndYear(result.educations[0].end_year);
                setViewPercentage(result.educations[0].perecentage);
            })
            .catch((error) => console.error(error));

    }


    // Modal for view the experience modal state
    const [ViewExperienceModal, setViewExperienceModal] = useState(false);
    const [ViewJobTitle, setViewJobTitle] = useState('');
    const [ViewCompanyName, setViewCompanyName] = useState('');
    const [ViewCompayLocation, setViewCompanyLocation] = useState('');
    const [ViewEmployementType, setViewEmployementType] = useState('');
    const [ViewJoinMonth, setViewJoinMonth] = useState('');
    const [ViewJoinYear, setViewJoinYear] = useState('');
    const [ViewEndMonth, setViewEndMonth] = useState('');
    const [ViewEndingYear, setViewEndingYear] = useState('');
    const [ViewNoticePeriod, setViewNoticePeriod] = useState('');
    const [ViewSkills, setViewSkills] = useState('');
    const [ViewJobDescription, setViewJobDescription] = useState('')
    const [ViewAddIfCurrentJob, setViewAddIfCurrentJob] = useState(false)
    const [ExperienceDataResponse, setExperienceDataResponse] = useState(false)

    const ViewExperience = (ExperienceId) => {
        setViewExperienceModal(true);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            experience_uuid: ExperienceId,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/view_particular_experience`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setExperienceDataResponse(true);
                setViewJobTitle(result.experiences[0].job_title);
                setViewCompanyName(result.experiences[0].company_name);
                setViewCompanyLocation(result.experiences[0].company_location);
                setViewEmployementType(result.experiences[0].employment_type);
                setViewAddIfCurrentJob(result.experiences[0].currently_working === "true"); //converting to boolean from string
                setViewJoinMonth(result.experiences[0].joining_month);
                setViewJoinYear(result.experiences[0].joining_year);
                setViewEndMonth(result.experiences[0].end_month);
                setViewEndingYear(result.experiences[0].end_year);
                setViewNoticePeriod(result.experiences[0].notice_period)
                setViewJobDescription(result.experiences[0].job_description);
                const skills = JSON.parse(result.experiences[0].skills);

                setViewSkills(skills.join(' , '));

                // setAddYourExpSkills(result.experiences[0].currently_working)
                // setAddYourOtherExpSkills(result.experiences[0].currently_working)
            })
            .catch((error) => console.error(error));
    }


    return (
        <>
            <div style={{ opacity: AllDataResponse === "1" ? "1" : "0.3" }} className="ViewCandidateProfile ">
                <h2>
                    Profile <span> details</span>{" "}
                </h2>

                <div className="profile">
                    <div className="one">
                        <div className="one_image">
                            <div
                                style={{
                                    left: "10px",
                                    color: "#333",
                                    fontSize: "70px",
                                    width: "140px",
                                    height: "140px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: 'uppercase'
                                }}
                            >
                                {ProfileFullName && ProfileFullName.split(' ').map(name => name.charAt(0)).join('')}
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="two">
                        <div id="part_1">
                            <div>
                                <h2>
                                    {" "}
                                    {ProfileFullName}

                                    {ProfileGender === "Male" ? (<BoyOutlined
                                        style={{ fontSize: "inherit", color: "#232a58" }}
                                    />) : (<GirlOutlined
                                        style={{ fontSize: "inherit", color: "pink" }}
                                    />)}

                                </h2>
                                <p>{Designation}</p>
                            </div>
                        </div>
                        <div id="part_2">
                            <ul>
                                <li>
                                    <LocationOn id="icon" />{" "}
                                    <p>
                                        {" "}
                                        {ProfileCityName == "0" ? (
                                            "No Address"
                                        ) : (
                                            <>
                                                {ProfileCityName}
                                            </>
                                        )}
                                    </p>
                                </li>
                                <li>
                                    <BusinessCenter id="icon" />
                                    <p>
                                        <p>
                                            {allExperience?.length > 0 ? "Experience" : ProfileExperience}
                                        </p>
                                    </p>
                                </li>
                                <li>
                                    {profileNoticePeriod ? (
                                        <>
                                            <EventAvailable id="icon" />

                                            <p> {profileNoticePeriod} Month Notice Period </p>
                                        </>
                                    )
                                        :
                                        (
                                            <>
                                                <EventAvailable id="icon" />

                                                <p> 0 Month Notice Period </p>
                                            </>
                                        )


                                    }
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Phone id="icon" />
                                    <p>
                                        <span>
                                            {ProfileMobileNumber ? (
                                                ProfileMobileNumber
                                            ) : (
                                                <span
                                                    style={{ color: "#1976D2", cursor: "pointer" }}
                                                >
                                                    --
                                                </span>
                                            )}{" "}
                                        </span>{" "}
                                    </p>
                                </li>

                                <li>
                                    {" "}
                                    <Mail id="icon" />{" "}
                                    <p>
                                        {" "}
                                        <span>
                                            {ProfileEmail ? (
                                                ProfileEmail
                                            ) : (
                                                <span
                                                    style={{ color: "#1976D2", cursor: "pointer" }}
                                                >
                                                    --
                                                </span>
                                            )}{" "}
                                        </span>{" "}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="three">
                        <h2>
                            Professional Summary
                        </h2>
                        {ProfessionalSummary ? (
                            <p>{ProfessionalSummary}</p>
                        ) : (
                            <p>
                                No Professional Summary ...
                            </p>
                        )}
                    </div>
                </div>

                <div className="ProfileDetails">
                    <TabContext value={ProfileSection}>
                        <Box>
                            <TabList onChange={handleProfileChangeTab} centered>
                                <Tab label="Personal Information" value="1" />
                                <Tab label="Resume" value="3" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    bgcolor: "background.paper",
                                    display: "flex",
                                }}
                            >
                                <TabContext value={PersonalInfoSection}>
                                    <TabList
                                        onChange={handleProfileInfoChangeTab}
                                        orientation="vertical"
                                        sx={{
                                            borderRight: 1,
                                            borderColor: "divider",
                                            width: "16%",
                                        }}
                                    >
                                        <Tab label="Basic Details" value="1" />
                                        <Tab label="Education" value="2" />
                                        <Tab label="Experience" value="3" />
                                        <Tab label="Skills" value="4" />
                                    </TabList>

                                    <TabPanel
                                        value="1"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <h2>
                                            Basic Details{" "}
                                            {" "}
                                        </h2>

                                        <ul>
                                            <li>
                                                <p> Name </p>{" "}
                                                <span>
                                                    {ProfileFullName ? (
                                                        ProfileFullName
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>
                                            <li>
                                                <p> Mobile Number </p>{" "}
                                                <span>
                                                    {ProfileMobileNumber ? (
                                                        ProfileMobileNumber
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>
                                            <li>
                                                <p> Email Id </p>{" "}
                                                <span>
                                                    {ProfileEmail ? (
                                                        ProfileEmail
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p>Permanent Address </p>{" "}
                                                <span>
                                                    {ProfileAddress ? (
                                                        ProfileAddress
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Gender </p>{" "}
                                                <span>
                                                    {" "}
                                                    {ProfileGender !== "0" ? (
                                                        ProfileGender
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Marital Status </p>{" "}
                                                <span>
                                                    {" "}
                                                    {ProfileMaritalStatus !== "0" ? (
                                                        ProfileMaritalStatus
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>
                                            </li>
                                            <li>
                                                <p> Date of Birth </p>
                                                <span>
                                                    {DOB ? (
                                                        convertDateFormat(DOB)
                                                    ) : (
                                                        <span
                                                            style={{ color: "#1976D2", cursor: "pointer" }}
                                                        >
                                                            --
                                                        </span>
                                                    )}{" "}
                                                </span>{" "}
                                            </li>

                                            <li>
                                                <p>Current Address </p>
                                                {ProfileStateName !== "0" &&
                                                    ProfileCountryName !== "0" &&
                                                    ProfileCityName !== "0" ? (
                                                    <>

                                                        <span>
                                                            {ProfileCityName !== "0" ? (
                                                                ProfileCityName
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        color: "#1976D2",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    --
                                                                </span>
                                                            )}{", "}
                                                            {ProfileStateName ? (
                                                                ProfileStateName
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        color: "#1976D2",
                                                                        cursor: "pointer",
                                                                    }}

                                                                >
                                                                    --
                                                                </span>
                                                            )}{", "}

                                                            {ProfileCountryName && ProfileCountryName}

                                                        </span>


                                                    </>
                                                ) : (
                                                    <span
                                                        style={{ color: "#1976D2", cursor: "pointer" }}
                                                    >
                                                        --
                                                    </span>
                                                )}
                                            </li>
                                        </ul>

                                        <div style={{ marginTop: "20px" }}>
                                            <h2>
                                                Professional Summary{" "}
                                            </h2>
                                            <p>
                                                {ProfessionalSummary
                                                    ? ProfessionalSummary
                                                    : <p>
                                                        No professional summary...
                                                    </p>}
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value="2"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h2>Education Details </h2>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "30px",
                                                gap: "20px",
                                            }}
                                        >
                                            {sortedEducation.length > 0 ? (
                                                sortedEducation.map((data) => (
                                                    <div key={data.id}>
                                                        <h2 >
                                                            {data.education_type === "10"
                                                                ? "10th"
                                                                : data.education_type === "12"
                                                                    ? "12th"
                                                                    : data.education_type}
                                                        </h2>

                                                        <p>{data.degree_board}</p>
                                                        <p style={{ color: "#444445" }}>
                                                            {data.name_of_institute}, {data.specialization} ,{" "}
                                                            {data.perecentage}%
                                                        </p>
                                                        <p style={{ color: "grey" }}>
                                                            {data.start_year}-{data.end_year}
                                                        </p>
                                                        <Button onClick={() => ViewEducation(data.education_uuid)} style={{ color: "f4d35e", marginTop:'1%'}}
                                                            variant="outlined"
                                                            color="info"
                                                        >
                                                            View Details</Button>
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No Education Record Found</div>
                                            )}
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        value="3"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h2>Experience Details </h2>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "30px",
                                                gap: "30px",
                                            }}
                                        >
                                            {allExperience ? (
                                                allExperience.map((data) => (
                                                    <div key={data.id}>
                                                        <h2  >
                                                            <BusinessCenterRounded id="editIcon" />{" "}
                                                            {data.job_title}
                                                        </h2>
                                                        <p>{data.company_name}. {" "}
                                                            {data.employment_type == "FullTime"
                                                                ? "Full Time"
                                                                : "Internship"}{" "}
                                                        </p>
                                                        <p style={{ color: "#444445" }}>
                                                            {data.company_location}
                                                        </p>
                                                        <p style={{ color: "#444445" }}>
                                                            {data.joining_month} {data.joining_year} -{" "}
                                                            {data.currently_working == "true"
                                                                ? "Till Date"
                                                                : data.end_month + " " + data.end_year}{" "}

                                                        </p>

                                                        <p style={{ color: "grey" }}>
                                                            {JSON.parse(data.skills).join(", ")}
                                                        </p>
                                                        {
                                                            <p style={{ color: "#444445" }}>
                                                                {data.currently_working == "true" && (data.notice_period + " Month Notice Period")}
                                                            </p>
                                                        }

                                                        <Button onClick={() => ViewExperience(data.experience_uuid)} style={{ color: "f4d35e", marginTop:'1%'}}
                                                            variant="outlined"
                                                            color="info"
                                                        >
                                                            View Details</Button>

                                                    </div>
                                                ))
                                            ) : (
                                                <div>No Experience Found</div>
                                            )}
                                        </div>
                                    </TabPanel>
                                    <TabPanel
                                        value="4"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h2>
                                                Skills
                                                {/* {viewTechSkills.length != "0" &&
                                                    viewSoftSkills.length != "0" && (
                                                    )} */}
                                            </h2>

                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop: "20px   ",
                                            }}
                                        >
                                            {viewTechSkills.length > 0 &&
                                                viewSoftSkills.length > 0 ? (
                                                <>
                                                    <h2>Tech Skills </h2>

                                                    <Box
                                                        mt={3}
                                                        mb={3}
                                                        sx={{
                                                            "& > :not(:last-child)": { marginRight: 1 },
                                                            "& > *": { marginBottom: 1 },
                                                        }}
                                                    >
                                                        {viewTechSkills.map((v) => (
                                                            <Chip key={v} label={v} />
                                                        ))}
                                                    </Box>

                                                    <h2>Soft Skills </h2>

                                                    <Box
                                                        mt={3}
                                                        mb={3}
                                                        sx={{
                                                            "& > :not(:last-child)": { marginRight: 1 },
                                                            "& > *": { marginBottom: 1 },
                                                        }}
                                                    >
                                                        {viewSoftSkills.map((v) => (
                                                            <Chip key={v} label={v} />
                                                        ))}
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        style={{
                                                            fontSize: "13px",
                                                            padding: "3px 10px",
                                                            color: "blue",
                                                            border: "1px solid blue",
                                                            borderRadius: "15px",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            display: "inline-block",
                                                            fontFamily: "inherit",
                                                            width:"30%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "19px",
                                                                verticalAlign: "middle",
                                                            }}
                                                        >

                                                        </span>
                                                        No skills mentioned
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </TabPanel>

                        <TabPanel value="3" className="tabPanel" id="tabPanelOne">
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    bgcolor: "background.paper",
                                    display: "flex",
                                }}
                            >
                                <TabContext value={ResumeInfoSection}>
                                    <TabList
                                        orientation="vertical"
                                        sx={{
                                            borderRight: 1,
                                            borderColor: "divider",
                                            width: "16%",
                                        }}
                                    >
                                        <Tab label="Resume Section" value="1" />
                                    </TabList>

                                    <TabPanel
                                        value="1"
                                        style={{ paddingTop: "0px", width: "100%" }}
                                        className="BasicDetails"
                                    >
                                        <div>
                                            <h2>Resume</h2>
                                            <div className="main_box">
                                                <div className="box_1">
                                                    {
                                                        ResumeName ?
                                                            <p style={{ padding: "10px" }}>
                                                                This is the current resume , click on it it to view.
                                                            </p> :
                                                            <p style={{ padding: "10px" }}>
                                                                N0 resume uploaded
                                                            </p>
                                                    }

                                                    <div>
                                                        {ResumeName ?
                                                            <Button
                                                                className="Button_1"
                                                                variant="outlined"
                                                                style={{
                                                                    marginRight: "12px",
                                                                    display: "block",
                                                                }}
                                                                onClick={viewResume}
                                                            >
                                                                <Tooltip title="View" placement="top">
                                                                    {ResumeName}
                                                                </Tooltip>
                                                            </Button>
                                                            :
                                                            <div style={{ marginRight: '60px' }} >
                                                                No resume available
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>

            {AllDataResponse !== "1" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: "100vh",
                        width: "100vw",
                        position: "fixed",
                        zIndex: '100',
                        top: 0,
                        overflow: 'hidden'
                    }}
                >
                    <Hourglass visible={true} height="40" width="40" colors={['#306cce', '#72a1ed']} />
                    <p style={{ padding: '10px 0px ' }}>Loading data !!</p>
                </div>
            )}

            {/* Modal for view the education details */}
            < Modal className="ViewDetailsModal"
                open={ViewEducationModal}
            // onClose={() => { setViewEducationModal(!ViewEducationModal) }}
            >
                <Box sx={modalStyle2}  >
                    <H2>
                        Education details
                        <CloseIcon style={{ color: 'indianred', fontSize: '30px', cursor: 'pointer' }} onClick={() => { setViewEducationModal(!ViewEducationModal); setEducationDataResponse(false) }} />
                    </H2>

                    {EducationDataResponse ? (
                        <div>
                            <div id="details" >
                                <p>Education :
                                    <span>{ViewEducation_type ? ViewEducation_type : " N/A"}</span>
                                </p>

                                <p> College Name :
                                    <span>{ViewCollegeName ? ViewCollegeName : " N/A"} </span>
                                </p>

                                <p> Board Name :
                                    <span> {ViewBoardName ? ViewBoardName : " N/A"} </span>
                                </p>

                                <p> specialization:
                                    <span>{ViewSpecialization ? ViewSpecialization : " N/A"} </span>
                                </p>

                                <p> Start Year :
                                    <span>{ViewStartYear ? ViewStartYear : " N/A"} </span>
                                </p>

                                <p> End Year:
                                    <span> {ViewEndYear ? ViewEndYear : "N/A"}
                                    </span>
                                </p>
                                <p> Percentage:
                                    <span> {ViewPercentage ? ViewPercentage + "%" : "N/A"}
                                    </span>
                                </p>
                            </div>


                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: '250px'


                            }}
                        >
                            <Bars
                                height="25"
                                width="25"
                                color="#9c88ff"
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                        </div>
                    )}



                </Box>
            </ Modal>

            {/* Modal for view the experience details */}
            < Modal className="ViewDetailsModal"
                open={ViewExperienceModal}
            // onClose={() => { setViewExperienceModal(!ViewExperienceModal) }}
            >
                <Box sx={modalStyle2}  >
                    <H2>
                        Experience details
                        <CloseIcon style={{ color: 'indianred', fontSize: '30px', cursor: 'pointer', }} onClick={() => { setViewExperienceModal(!ViewExperienceModal); }} />
                    </H2>

                    {ExperienceDataResponse ? (
                    <div>
                        <div id="details" >
                            <p>Job Title :
                                <span>{ViewJobTitle ? ViewJobTitle : " N/A"}</span>
                            </p>

                            <p> Company Name :
                                <span> {ViewCompanyName ? ViewCompanyName : " N/A"} </span>
                            </p>

                            <p> Company Location:
                                <span>{ViewCompayLocation ? ViewCompayLocation : " N/A"} </span>
                            </p>



                            <p> Employment Type :
                                <span>{ViewEmployementType ? ViewEmployementType : " N/A"} </span>
                            </p>

                            {ViewAddIfCurrentJob == true ?
                                (
                                    <>
                                        <p> Date of Joining:
                                            <span> {ViewJoinMonth ? ViewJoinMonth : "N/A"}, {" "}  {ViewJoinYear ? ViewJoinYear : "N/A"}
                                            </span>
                                        </p>
                                        <p> Date of Completion:
                                            <span> Present
                                            </span>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p> Date of Joining:
                                            <span> {ViewJoinMonth ? ViewJoinMonth : "N/A"}, {" "}  {ViewJoinYear ? ViewJoinYear : "N/A"}
                                            </span>
                                        </p>
                                        <p> Date of Completion:
                                            <span> {ViewEndMonth ? ViewEndMonth : "N/A"}, {" "} {ViewEndingYear ? ViewEndingYear : "N/A"}
                                            </span>
                                        </p>
                                    </>
                                )
                            }

                            {ViewAddIfCurrentJob &&
                                <p> Notice Period:
                                    <span> {ViewNoticePeriod ? ViewNoticePeriod + " Months" : "N/A"}
                                    </span>
                                </p>
                            }


                            <p> Skills:
                                <span> {ViewSkills ? ViewSkills : "N/A"}
                                </span>
                            </p>
                            <p> Job Description:
                                <span> {ViewJobDescription ? ViewJobDescription : "N/A"}
                                </span>
                            </p>

                        </div>


                    </div>
                    ) : (
                     <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: '250px'


                                    }}
                                >
                                    <Bars
                                        height="25"
                                        width="25"
                                        color="#9c88ff"
                                        ariaLabel="bars-loading"
                                        visible={true}
                                    />
                                </div> 
                    )}



                </Box>
            </ Modal>

        </>

    );
};

export default ViewCandidateProfile;
