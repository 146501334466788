import React, { useState } from 'react'
import './ViewCompanyProfile.css';

import Logo from '../../../Assets/StarteleLogo.png';
import CoverPhoto from '../../../Assets/EnterpriseCoverPhoto.jpg';

import { H1, H2 } from '../../Typography';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import PhoneInput from "react-phone-input-2";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Add, AddRounded, BusinessRounded, Call, ContactMailRounded, CreateRounded, Delete, DeleteOutline, Edit, Email, GpsFixed, Phone } from '@mui/icons-material';
import { Box, Button, FormControl, MenuItem, Modal, OutlinedInput, Select, useMediaQuery } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useFetcher, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { red } from '@mui/material/colors';
import { Hourglass } from 'react-loader-spinner';

const ViewCompanyProfile = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID");
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");
    const EnterpriseIdForViewVacancyPage = localStorage.getItem("EnterpriseIdForViewVacancyPage");

    const CurrentDate = new Date()
    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1950, currentYear);
    const MonthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const [OpenEditEnterpriseModal, setOpenEditEnterpriseModal] = useState(false)

    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            setOpenEditEnterpriseModal(false);
        } else {
            setOpenEditEnterpriseModal(true);
        }
    }, []);

    const [EnterpriseName, setEnterpriseName] = useState(localStorage.getItem('CompanyName'))
    const [EnterpriseTagLine, setEnterpriseTagLine] = useState("")
    const [EnterpriseEstablishmentYear, setEnterpriseEstablishmentYear] = useState("")
    const [EnterpriseCinNumber, setEnterpriseCinNumber] = useState("")
    const [EnterpriseAddress, setEnterpriseAddress] = useState("")
    const [EnterprisePincode, setEnterprisePincode] = useState("")
    const [EnterpriseCity, setEnterpriseCity] = useState("")
    const [EnterpriseState, setEnterpriseState] = useState("")
    const [EnterpriseCountry, setEnterpriseCountry] = useState("")
    const [EnterpriseAboutUs, setEnterpriseAboutUs] = useState("")
    const [EnterpriseType, setEnterpriseType] = useState("")
    const [EnterpriseWebsiteLink, setEnterpriseWebsiteLink] = useState("")
    const [EnterpriseMobileNumbers, setEnterpriseMobileNumbers] = useState("")
    const [EnterpriseEmailId, setEnterpriseEmailId] = useState("")
    const [NumberOfEmployes, setNumberOfEmployes] = useState(localStorage.getItem('NumberOfEmployes'))

    // Data response status state
    const [EnterpriseDataResponse, setEnterpriseDataResponse] = useState('')



    const AddressForGoogleMap = EnterpriseAddress + "," + EnterpriseCity + "," + EnterpriseState + "," + EnterpriseCountry
    const SrcForGoogleMap = `https://maps.google.com/maps?&q="+${AddressForGoogleMap}"&output=embed`;

    // console.log(SrcForGoogleMap, 'src');

    // country state city code start here 
    const [AllStates, setAllStates] = useState([]);
    const [statesMap, setStatesMap] = useState({});
    const [AllCities, setAllCities] = useState([]);

    const countries = Country.getAllCountries();

    const countryMap = countries.reduce((acc, country) => {
        acc[country.name] = country.isoCode;
        return acc;
    }, {});

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        ...country
    }));

    const getStates = (countryIsoCode) => {
        const stateList = State.getStatesOfCountry(countryIsoCode);

        const statesMap = stateList.reduce((acc, state) => {
            acc[state.name] = state.isoCode;
            return acc;
        }, {});

        setStatesMap(statesMap);

        const mappedStates = stateList.map((state) => ({
            label: state.name,
            value: state.name,
            isoCode: state.isoCode,
            ...state
        }));
        setAllStates(mappedStates);

        return mappedStates;
    };

    // Updating the selected state value 
    useEffect(() => {
        if (EnterpriseCountry) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            getStates(countryIsoCode);
        }
    }, [EnterpriseCountry]);

    // Geeting the all cities name based on selected state
    const getCities = (countryIsoCode, stateIsoCode) => {
        const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

        const mappedCities = cityList.map((city) => ({
            label: city.name,
            value: city.name,
            ...city
        }));
        setAllCities(mappedCities);

        return mappedCities;
    };

    // Updating the cities name in the city selectbox  
    useEffect(() => {
        if (EnterpriseState) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            const stateIsoCode = statesMap[EnterpriseState];
            getCities(countryIsoCode, stateIsoCode);
        }
    }, [EnterpriseState, statesMap]);

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseIdForViewVacancyPage
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setEnterpriseDataResponse(result.Status)
                setEnterpriseName(result.enterprises[0].company_name);
                setNumberOfEmployes(result.enterprises[0].employees);
                setEnterpriseTagLine(result.enterprises[0].company_tag_line)
                setEnterpriseEstablishmentYear(result.enterprises[0].year_of_establishment)
                setEnterpriseCinNumber(result.enterprises[0].cin_number)
                setEnterpriseAddress(result.enterprises[0].company_address)
                setEnterprisePincode(result.enterprises[0].company_pincode)
                setEnterpriseCity(result.enterprises[0].company_city)
                setEnterpriseState(result.enterprises[0].company_state)
                setEnterpriseCountry(result.enterprises[0].company_country)
                setEnterpriseAboutUs(result.enterprises[0].company_about)
                setEnterpriseType(result.enterprises[0].company_type)
                setEnterpriseWebsiteLink(result.enterprises[0].company_website)
                setEnterpriseMobileNumbers(result.enterprises[0].company_contact_number)
                setEnterpriseEmailId(result.enterprises[0].company_email_id)
            })
    }

    useEffect(() => {
        ViewEnterpriseDetails()
    }, []);


    return (
        <>
            <div style={{ opacity: EnterpriseDataResponse === "1" ? "1" : "0.3" }} className='ViewCompanyProfile' >
                <div className="section_1">
                    <div className="part1"
                        style={{ backgroundImage: `url(${CoverPhoto})` }}
                    >
                    </div>

                    <div className="part2">
                        <div className="imageBox">
                            <div className='profile_logo'>
                                {EnterpriseName && EnterpriseName.split(' ').map(name => name.charAt(0)).join('')}
                            </div>
                        </div>
                    </div>

                    <div className="part3">
                        <div id="companyName">
                            <h2>
                                {EnterpriseName === "" ? <span id="AddButton"> -- </span> : EnterpriseName}
                            </h2>
                            <p>{EnterpriseTagLine === "" ? <span id="AddButton" ></span> : EnterpriseTagLine}</p>

                            {
                                EnterpriseMobileNumbers !== "" &&
                                <p>
                                    <Phone id="icon" />
                                    {EnterpriseMobileNumbers}
                                </p>
                            }
                        </div>

                        <div id="companyAddress">
                            <a href="mailto:info@startelelogic.com"> <Email id="icon" />{EnterpriseEmailId === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseEmailId}</a>

                            <p> <GpsFixed id="icon" />

                                {EnterpriseAddress !== "" && EnterpriseAddress}, {" "}

                                {EnterpriseCity !== "" && EnterpriseCity} {" "}

                                ({EnterprisePincode !== "" && EnterprisePincode})
                            </p>

                            <p>
                                {EnterpriseState !== "" && EnterpriseState} {" "}

                                ({EnterpriseCountry !== "" && EnterpriseCountry})
                            </p>
                        </div>
                    </div>
                </div>
                <div className="section_2">
                    <div className='part1' >
                        <h3>Company Details  </h3>
                    </div>

                    <div className='part2'>
                        <ul>
                            <li> <h2>Company Name </h2>  <p> {EnterpriseName === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseName} </p></li>

                            <li> <h2>Company's Tag Line </h2> <p> {EnterpriseTagLine === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseTagLine} </p> </li>

                            <li><h2>Company Type  </h2> <p>{EnterpriseType === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseType} </p></li>

                            <li><h2>Employee Size  </h2> <p>{
                                NumberOfEmployes == "" ? <span id="AddButton" >
                                    --
                                </span> : NumberOfEmployes + " Employees"} </p>
                            </li>

                            <li><h2>Contact Numbers  </h2> <p>{
                                EnterpriseMobileNumbers.length == 0 ? <span id="AddButton" >
                                    --
                                </span> : EnterpriseMobileNumbers} </p>
                            </li>

                            <li><h2>Contact Email Id </h2> <p>{EnterpriseEmailId === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseEmailId}</p></li>

                            <div className='part2_1'>
                                <div className='addressList' >
                                    <li> <h2>Company's Address </h2><p> {EnterpriseAddress === "" ? <span id="AddButton" >
                                        --
                                    </span> : EnterpriseAddress}</p> </li>

                                    <li> <h2>City & Pin code </h2><p>  {EnterpriseCity === "" ? <span id="AddButton" >
                                        --
                                    </span> : EnterpriseCity}
                                        , ( {EnterprisePincode === "" ? <span id="AddButton" >
                                            --
                                        </span> : EnterprisePincode} ) </p> </li>

                                    <li> <h2>State & Country </h2><p>  {EnterpriseState === "" ? <span id="AddButton" >
                                        --
                                    </span> : EnterpriseState}
                                        , ( {EnterpriseCountry === "" ? <span id="AddButton" >
                                            --
                                        </span> : EnterpriseCountry} ) </p> </li>
                                </div>

                                <iframe src={SrcForGoogleMap} id='GoogleMap' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <li> <h2>Year Of Establishment </h2> <p> {EnterpriseEstablishmentYear === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseEstablishmentYear} </p> </li>

                            <li><h2>About  Company</h2><p> {EnterpriseAboutUs === "" ? <span id="AddButton" >
                                --
                            </span> : EnterpriseAboutUs} </p></li>

                            <li><h2>Company's Website </h2>
                                {
                                    EnterpriseWebsiteLink === "" ? <p><span id="AddButton" >
                                        Add Website's Link
                                    </span> </p> :
                                        <a href={EnterpriseWebsiteLink} target='_blank' >Click to view the website</a>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div >

            {EnterpriseDataResponse !== "1" &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: "100vh",
                        width: "100vw",
                        position: "fixed",
                        zIndex: '100',
                        top: 0,
                        overflow: 'hidden'
                    }}
                >
                    <Hourglass visible={true} height="40" width="40" colors={['#306cce', '#72a1ed']} />
                    <p style={{ padding: '10px 0px ' }}>Loading data !!</p>
                </div>
            }
        </>
    )
}

export default ViewCompanyProfile
