import React from 'react'
import SeekerProfilePage from './SeekerProfilePage'
import EnterpriseProfilePage from './EnterpriseProfilePage';
import EnterpriseOwnerProfilePage from './EnterpriseOwnerProfilePage';
import AdminProfilePage from './AdminProfilePage';



const ProfilePage = () => {
    const Account_Type = localStorage.getItem("Account_Type");
    // console.log(Account_Type, 'pp');

    return (
        <>
            {
                Account_Type == "Job_Seeker" &&
                <SeekerProfilePage />
            }

            {
                Account_Type == "Enterprise" &&
                <EnterpriseOwnerProfilePage />
            }

            {
                Account_Type == "Admin" &&
                <AdminProfilePage />
            }
        </>
    )
}

export default ProfilePage;
