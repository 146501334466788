import React, { useEffect, useRef, useState } from "react";
import "./QuizRound.css";

import { Bars, ColorRing, DNA, Hourglass } from "react-loader-spinner";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    OutlinedInput,
    Radio,
    RadioGroup,
} from "@mui/material";
import StarteleName from "../../../Assets/StarteleLogo.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { Done, Padding, Stream, WarningAmber } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { toast } from "react-toastify";
import CompletedGif from '../../../Assets/Completed.gif'

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: "none",
};

const QuizRound = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast";

    const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen status
    const assessmentStartedRef = useRef(false);

    const navigate = useNavigate();
    useEffect(() => {
        // Push a new state to the history stack to prevent going back
        window.history.pushState(null, null, window.location.pathname);

        // Listen for popstate event which is triggered by back/forward navigation
        const handlePopState = () => {
            window.history.pushState(null, null, window.location.pathname);
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);

    const UserUuid = localStorage.getItem("UserUuid");
    const VacancyIdForStartAssessment = localStorage.getItem(
        "VacancyIdForStartAssessment"
    );

    const [InstructionsModal, setInstructionsModal] = useState();
    const [CompletedExamModal, setCompletedExamModal] = useState();

    const [ShowMarksModal, setShowMarksModal] = useState(false);
    const [ShowCheatingMarksModal, setShowCheatingMarksModal] = useState(false);

    const [StartTimer, setStartTimer] = useState(false);
    const [TimeLimit, setTimeLimit] = useState("");

    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [roundNo, setRoundNo] = useState("3/4");
    const [roundType, setRoundType] = useState("quiz");
    const [roundId, setRoundId] = useState("");
    // const [questionLimit, setquestionLimit] = useState("");

    // const [MarksObtained, setMarksObtained] = useState(0);
    const MarksObtained = localStorage.getItem("MarksObtained")
    const QuestionCountForShowingMarks = localStorage.getItem("QuestionCountForShowingMarks")

    const [SelectedAnswerIndex, setSelectedAnswerIndex] = useState("");
    const [MarksObtainedLoader, setMarksObtainedLoader] = useState(false);

    const [QuestionArray, setQuestionArray] = useState([]);
    const [QuestionIDsArray, setQuestionIDsArray] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [IndividualMarks, setIndividualMarks] = useState([]);
    const [ShowResultButton, setShowResultButton] = useState(false);
    const [quizSubmitted, setQuizSubmitted] = useState(false);

    const [ShowMainPageLoader, setShowMainPageLoader] = useState(true);

    useEffect(() => {
        const ShowVacancyRoundDetails = async (vacancy_uuid) => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                user_uuid: UserUuid,
                vacancy_uuid: VacancyIdForStartAssessment,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    `${REACT_APP_BASE_API_URL}/assessment_result`,
                    requestOptions
                );
                const apiResponse = await response.json();

                const isRoundPresent = apiResponse.assessment.some(
                    (assessment) => assessment.round_no === "3/4"
                );
                if (isRoundPresent) {
                    setShowMainPageLoader(false)
                    setCompletedExamModal(true);
                } else {
                    if (AssessmentStarted == "true") {
                        setShowMainPageLoader(false);
                        AutoSubmitAnswer();
                        setInstructionsModal(false);
                    } else if (AssessmentStarted == "false") {
                        setShowMainPageLoader(false);
                        setInstructionsModal(true);
                    } else if (AssessmentStarted == "done") {
                        setShowMainPageLoader(false);
                        setInstructionsModal(false);
                        setCompletedExamModal(false);
                        setShowMarksModal(true);
                    }
                }
                // console.log(isRoundPresent);
            } catch (error) {
                console.error(error);
            }
        };
        const AssessmentStarted = localStorage.getItem("AssessmentStarted");
        if (AssessmentStarted == "true") {
            AutoSubmitAnswer();
        }
        ShowVacancyRoundDetails();
    }, []);

    const handleFullscreen = () => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }
        setIsFullscreen(true); // Set to true when entering fullscreen
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        setIsFullscreen(false); // Reset to false when exiting fullscreen
        setShowMarksModal(true); // Show the marks modal when exiting fullscreen intentionally;
    };

    const handleAnswerChange = (e, questionIndex, optionIndex) => {
        // console.log(`Selected Option Index: ${optionIndex}`);
        const updatedAnswers = [...userAnswers];
        updatedAnswers[questionIndex] = e.target.value;
        setUserAnswers(updatedAnswers);
        setSelectedAnswerIndex(optionIndex);
    };

    //This function is for the  initilizing the assessment
    const InitializeAssessment = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            vacancy_uuid: VacancyIdForStartAssessment,
            round_no: roundNo,
            round_type: roundType,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/interview_assessment`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // setRoundType(result.interview_detail[0].round_type);
                setDifficultyLevel(result.interview_detail[0].difficulty_level);
                // setquestionLimit(result.interview_detail[0].limit);
                localStorage.setItem("QuestionCountForShowingMarks", result.interview_detail[0].limit)
                setTimeLimit(result.interview_detail[0].timer);
                // setRoundNo(result.interview_detail[0].round_no);

                HandleInterviewDetails(
                    // result.interview_detail[0].round_type,
                    result.interview_detail[0].difficulty_level,
                    result.interview_detail[0].question,
                    result.interview_detail[0].limit
                );
            });
    };

    // This function is for handeling the interview details & fetching the Question , QuestionId , RoundId
    const HandleInterviewDetails = (difficulty_level, questionTopic, limit) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            round_type: roundType,
            difficulty_level: difficulty_level,
            questions: questionTopic,
            limit: limit,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/interview/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setStartTimer(true);
                setRoundId(result.round_id);

                const parsedQuestions = result.questions[0]
                    .split("#$, ")
                    .map((ques) => {
                        const [question, ...options] = ques.split("$");

                        // Filter out invalid options (like empty strings or options with double '$')
                        const validOptions = options.filter(
                            (option) => option.trim() !== "" && !option.includes("$$")
                        );

                        return {
                            question: question.trim(),
                            options: validOptions.slice(0, 4), // Ensure only the first 4 valid options are taken
                        };
                    });

                setQuestionArray(parsedQuestions);
                setQuestionIDsArray(result.question_ids.split(","));
            });
    };

    // console.log(QuestionArray, 'arraayy___');

    const StartAssessment = () => {
        localStorage.setItem("AssessmentStarted", "true");
        assessmentStartedRef.current = true; // This is for tab change and exit full screen action.

        InitializeAssessment();
        setInstructionsModal(false);
        handleFullscreen();

        toast.info("Timer will get start once you get the questions.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });
    };

    const AddAllMarks = (marksArray) => {
        const finalScore = marksArray.reduce((total, mark) => total + mark, 0);
        // setMarksObtained(finalScore);
        localStorage.setItem("MarksObtained", finalScore)
        localStorage.setItem("AssessmentStarted", "done");
    };

    const SubmitAnswer = () => {
        AddAllMarks(IndividualMarks);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            // for full screen
            if (!document.fullscreenElement && isFullscreen) {
                if (assessmentStartedRef.current) {
                    //   exitFullscreen();
                    AutoSubmitAnswer();
                }
            }
            setIsFullscreen(!!document.fullscreenElement); // Update fullscreen status
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, [isFullscreen]);

    useEffect(() => {
        // for tab change
        const handleBlur = () => {
            if (assessmentStartedRef.current) {
                AutoSubmitAnswer();
            }
        };
        window.addEventListener("blur", handleBlur);
        return () => {
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    const AutoSubmitAnswer = () => {
        setShowCheatingMarksModal(true);
        // setMarksObtained("0");
        localStorage.setItem("MarksObtained", "0")
        setStartTimer(false);

        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                // console.error("Error attempting to exit fullscreen mode:", err);
            });
        }
    };

    const HandleOnTimerStop = () => {
        setStartTimer(false);
        setTimeLimit("0");
        setMarksObtainedLoader(false);

        toast.success("Time’s up! Your response has been submitted.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });

        setShowResultButton(true);
        setQuizSubmitted(true);
        SubmitAnswer();
        assessmentStartedRef.current = false;
    };

    const ClickSubmitAnswer = () => {
        setMarksObtainedLoader(true);

        const formdata = new FormData();
        formdata.append("round_id", roundId);
        formdata.append("question_id", QuestionIDsArray[currentQuestionIndex]);
        // formdata.append("answer_text", userAnswers[currentQuestionIndex]);
        formdata.append("answer_text", SelectedAnswerIndex);
        formdata.append("round_name", roundType);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/analyze-answer/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const correctAnswer = result.result;
                const userAnswer = SelectedAnswerIndex;

                const mark = correctAnswer == userAnswer ? 1 : 0;

                setIndividualMarks((prevMarksArray) => [...prevMarksArray, mark]);

                if (currentQuestionIndex < QuestionArray.length - 1) {
                    setMarksObtainedLoader(false);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                    AddAllMarks([...IndividualMarks, mark]);

                    toast.success(
                        "The response is submitted and is currently being processed.",
                        {
                            position: "top-right",
                            toastId: customId,
                            autoClose: 4000,
                        }
                    );

                    setStartTimer(false); // This is to stop the timer
                    setShowResultButton(true);
                    setQuizSubmitted(true);
                    setMarksObtainedLoader(false);
                    assessmentStartedRef.current = false;
                }
            });
    };

    // console.log(IndividualMarks, 'Individual score ');
    // console.log(MarksObtained, 'final score')

    const RoundComplete = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            vacancy_uuid: VacancyIdForStartAssessment,
            round_no: roundNo,
            round_type: roundType,
            round_marks: ((MarksObtained / QuestionCountForShowingMarks) * 100).toString(),
            round_status: "true",
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/assessment_detail`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    localStorage.setItem("AssessmentStarted", "false");
                    navigate("/applied_vacancy");
                    localStorage.removeItem("MarksObtained");
                    localStorage.removeItem("QuestionCountForShowingMarks");
                }
            });
    };

    return (
        <>
            <div className="QuizRound">
                {/* These are for keyboard event keys handeling  */}
                <KeyboardEventHandler
                    handleKeys={["F11", "esc", "ctrl+tab", "alt+tab", "meta"]}
                    isDisabled={true}
                    onKeyEvent={(key, e) => {
                        e.preventDefault();
                    }}
                />

                {
                    ShowMainPageLoader &&
                    <div className="MainPageLoader" >
                        <div className="LoaderContent" >
                            <Hourglass visible={true} height="50" width="50" colors={['#306cce', '#72a1ed']} />
                            <p style={{ color: 'black', fontWeight: '500' }}>Loading ...</p>
                        </div>
                    </div>
                }

                <img src={StarteleName} id="Logo" />

                <div className="RoundInfo">
                    <div className="RoundInfoLeft">
                        <h2>Round Name : This is the Quiz Round</h2>
                        <h3>Difficulty Level : Level {difficultyLevel} </h3>
                        <p>Round : 3 </p>
                    </div>

                    <div id="trialBox">
                        <CountdownCircleTimer
                            isPlaying={StartTimer}
                            fill={"yellow"}
                            duration={TimeLimit}
                            key={TimeLimit} // To ensure the timer resets when TimeLimit changes
                            colors={["#9ef01a", "#c6db34", "#f7b801", "#f8961e", "#dd1c1a"]}
                            colorsTime={[
                                TimeLimit,
                                TimeLimit * 0.75,
                                TimeLimit * 0.5,
                                TimeLimit * 0.25,
                                0,
                            ]}
                            size={140}
                            strokeWidth={15}
                            trailStrokeWidth={10}
                            trailColor={"#7AB4F566"}
                            onComplete={HandleOnTimerStop}
                        >
                            {({ remainingTime }) => {
                                const minutes = Math.floor(remainingTime / 60);
                                const seconds = remainingTime % 60;

                                return (
                                    <div style={{ color: "white", fontSize: "25px" }}>
                                        {minutes} : {seconds < 10 ? `0${seconds}` : seconds}
                                    </div>
                                );
                            }}
                        </CountdownCircleTimer>
                    </div>
                </div>

                <div className="Section_1">
                    <div className="Box_1">
                        {QuestionArray.length == 0 ? (
                            <div
                                style={{
                                    padding: "50px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    rowGap: "20px",
                                }}
                            >
                                <Hourglass
                                    visible={true}
                                    height="70"
                                    width="70"
                                    colors={["#306cce", "#72a1ed"]}
                                />
                                Fetching the questions .. Be ready !!
                            </div>
                        ) : (
                            <>
                                <h1>Total Questions: {QuestionArray.length}</h1>
                                <div className="questionBox">
                                    <FormLabel>
                                        <h1>
                                            Ques {currentQuestionIndex + 1}.{" "}
                                            {QuestionArray[currentQuestionIndex].question}
                                        </h1>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby={`question-${currentQuestionIndex}`}
                                        name={`question-${currentQuestionIndex}`}
                                        value={userAnswers[currentQuestionIndex] || ""}
                                        onChange={(e) => {
                                            // Finding the index of the selected option
                                            const selectedOptionIndex = QuestionArray[
                                                currentQuestionIndex
                                            ].options.indexOf(e.target.value);
                                            handleAnswerChange(
                                                e,
                                                currentQuestionIndex,
                                                selectedOptionIndex
                                            );
                                        }}
                                    >
                                        {QuestionArray[currentQuestionIndex].options.map(
                                            (option, i) => (
                                                <FormControlLabel
                                                    key={i}
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option}
                                                    id="options"
                                                />
                                            )
                                        )}
                                    </RadioGroup>

                                    <div>
                                        {!quizSubmitted && (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    fontSize: "13px",
                                                    color: "white",
                                                    backgroundColor: "#176D96",
                                                    cursor: "pointer",
                                                    marginTop: "10px",
                                                    float: "right",
                                                    width: "100px",
                                                }}
                                                onClick={ClickSubmitAnswer}
                                                disabled={MarksObtainedLoader}
                                            >
                                                {MarksObtainedLoader ? (
                                                    <Bars
                                                        visible={true}
                                                        height="24"
                                                        width="30"
                                                        color="white"
                                                    />
                                                ) : currentQuestionIndex ===
                                                    QuestionArray.length - 1 ? (
                                                    "Submit"
                                                ) : (
                                                    "Next"
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        <p>
                            Note : <br />
                            1. This is the Quiz round. Each question has 4 options. Select
                            the most appropriate answer for each question within the time
                            limit displayed at the top of the page. <br />
                            2. You will answer each question in sequential order. Once you move on to the next question, you cannot return to previous ones.
                        </p>
                    </div>

                    <div className="Box_2">
                        {ShowResultButton && (
                            <button id="SubmitButton" onClick={exitFullscreen}>
                                {MarksObtainedLoader ? "Loading result..." : "View Result"}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal for instructions before assessment  */}
            <Modal Modal open={InstructionsModal}>
                <Box sx={ModalStyle2} className="QuizRoundModal">
                    <div>
                        <h3 style={{ color: "#4B64FF" }}>
                            {" "}
                            Important Instructions for Quiz Round.{" "}
                        </h3>

                        <p style={{ color: "grey" }}>
                            {" "}
                            Please mug up these important instructions carefully before
                            attempting the assessment .
                        </p>
                    </div>

                    <ul>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not refresh the page
                                once the assessment has started resulting in the auto-submission of the assessment.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not switch tabs or
                                windows while completing the assessment otherwise the assessment will get auto-submit.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please ensure that you are
                                connected to the internet.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" />
                                This is the Quiz round. Each question has 4 options. Select the
                                most appropriate answer for each question within the time limit.
                                Be sure to submit your answers before the time runs out.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" />You will answer each question in sequential order. Once you move on to the next question, you cannot return to previous ones.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> The timeline for this round will
                                be displayed at the top of the assessment page as a Timer.
                                Please adhere to the specified timings.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> After completing the assessment
                                you will be redirected to the applied jobs page.
                            </p>
                        </li>
                    </ul>

                    <div>
                        <Button
                            className="Button_1"
                            variant="contained"
                            style={{ margin: "0px 10px" }}
                            onClick={StartAssessment}
                        >
                            Start
                        </Button>

                        <Button
                            onClick={() => navigate("/applied_vacancy")}
                            style={{ color: "indianRed" }}
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>

            {/* Modal for already completed assessment  */}
            <Modal open={CompletedExamModal}>
                <Box
                    sx={ModalStyle2}
                    className="QuizRoundModal"
                    style={{ width: "50%", padding: "25px 20px" }}
                >
                    <h3 style={{ color: "#4B64FF" }}>
                        Assessment Already Completed
                    </h3>

                    <p style={{ color: "grey", textAlign: "center" }}>
                        You have already given this round of your assessment.
                    </p>

                    <center>
                        <img src={CompletedGif} style={{
                            width: "300px",
                            height: "auto"
                        }} />
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: "scale(1.2)" }}
                        onClick={() => navigate("/applied_vacancy")}
                    >
                        Go to Applied Vacancies
                    </Button>
                </Box>
            </Modal>

            <Modal open={ShowMarksModal}>
                <Box
                    sx={ModalStyle2}
                    className="QuizRoundModal"
                    style={{ width: "50%", padding: "25px 20px" }}
                >
                    <h3 style={{ color: "#4B64FF" }}>
                        {" "}
                        Assessment Submitted Successfully
                    </h3>

                    <p style={{ color: "grey", textAlign: "center" }}>
                        This is your result for this assessment. You can view your marks on "Applied Vacancies" page once you complete all the remaining rounds. The final result will be sent to your email followed by the further asseessment round if qualified.
                    </p>

                    <center>
                        <h1
                            id="MarksObtained"
                            style={{
                                color:
                                    MarksObtained < QuestionCountForShowingMarks / 2 ? "indianred" : "#4B64FF",
                                border:
                                    MarksObtained < QuestionCountForShowingMarks / 2
                                        ? "2px indianred solid"
                                        : "2px #4B64FF solid",
                            }}
                        >
                            {MarksObtained} / {QuestionCountForShowingMarks}
                            <span>
                                {MarksObtained < QuestionCountForShowingMarks / 2
                                    ? "You didn't clear this round."
                                    : "You cleared this round."}
                            </span>
                        </h1>

                        {/* {MarksObtained ? (
                            <h1 id="MarksObtained">{MarksObtained} / {questionLimit}</h1>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Bars visible={true} height="30" width="70" color="#9c88ff" />
                            </div>
                        )} */}
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: "scale(1.2)" }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal>

            <Modal open={ShowCheatingMarksModal}>
                <Box
                    sx={ModalStyle2}
                    className="QuizRoundModal"
                    style={{ width: "50%", padding: "25px 20px" }}
                >
                    <h3 style={{ color: "indianred" }}> Assessment Submitted</h3>

                    <p style={{ color: "indianred", textAlign: "center" }}>
                        Since you attempted to refresh page , switch tabs or exit full-screen mode, which
                        violates the assessment guidelines outlined in the instructions,
                        your assessment has been submitted, and this is your result for this assessment.
                    </p>

                    <center>
                        <h1
                            id="MarksObtained"
                            style={{ color: "indianred", border: "2px indianred solid" }}
                        >
                            {MarksObtained} / {QuestionCountForShowingMarks}
                            <span>You didn't clear this round.</span>
                        </h1>
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: "scale(1.2)" }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default QuizRound;
